import { PublicationForm } from '@local/src/Pages/DraftPage/PublicationFormView/Form/types'
import { usePatchRecruitment } from '@local/src/Api/Hooks/usePatchRecruitment'
import { useFormikContext } from 'formik'

export const usePublicationForm = () => {
  const { patchPublicationEndDate } = usePatchRecruitment()

  const { values, errors, setFieldValue, setFieldTouched } =
    useFormikContext<PublicationForm>()

  const patchOnChangeMapper: {
    [key in keyof Partial<PublicationForm>]: (
      recruitmentId: string,
      value: PublicationForm[key]
    ) => Promise<unknown>
  } = {
    endDate: patchPublicationEndDate,
  }

  const handleChange =
    <K extends keyof PublicationForm>(key: K) =>
    (recruitmentId: string, value: PublicationForm[K]) => {
      void setFieldValue(key, value)
      void patchOnChangeMapper[key]?.(recruitmentId, value)
    }

  const handleBlur =
    <K extends keyof PublicationForm>(key: K) =>
    () => {
      void setFieldTouched(key, true)
    }

  return {
    values,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
  }
}
