import React from 'react'
import { useLocation } from 'react-router-dom'

interface UseQueryResult<Key extends string, Value extends string>
  extends URLSearchParams {
  get: (key: Key) => Value | null
}

export const useQuery = <
  Key extends string = string,
  Value extends string = string,
>() => {
  const { search } = useLocation()

  return React.useMemo(
    () => new URLSearchParams(search),
    [search]
  ) as UseQueryResult<Key, Value>
}
