import {
  LocationInformation,
  LocationType,
} from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { baseApi } from '@local/src/Api/baseApi'

export interface GetLocationsResponse extends LocationInformation {
  nameWithHighLightedSearchTerm: string
}

export const locationApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getLocations: builder.query<
      GetLocationsResponse[],
      { search: string; type?: LocationType[] }
    >({
      query: ({ search }) => ({
        url: `/referencedata/locations/suggest/${search}`,
      }),
      transformResponse: (response: GetLocationsResponse[], _, arg) =>
        response.filter((location) => arg?.type.includes(location.type)),
    }),
  }),
})

export const { useGetLocationsQuery } = locationApi
