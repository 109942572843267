import { ApplicationStatus } from '@local/src/Api/types'
import { ApplicantStatusForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { useContent } from '@local/src/Utils/Content/useContent'
import { mixed, object } from 'yup'

export const useApplicationStatusFormValidationSchema = () => {
  const { requiredField } = useContent().manageCandidate.dialogInterested

  return object<ApplicantStatusForm>().shape({
    status: mixed<ApplicationStatus>().required(requiredField),
  })
}
