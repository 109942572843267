import React from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { useEpiPagePath } from '@trr/app-shell-data'
import { useCurrentRecruitmentId } from '@local/src/Utils/Hooks/useCurrentRecruitment'

interface DetailsHeaderProps {
  heading: string
  buttonLabel: string
}

export const DetailsHeader: React.FC<DetailsHeaderProps> = ({
  heading,
  buttonLabel,
}) => {
  const recruitmentId = useCurrentRecruitmentId()

  const epiPagePath = useEpiPagePath()

  const settingsPath = `${epiPagePath}${recruitmentId}/hantera`

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 1, md: 4 }}
      justifyContent={{ xs: 'center', sm: 'space-between' }}
      alignItems="flex-start"
    >
      <Typography variant="h3" component="h1">
        {heading}
      </Typography>
      <Button
        size="small"
        variant="outlined"
        sx={{ flexShrink: 0 }}
        href={settingsPath}
      >
        {buttonLabel}
      </Button>
    </Stack>
  )
}
