import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { ApplicantsList } from '@local/src/Pages/DetailsPage/Applicants/ActiveApplicants/ApplicantsList/ApplicantsList'
import {
  useCurrentRecruitment,
  useCurrentRecruitmentId,
} from '@local/src/Utils/Hooks/useCurrentRecruitment'
import { date } from '@trr/frontend-datetime'
import { useDevice } from '@trr/app-shell-data'
import { CandidateDrawer } from '@local/src/Pages/DetailsPage/CandidateDrawer/CandidateDrawer'
import { useContent } from '@local/src/Utils/Content/useContent'
import { applicationStatusContentMapper } from '@local/src/Utils/Content/applicationStatusContentMapper'
import { dottedStringArray } from '@local/src/Utils/stringHelpers'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'
import { FavoriteApplication } from '@local/src/Pages/DetailsPage/Components/FavoriteApplication'

export const ClosedRecruitmentApplicants: React.FC = () => {
  const [currentDrawerApplicationId, setCurrentDrawerApplicationId] =
    React.useState<string | null>(null)

  const recruitmentId = useCurrentRecruitmentId()
  const { data: recruitment } = useCurrentRecruitment()

  const {
    detailsPage: {
      applicants: {
        closedRecruitmentApplicantsText,
        showCandidateLabel,
        closedApplicantDateText,
      },
      applicationStatuses,
    },
  } = useContent()

  const device = useDevice()

  const columns = (() => {
    if (device.isMobile) {
      return 1
    }
    if (device.isTablet) {
      return 2
    }
    return 3
  })()

  const handleShowApplicantClick = (applicationId: string) => () => {
    setCurrentDrawerApplicationId(applicationId)
  }

  return (
    <>
      <Stack spacing={3}>
        <Typography>{closedRecruitmentApplicantsText}</Typography>
        <Box>
          <ApplicantsList
            recruitmentId={recruitmentId}
            mapApplicationToCard={(app) => ({
              title: app.person.name,
              subtitle: dottedStringArray([
                replaceEpiVariables(closedApplicantDateText, {
                  date: date.format(new Date(app.lastModifiedWhen), {
                    year: true,
                  }),
                }),
                applicationStatusContentMapper(app.status, applicationStatuses),
              ]),
              skillsFulfilled: app.skills,
              buttonLabel: showCandidateLabel,
              status: app.status,
              totalSkills: recruitment.position.skills.length,
              onShowApplicantClick: handleShowApplicantClick(app.id),
              headerAction: (
                <FavoriteApplication
                  applicationId={app.id}
                  isFavorite={app.isFavourite}
                />
              ),
            })}
            columns={columns}
          />
        </Box>
      </Stack>
      {recruitment && (
        <CandidateDrawer
          recruitment={recruitment}
          isOpen={!!currentDrawerApplicationId}
          applicationId={currentDrawerApplicationId}
          onClose={() => setCurrentDrawerApplicationId(null)}
        />
      )}
    </>
  )
}
