import React from 'react'
import EmptyResult from '@local/src/Components/EmptyResult/EmptyResult'
import { RecruitmentStatus } from '@local/src/Api/types'
import { RecruitmentPreview } from '@local/src/Components/RecruitmentPreview/RecruitmentPreview'
import { ResponsiveWrapper } from '@local/src/Components/ResponsiveWrapper/ResponsiveWrapper'
import { ClosureSettings } from '@local/src/Pages/SettingsPage/ClosureSettings/ClosureSettings'
import { PublicationSettings } from '@local/src/Pages/SettingsPage/PublicationSettings/PublicationSettings'
import { useContent } from '@local/src/Utils/Content/useContent'
import {
  useCurrentRecruitment,
  useCurrentRecruitmentId,
} from '@local/src/Utils/Hooks/useCurrentRecruitment'
import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { EditableContactInfo } from '@local/src/Pages/SettingsPage/EditContactInfoDialog/ContactInfoPreview/EditableContactInfo'

enum SettingsPageTabs {
  Publication,
  ContactInfo,
}

export const SettingsPage: React.FC = () => {
  const recruitmentId = useCurrentRecruitmentId()
  const [tab, setTab] = React.useState(SettingsPageTabs.Publication)
  const { data: recruitment, isLoading, isError } = useCurrentRecruitment()

  const {
    errorMessage: {
      heading: errorHeading,
      description: errorDescription,
      startPagePath,
      actionLabel,
    },
    settingsView: { heading, previewSubtitle, contactSubtitle },
  } = useContent().detailsPage

  if (isLoading) return <CircularProgress sx={{ m: 1, alignSelf: 'center' }} />

  if (isError || !recruitment)
    return (
      <EmptyResult
        heading={errorHeading}
        description={errorDescription}
        action={{ link: startPagePath, label: actionLabel }}
      />
    )

  const handleChangeTab = (_event: unknown, value: SettingsPageTabs) => {
    setTab(value)
  }

  return (
    <ResponsiveWrapper size={{ xs: 12, md: 8 }}>
      <Stack spacing={3}>
        <Typography variant="h3" component="h1">
          {heading}
        </Typography>
        <Stack spacing={2}>
          <PublicationSettings
            recruitmentId={recruitmentId}
            endDate={
              recruitment.publication.endDate
                ? new Date(recruitment.publication.endDate)
                : undefined
            }
          />
          <ClosureSettings
            recruitmentId={recruitmentId}
            isClosed={recruitment.status === RecruitmentStatus.Closed}
            closureDate={
              recruitment.closure?.date
                ? new Date(recruitment.closure.date)
                : undefined
            }
          />
        </Stack>
        <Box>
          <Tabs value={tab} onChange={handleChangeTab}>
            <Tab label={previewSubtitle} />
            <Tab label={contactSubtitle} />
          </Tabs>
          <Divider />
        </Box>

        {tab === SettingsPageTabs.Publication && (
          <RecruitmentPreview recruitment={recruitment} />
        )}
        {tab === SettingsPageTabs.ContactInfo && (
          <EditableContactInfo
            contacts={recruitment?.contacts}
            recruitmentId={recruitmentId}
          />
        )}
      </Stack>
    </ResponsiveWrapper>
  )
}
