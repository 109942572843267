import { CloseRecruitmentFormValues } from '@local/src/Pages/SettingsPage/ClosureSettings/ClosureSettingsForm/types'
import { useContent } from '@local/src/Utils/Content/useContent'
import { Alert } from '@mui/material'
import { useFormikContext } from 'formik'
import React from 'react'

export const ClosureInformationAlert: React.FC = () => {
  const { informationAlert } = useContent().detailsPage.closure

  const { values } = useFormikContext<CloseRecruitmentFormValues>()

  if (!values.reason) return null

  return <Alert severity="info">{informationAlert}</Alert>
}
