import React from 'react'
import { ListItemText, Stack, Typography } from '@mui/material'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'
import { useContent } from '@local/src/Utils/Content/useContent'
import { date } from '@trr/frontend-datetime'
import { calculateDifferenceInDays } from '@local/src/Utils/dateHelpers'
import { PublicationStatusListItem } from '@local/src/Pages/DetailsPage/PublicationDetails/PublicationStatusListItem'

interface PublicationDetailsProps {
  endDate?: Date
  startDate: Date
}

export const PublicationDetails: React.FC<PublicationDetailsProps> = ({
  endDate,
  startDate,
}) => {
  const {
    ongoingRecruitment,
    today,
    publicationWillExpire,
    publicationStart,
    daysLeftPlural,
    daysLeftSingular,
  } = useContent().detailsPage.publication

  const isPublished = !endDate || new Date(endDate) > new Date()

  if (!isPublished) {
    return (
      <PublicationStatusListItem
        endDate={endDate ? new Date(endDate) : undefined}
      />
    )
  }

  const publishedText = date.format(new Date(startDate), {
    year: true,
  })

  const publishEndText = endDate
    ? date.format(new Date(endDate), { year: true })
    : ongoingRecruitment

  const daysLeft = endDate
    ? calculateDifferenceInDays(new Date(endDate))
    : undefined

  const daysLeftText = (() => {
    if (daysLeft > 1 && daysLeft < 8) {
      return replaceEpiVariables(daysLeftPlural, {
        daysLeft: daysLeft.toString(),
      })
    }

    if (daysLeft === 1) {
      return daysLeftSingular
    }

    if (daysLeft === 0) {
      return today
    }

    return ''
  })()

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 2, sm: 4 }}
      alignSelf="flex-start"
    >
      <ListItemText primary={publishedText} secondary={publicationStart} />
      <ListItemText
        primary={
          <Typography>
            {publishEndText}
            {!!endDate && daysLeftText && (
              <>
                {' \u2022 '}
                <Typography component="span" color="info.main">
                  {daysLeftText}
                </Typography>
              </>
            )}
          </Typography>
        }
        secondary={publicationWillExpire}
      />
    </Stack>
  )
}
