import React from 'react'
import { useLazyGetSkillsQuery } from '@local/src/Api/skillsApi'
import {
  Autocomplete,
  TextField,
  Chip,
  AutocompleteInputChangeReason,
} from '@mui/material'
import LabelWrapper from '@local/src/Components/LabelWrapper'
import { useContent } from '@local/src/Utils/Content/useContent'
import { Search } from '@mui/icons-material'
import { useRecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/useRecruitmentForm'
import { Skill } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { debounce } from 'lodash'
import { BottomPopper } from '@local/src/Components/BottomPopper'
import { boldify, capitalizeFirstLetter } from '@local/src/Utils/stringHelpers'
import parse from 'html-react-parser'
import { removeDuplicates } from '@local/src/Utils/arrayHelpers'

export const SkillsField: React.FC = () => {
  const { label, placeholder, description } = useContent().form.skills
  const { errors, handleChange, values, handleBlur } = useRecruitmentForm()
  const [input, setInput] = React.useState({ name: '' })
  const [showOptions, setShowOptions] = React.useState(false)
  const [getSkills, { data: skills }] = useLazyGetSkillsQuery()

  const fetchSkillsDebounce = debounce((search: string) => {
    void getSkills(search)
  }, 300)

  const handleInputChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setShowOptions(reason !== 'clear' && value !== '')
    fetchSkillsDebounce(value)
    setInput({ name: value.trimStart() })
  }

  const handleOnClose = () => {
    setShowOptions(false)
  }

  const handleAutocompleteChange = (_event: unknown, newValues: Skill[]) => {
    const skills = removeDuplicates(
      newValues,
      (a, b) => a?.name.toLowerCase() === b?.name.toLowerCase()
    )?.map((x: Skill) => ({
      name: capitalizeFirstLetter(x?.name),
    }))
    handleChange('skills')(values.id, skills)
  }

  const handleAutocompleteBlur = () => {
    if (input?.name.length > 0) {
      const newSkills = removeDuplicates(
        [...values.skills, { name: capitalizeFirstLetter(input?.name.trim()) }],
        (a, b) => a?.name === b?.name
      )
      handleChange('skills')(values.id, newSkills)
    }
  }

  const nonSelectedSkills = skills?.filter(
    (skill) => !values.skills?.some((x) => x?.name === skill?.name)
  )

  const options = nonSelectedSkills ?? []

  return (
    <LabelWrapper label={label} description={description}>
      <Autocomplete
        popupIcon={<Search />}
        forcePopupIcon
        freeSolo
        multiple
        inputValue={input?.name}
        limitTags={3}
        clearOnBlur
        PopperComponent={BottomPopper}
        onChange={handleAutocompleteChange}
        onInputChange={handleInputChange}
        onBlur={handleAutocompleteBlur}
        autoHighlight
        options={showOptions && options ? options : []}
        onClose={handleOnClose}
        value={values.skills}
        loadingText="Hämtar kompetenser..."
        disableCloseOnSelect
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option: Skill, index) => (
            <Chip
              key={index}
              label={option?.name}
              variant="filled"
              {...getTagProps({ index })}
            />
          ))
        }
        getOptionLabel={(option: Skill) => option?.name}
        filterOptions={(options: Skill[], { inputValue }) =>
          inputValue.trim() !== ''
            ? options.push({
                title: `Lägg till: "${inputValue}"`,
                name: inputValue,
              }) && options
            : options
        }
        renderOption={(props, skill, { inputValue }) => (
          <li {...props} translate="no">
            {skill?.title ?? parse(boldify(skill?.name, inputValue))}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            helperText={
              typeof errors?.skills === 'string'
                ? errors?.skills
                : typeof errors?.skills?.[0] === 'string'
                  ? errors?.skills?.[0]
                  : errors?.skills?.[0]?.name
            }
            error={Boolean(errors?.skills)}
            onBlur={() => handleBlur('skills')(values.id)}
            data-testid="skills-field"
          />
        )}
        sx={{
          '& .MuiAutocomplete-popupIndicator': { transform: 'none' },
        }}
      />
    </LabelWrapper>
  )
}
