import React from 'react'
import RecruitmentDraftItem from '@local/src/Pages/StartPage/CreatedRecruitments/RecruitmentDrafts/RecruitmentDraftItem/RecruitmentDraftItem'
import { RecruitmentsList } from '@local/src/Pages/StartPage/CreatedRecruitments/RecruitmentList/RecruitmentsList'
import { useContent } from '@local/src/Utils/Content/useContent'
import { RecruitmentStatus } from '@local/src/Api/types'

export const RecruitmentDrafts: React.FunctionComponent = () => {
  const {
    skapadeAnnonser: { draftTabEmptyText },
  } = useContent()

  return (
    <RecruitmentsList
      status={RecruitmentStatus.Draft}
      emptyText={draftTabEmptyText}
      renderListItem={(recruitment) => (
        <RecruitmentDraftItem key={recruitment.id} recruitment={recruitment} />
      )}
    />
  )
}
