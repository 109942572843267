import React from 'react'
import TextEditor from '@local/src/Components/TextEditor/TextEditor'
import LabelWrapper from '@local/src/Components/LabelWrapper'
import { useRecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/useRecruitmentForm'
import { useContent } from '@local/src/Utils/Content/useContent'
import { FormattedText } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'

export const CompanyDescriptionField: React.FC = () => {
  const { description, placeholder } = useContent().form.companyDescription

  const { values, handleChange, errors, handleBlur } = useRecruitmentForm()

  const handleInputChange = (value: FormattedText) => {
    handleChange('companyDescription')(values.id, value, {
      validationType: 'onError',
    })
  }

  return (
    <LabelWrapper description={description} htmlFor="company-description">
      <TextEditor
        content={values.companyDescription?.html}
        onChange={handleInputChange}
        editorId="company-description"
        error={!!errors.companyDescription?.text}
        helperText={errors.companyDescription?.text}
        placeholder={placeholder}
        onBlur={() => handleBlur('companyDescription')(values.id)}
        data-testid="company-description-field"
      />
    </LabelWrapper>
  )
}
