import React from 'react'
import { RecruiterContactInformation } from '@local/src/Components/RecruitmentPreview/RecruiterContactInformation/RecruiterContactInformation'
import { Box, Button, Stack, Typography } from '@mui/material'
import { ContactInfo } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { useContent } from '@local/src/Utils/Content/useContent'
import { EditContactInfoDialog } from '@local/src/Pages/SettingsPage/EditContactInfoDialog/EditContactInfoDialog'
import { useOptimistic } from '@local/src/Utils/Hooks/useOptimistic'

interface EditableContactInfoProps {
  contacts: ContactInfo[]
  recruitmentId: string
}

export const EditableContactInfo: React.FC<EditableContactInfoProps> = ({
  contacts,
  recruitmentId,
}) => {
  const [showEditContactDialog, setShowEditContactDialog] =
    React.useState(false)
  const [optimisticContacts, setOptimisticContacts] = useOptimistic(contacts)
  const { contactTitle, noContactContent, contactUpdatePromptLabel } =
    useContent().preview

  const handleDialogClose = (newContacts: ContactInfo[]) => {
    if (newContacts) {
      setOptimisticContacts(newContacts)
    }
    setShowEditContactDialog(false)
  }

  return (
    <>
      <Box>
        <Stack justifyContent="space-between" flexDirection="row">
          <Typography variant="h6" mb={2}>
            {contactTitle}
          </Typography>
          <Button variant="text" onClick={() => setShowEditContactDialog(true)}>
            {optimisticContacts?.length > 0 ? 'Ändra' : 'Lägg till'}
          </Button>
        </Stack>
        <Typography variant="body2" gutterBottom>
          {contactUpdatePromptLabel}
        </Typography>
        {optimisticContacts?.length > 0 ? (
          <RecruiterContactInformation recruiter={optimisticContacts?.[0]} />
        ) : (
          noContactContent
        )}
      </Box>
      <EditContactInfoDialog
        open={showEditContactDialog}
        onClose={handleDialogClose}
        contacts={optimisticContacts}
        recruitmentId={recruitmentId}
      />
    </>
  )
}
