import {
  CompanyType,
  EmployingCompanyType,
  RecruitmentForm,
} from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { usePatchRecruitment } from '@local/src/Api/Hooks/usePatchRecruitment'
import { useFormikContext } from 'formik'

export const useRecruitmentForm = () => {
  const {
    values,
    errors,
    touched,
    validateField,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<RecruitmentForm>()

  const {
    patchRecruitingForCompany,
    patchSkills,
    patchLocations,
    patchJobTitle,
    patchHeading,
    patchCompanyType,
    patchCompanyDescription,
    patchCompanyName,
    patchEmploymentDescription,
    patchEmploymentType,
    patchWorkingHours,
    patchContactInfo,
  } = usePatchRecruitment()

  const patchOnChangeMapper: {
    [key in keyof Partial<RecruitmentForm>]: (
      recruitmentId: string,
      value: RecruitmentForm[key]
    ) => Promise<unknown> | void
  } = {
    employingCompanyType: (recruitmentId, value) => {
      void setFieldValue('employingCompanyName', undefined)
      void setFieldValue('isRecruitingForDeploymentAgency', false)
      return patchRecruitingForCompany(recruitmentId, {
        type: value,
        name: null,
        showName: values.showEmployingCompanyNameInJobAd,
      })
    },
    showEmployingCompanyNameInJobAd: (recruitmentId, value) =>
      patchRecruitingForCompany(recruitmentId, {
        type: EmployingCompanyType.AnotherCompany,
        name: values.employingCompanyName,
        showName: value,
      }),
    isRecruitingForDeploymentAgency: (recruitmentId, value) =>
      patchCompanyType(
        recruitmentId,
        value ? CompanyType.StaffingCompany : CompanyType.EmployingCompany
      ),
    skills: patchSkills,
    locations: patchLocations,
    employmentType: patchEmploymentType,
    workingHours: patchWorkingHours,
  }

  const patchOnBlurMapper: {
    [key in keyof Partial<RecruitmentForm>]: (
      recruitmentId: string,
      value: RecruitmentForm[key]
    ) => Promise<unknown>
  } = {
    jobHeading: patchHeading,
    jobTitle: patchJobTitle,
    employmentDescription: patchEmploymentDescription,
    companyDescription: patchCompanyDescription,
    companyName: patchCompanyName,
    employingCompanyName: (recruitmentId, value) =>
      patchRecruitingForCompany(recruitmentId, {
        type: EmployingCompanyType.AnotherCompany,
        name: value,
        showName: values.showEmployingCompanyNameInJobAd,
      }),
    contactInfo: patchContactInfo,
  }

  interface HandleChangeOptions {
    validationType?: 'always' | 'onError'
  }
  const defaultOptions: HandleChangeOptions = { validationType: 'always' }

  const handleChange =
    <K extends keyof RecruitmentForm>(key: K) =>
    (
      recruitmentId: string,
      value: RecruitmentForm[K],
      options?: HandleChangeOptions
    ) => {
      void setFieldValue(key, value).then(() => {
        options = { ...defaultOptions, ...options }
        const shouldValidate =
          options?.validationType === 'always' || errors[key]

        if (shouldValidate) {
          void validateField(key)
        }
      })
      void setFieldTouched(key, true)
      void patchOnChangeMapper[key]?.(recruitmentId, value)
    }

  const handleBlur =
    <K extends keyof RecruitmentForm>(key: K) =>
    (recruitmentId: string) => {
      touched[key] && void validateField(key)
      void patchOnBlurMapper[key]?.(recruitmentId, values[key])
    }

  return {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
  }
}
