import React from 'react'
import { ContactInfo } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { Stack, Typography } from '@mui/material'
import { RecruiterContactInformation } from '@local/src/Components/RecruitmentPreview/RecruiterContactInformation/RecruiterContactInformation'
import { useContent } from '@local/src/Utils/Content/useContent'

interface ContactInfoPreviewProps {
  contacts: ContactInfo[]
}

export const ContactInfoPreview: React.FC<ContactInfoPreviewProps> = ({
  contacts,
}) => {
  const { contactTitle, contactInformationLabel, noContactContent } =
    useContent().preview

  return (
    <Stack>
      <Typography variant="h6" mb={2}>
        {contactTitle}
      </Typography>
      {contacts?.length > 0 ? (
        <RecruiterContactInformation recruiter={contacts?.[0]} />
      ) : (
        noContactContent
      )}
      <Typography variant="body2">{contactInformationLabel}</Typography>
    </Stack>
  )
}
