import { useEffect, useState } from 'react'

export const useOptimistic = <T>(state: T) => {
  const [optimisticState, setOptimisticState] = useState<T>(state)

  useEffect(() => {
    setOptimisticState(state)
  }, [state])

  return [optimisticState, setOptimisticState] as const
}
