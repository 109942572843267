import React from 'react'
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material'
import LabelWrapper from '@local/src/Components/LabelWrapper'
import { useRecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/useRecruitmentForm'
import { useContent } from '@local/src/Utils/Content/useContent'
import { EmployingCompanyType } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'

export const CompanyOwnerRadio: React.FC = () => {
  const {
    label,
    myCompanyCheckbox,
    anotherCompanyCheckbox,
    recruitingForEmploymentAgency,
  } = useContent().form.companyOwnerRadio

  const { values, handleChange, errors } = useRecruitmentForm()

  const employingCompanyTypeName = 'employingCompanyType'
  const isRecruitingForDeploymentAgencyName = 'isRecruitingForDeploymentAgency'

  const handleRadioChange = (_event: unknown, value: EmployingCompanyType) => {
    handleChange(employingCompanyTypeName)(values.id, value)
  }

  const handleIsRecruitingForDeploymentAgencyChange = (
    _event: unknown,
    checked: boolean
  ) => {
    handleChange(isRecruitingForDeploymentAgencyName)(values.id, checked)
  }

  return (
    <LabelWrapper label={label}>
      <RadioGroup
        name={employingCompanyTypeName}
        value={values.employingCompanyType}
        onChange={handleRadioChange}
      >
        <FormControlLabel
          value={EmployingCompanyType.MyOwnCompany}
          control={<Radio />}
          label={myCompanyCheckbox}
        />
        {values.employingCompanyType === EmployingCompanyType.MyOwnCompany && (
          <Stack spacing={2} ml={2} maxWidth={(t) => t.breakpoints.values.sm}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values?.isRecruitingForDeploymentAgency}
                  onChange={handleIsRecruitingForDeploymentAgencyChange}
                />
              }
              label={recruitingForEmploymentAgency}
            />
          </Stack>
        )}
        <FormControlLabel
          value={EmployingCompanyType.AnotherCompany}
          control={<Radio />}
          label={anotherCompanyCheckbox}
        />
      </RadioGroup>
      {!!errors?.employingCompanyType && (
        <FormHelperText sx={{ ml: 2 }} error>
          {errors.employingCompanyType}
        </FormHelperText>
      )}
    </LabelWrapper>
  )
}
