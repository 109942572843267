import React from 'react'
import { PublicationEndDateField } from '@local/src/Pages/DraftPage/PublicationFormView/Fields/PublicationEndDateField'
import { Formik } from 'formik'
import { mapRecruitmentResponseToPublicationForm } from '@local/src/Pages/DraftPage/PublicationFormView/Form/types'
import { Stack } from '@mui/material'
import { WizardActions } from '@local/src/Components/Wizard/WizardActions'
import { usePublicationFormValidationSchema } from '@local/src/Pages/DraftPage/PublicationFormView/Form/validationSchema'
import { RecruitmentResponse } from '@local/src/Api/types'

interface PublicationFormViewProps {
  recruitment: RecruitmentResponse
}

const PublicationFormView: React.FC<PublicationFormViewProps> = ({
  recruitment,
}) => {
  const validationSchema = usePublicationFormValidationSchema()

  return (
    <Formik
      initialValues={mapRecruitmentResponseToPublicationForm(recruitment)}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false)
      }}
    >
      {({ validateForm }) => (
        <Stack>
          <PublicationEndDateField />
          <WizardActions
            primaryButtonText="Nästa"
            secondaryButtonText="Tillbaka"
            onPrimaryButtonClicked={({ nextStep }) => {
              void validateForm().then((errors) => {
                Object.keys(errors).length === 0 && nextStep()
              })
            }}
            onSecondaryButtonClicked={({ prevStep }) => {
              prevStep()
            }}
          />
        </Stack>
      )}
    </Formik>
  )
}

export default PublicationFormView
