import React from 'react'
import { Divider, Stack, StackProps } from '@mui/material'

export const StackDivider: React.FC<StackProps> = ({ children, ...props }) => (
  <Stack
    divider={<Divider />}
    m={{ xs: 2, sm: 3, md: 5 }}
    spacing={3}
    {...props}
  >
    {children}
  </Stack>
)
