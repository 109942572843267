import Cookies from 'js-cookie'

const APPROVED_COOKIE_KEY = 'TrrCookiesApproved'
const APPROVED_COOKIE_VALUE = '1'

const CLOSED_ONBOARDING_CARD_COOKIE_KEY =
  'TrrClosedMatchningKundforetagOnboardingCard'
const CLOSED_ONBOARDING_CARD_COOKIE_VALUE = '1'

const isCookiesApproved = () =>
  Cookies.get(APPROVED_COOKIE_KEY) === APPROVED_COOKIE_VALUE

export const setClosedOnboardingCardCookie = () => {
  isCookiesApproved() &&
    Cookies.set(
      CLOSED_ONBOARDING_CARD_COOKIE_KEY,
      CLOSED_ONBOARDING_CARD_COOKIE_VALUE,
      {
        expires: 365,
        secure: true,
      }
    )
}

export const hasClosedOnboardingCardCookie = () =>
  isCookiesApproved() &&
  Cookies.get(CLOSED_ONBOARDING_CARD_COOKIE_KEY) ===
    CLOSED_ONBOARDING_CARD_COOKIE_VALUE
