import React from 'react'
import { Popper, PopperProps } from '@mui/material'

export const BottomPopper = (props: PopperProps) => (
  <Popper
    {...props}
    placement="bottom-start"
    modifiers={[
      {
        name: 'flip',
        enabled: false,
      },
    ]}
  />
)
