import React from 'react'
import { useSetStatusMutation } from '@local/src/Api/applicationsApi'
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import {
  ApplicationStatus,
  RecruitmentApplication,
  RecruitmentResponse,
} from '@local/src/Api/types'
import { useContent } from '@local/src/Utils/Content/useContent'
import { CandidateHistoryLog } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/CandidateHistoryLog'
import { SkillsChecklist } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/SkillsChecklist'
import { StackDivider } from '@local/src/Components/StackDivider'
import { HiredApplicantDialog } from '@local/src/Pages/DetailsPage/CandidateDrawer/Dialogs/HiredApplicantDialog/HiredApplicantDialog'
import { Motivation } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/Motivation'
import { ContactInformation } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/ContactInformation'
import { ButtonStack } from '@local/src/Components/ButtonStack/ButtonStack'
import { useResolve } from '@local/src/Utils/Hooks/useResolve'
import { applicationStatusContentMapper } from '@local/src/Utils/Content/applicationStatusContentMapper'
import { useOptimistic } from '@local/src/Utils/Hooks/useOptimistic'
import { RejectApplicantDialog } from '@local/src/Pages/DetailsPage/CandidateDrawer/Dialogs/RejectApplicantDialog/RejectApplicantDialog'

interface OngoingCandidateProps {
  application: RecruitmentApplication
  recruitment: RecruitmentResponse
  onClose: () => void
}

export const OngoingCandidate: React.FC<OngoingCandidateProps> = ({
  application,
  recruitment,
  onClose,
}) => {
  const [setStatus, { isLoading: isSetStatusLoading }] = useSetStatusMutation()

  const [optimisticStatus, setOptimisticStatus] = useOptimistic(
    application?.status
  )

  const {
    manageCandidate: { actionMoveToHired },
    detailsPage: { applicationStatuses },
  } = useContent()

  const handleSetStatusOngoingButtonClick = async () => {
    const confirmed = await confirm()
    return confirmed ? onClose() : null
  }

  const {
    isPending: isPendingConfirmation,
    resolve: resolveConfirmation,
    promise: confirm,
  } = useResolve<boolean>()

  const {
    isPending: isPendingReject,
    resolve: resolveReject,
    promise: confirmReject,
  } = useResolve<boolean>()

  const handleShowRejectDialogClick = async () => {
    const confirmed = await confirmReject()
    confirmed && onClose()
  }

  const onStatusChange = (status: ApplicationStatus) => {
    status !== ApplicationStatus.Hired && setOptimisticStatus(status)
    setStatus({
      applicationId: application.id,
      status,
    }).catch((error) => console.error(error))
  }

  return (
    <>
      <StackDivider data-testid="ongoing-candidate-wrapper">
        <Stack>
          <Typography variant="h6" mb={2}>
            {actionMoveToHired.title}
          </Typography>
          <InputLabel sx={{ mb: 2 }}>
            {actionMoveToHired.description}
          </InputLabel>
          <Select
            value={optimisticStatus}
            sx={{ mb: 4 }}
            onChange={(e) =>
              onStatusChange(e.target.value as ApplicationStatus)
            }
          >
            {[
              ApplicationStatus.InProgress,
              ApplicationStatus.InterviewBooked,
              ApplicationStatus.OfferPresented,
            ].map((status) => (
              <MenuItem key={status} value={status}>
                {applicationStatusContentMapper(status, applicationStatuses)}
              </MenuItem>
            ))}
          </Select>

          <ButtonStack>
            <Button
              variant="contained"
              color="primary"
              onClick={
                isSetStatusLoading
                  ? undefined
                  : () => void handleSetStatusOngoingButtonClick()
              }
            >
              {actionMoveToHired.buttonPrimary}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={
                isSetStatusLoading
                  ? undefined
                  : () => void handleShowRejectDialogClick()
              }
            >
              {actionMoveToHired.buttonSecondary}
            </Button>
          </ButtonStack>
        </Stack>

        <CandidateHistoryLog historyLog={application?.history} />

        <Motivation text={application?.motivation} />

        <SkillsChecklist
          applicationSkills={application?.skills}
          recruitmentSkills={recruitment.position?.skills.map((x) => x.name)}
        />

        {(application?.person?.phone || application?.person?.email) && (
          <ContactInformation person={application?.person} />
        )}
      </StackDivider>
      <HiredApplicantDialog
        applicationId={application.id}
        recruitmentId={recruitment.id}
        isOpen={isPendingConfirmation}
        onCancel={() => resolveConfirmation(false)}
        onConfirm={() => resolveConfirmation(true)}
      />
      <RejectApplicantDialog
        applicationId={application.id}
        applicantName={application?.person?.name ?? 'Användaren'}
        isOpen={isPendingReject}
        onConfirm={() => resolveReject(true)}
        onCancel={() => resolveReject(false)}
      />
    </>
  )
}
