import React from 'react'
import { StarOutlineRounded, StarRounded } from '@mui/icons-material'
import { Checkbox, FormControlLabel, SxProps } from '@mui/material'
import { useFavoriteApplicationMutation } from '@local/src/Api/applicationsApi'
import { useOptimistic } from '@local/src/Utils/Hooks/useOptimistic'

interface FavoriteApplicationProps {
  applicationId: string
  isFavorite: boolean
  label?: string
  checkboxSx?: SxProps
}

export const FavoriteApplication: React.FC<FavoriteApplicationProps> = ({
  applicationId,
  isFavorite,
  label,
  checkboxSx,
}) => {
  const [optimisticIsFavorite, setOptimstiicIsFavorite] =
    useOptimistic(isFavorite)

  const [favorite] = useFavoriteApplicationMutation()

  const handleChange = (_event: unknown, checked: boolean) => {
    setOptimstiicIsFavorite(checked)
    void favorite({ applicationId, value: checked })
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={<StarOutlineRounded />}
          checkedIcon={<StarRounded />}
          color="warning"
          sx={checkboxSx}
        />
      }
      onChange={handleChange}
      checked={optimisticIsFavorite}
      label={label}
      sx={{ mr: label ? undefined : 0 }}
    />
  )
}
