import React from 'react'
import { PreviewDetails } from '@local/src/Components/RecruitmentPreview/PreviewDetails/PreviewDetails'
import { RecruitmentResponse } from '@local/src/Api/types'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { useContent } from '@local/src/Utils/Content/useContent'
import HtmlPreview from '@local/src/Components/RecruitmentPreview/HtmlPreview/HtmlPreview'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'
import SkillsList from '@local/src/Components/RecruitmentPreview/SkillsList/SkillsLists'
import { date } from '@trr/frontend-datetime'
import { dottedStringArray } from '@local/src/Utils/stringHelpers'

interface RecruitmentPreviewProps {
  recruitment: RecruitmentResponse
}

export const RecruitmentPreview: React.FC<RecruitmentPreviewProps> = ({
  recruitment,
}) => {
  const {
    preview: {
      employmentDescriptionLabel,
      skillsLabel,
      companyDescriptionLabel,
      unknownCompany,
      publicationStartDate,
      publicationEndDate,
      ongoingRecruitment,
      recruitingCompany,
    },
  } = useContent()

  const hiringCompanyName = (() => {
    if (recruitment.recruitingForCompany) {
      return recruitment.recruitingForCompany.showName
        ? recruitment.recruitingForCompany.name
        : unknownCompany
    }
    return recruitment.company.name
  })()

  const endDate = recruitment.publication.endDate
    ? replaceEpiVariables(publicationEndDate, {
        date: date.format(recruitment.publication.endDate, { year: true }),
      })
    : ongoingRecruitment

  const startDate = replaceEpiVariables(publicationStartDate, {
    date: date.format(new Date(), { year: true }),
  })

  const recruiter = recruitment.recruitingForCompany
    ? replaceEpiVariables(recruitingCompany, {
        companyName: recruitment.company.name,
      })
    : undefined

  return (
    <Stack spacing={2} sx={{ wordBreak: 'break-word' }}>
      <Box>
        <Typography variant="h4" mb={1}>
          {recruitment.position.heading}
        </Typography>

        <Typography variant="body2" color="text.secondary" mb={2}>
          {dottedStringArray(
            recruitment.publication.startDate ? [startDate, endDate] : [endDate]
          )}
        </Typography>
        <Typography variant="body2">
          {recruiter && (
            <Typography component="span" variant="body2">
              {recruiter}
            </Typography>
          )}
        </Typography>
      </Box>
      <PreviewDetails
        jobTitle={recruitment.position.jobTitle.name}
        location={recruitment.position.locations[0]?.name}
        companyName={hiringCompanyName}
        employmentType={recruitment.position?.employmentType}
        workingHours={recruitment.position?.workingHours}
      />
      {recruitment.position.description && (
        <Box>
          <Typography variant="h5">{employmentDescriptionLabel}</Typography>
          <HtmlPreview html={recruitment.position.descriptionFormatted} />
        </Box>
      )}
      {recruitment.company.description && (
        <Box>
          <Typography variant="h6">
            {replaceEpiVariables(companyDescriptionLabel, {
              companyName: recruitment.company?.name ?? 'företaget',
            })}
          </Typography>
          <HtmlPreview html={recruitment.company.descriptionFormatted} />
        </Box>
      )}
      <Divider />
      {recruitment.position.skills?.length > 0 && (
        <Box>
          <Typography variant="h6" mb={2}>
            {skillsLabel}
          </Typography>
          <SkillsList skills={recruitment.position.skills.map((s) => s.name)} />
        </Box>
      )}
    </Stack>
  )
}
