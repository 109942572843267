import { ThemeOptions } from '@mui/material'

export const customThemeOptions: ThemeOptions = {
  components: {
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          'data-trackable': true,
        },
      },
    },
  },
}
