import React, { useId } from 'react'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material'
import { ApplicationStatus, RecruitmentApplication } from '@local/src/Api/types'
import { useContent } from '@local/src/Utils/Content/useContent'
import { CloseRecruitmentFormValues } from '@local/src/Pages/SettingsPage/ClosureSettings/ClosureSettingsForm/types'
import { useFormikContext } from 'formik'
import { sortByStatusAndAlphabetical } from '@local/src/Pages/SettingsPage/ClosureSettings/ClosureSettingsForm/closureSettingsFormHelpers'

interface HireApplicantsFieldProps {
  applications: RecruitmentApplication[]
}

export const HiredApplicantsField: React.FC<HireApplicantsFieldProps> = ({
  applications,
}) => {
  const { hiredPeopleLabel, hiredPeopleDescription } =
    useContent().detailsPage.closure

  const labelId = useId()

  const { values, errors, handleChange } =
    useFormikContext<CloseRecruitmentFormValues>()

  const handleHiredApplicantChange = (applicationId: string) => {
    const applicationsToHire = values.applicationsToHire || []
    const index = applicationsToHire.indexOf(applicationId)

    if (index === -1) {
      handleChange({
        target: {
          name: 'applicationsToHire',
          value: [...applicationsToHire, applicationId],
        },
      })
    } else {
      handleChange({
        target: {
          name: 'applicationsToHire',
          value: [
            ...applicationsToHire.slice(0, index),
            ...applicationsToHire.slice(index + 1),
          ],
        },
      })
    }
  }

  if (!applications.length) {
    return null
  }

  const orderedApplications = sortByStatusAndAlphabetical(applications)

  return (
    <FormControl error={Boolean(errors.applicationsToHire)}>
      <Stack spacing={1}>
        <Typography
          variant="body1"
          component="label"
          fontWeight="bold"
          id={labelId}
        >
          {hiredPeopleLabel}
        </Typography>
        <Typography variant="body1">{hiredPeopleDescription}</Typography>
        <Box maxHeight={300} ml={-1} display="grid" overflow="auto">
          {orderedApplications.map((applicant) => (
            <FormControlLabel
              control={
                <Checkbox
                  name="applicationsToHire"
                  onChange={() => handleHiredApplicantChange(applicant.id)}
                  defaultChecked={applicant.status === ApplicationStatus.Hired}
                  disableRipple
                  disabled={applicant.status === ApplicationStatus.Hired}
                />
              }
              key={applicant.id}
              label={applicant.person.name}
            />
          ))}
        </Box>
      </Stack>
      {errors.applicationsToHire && (
        <FormHelperText error>{errors.applicationsToHire}</FormHelperText>
      )}
    </FormControl>
  )
}
