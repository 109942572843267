import React, { useState } from 'react'

export const useResolve = <T>() => {
  const [isPending, setIsPending] = useState(false)

  const resolveRef = React.useRef<((value: T) => void) | null>(null)

  const promise = () => {
    setIsPending(true)
    return new Promise<T>((resolve) => {
      resolveRef.current = resolve
    })
  }

  const resolve = (value: T) => {
    setIsPending(false)
    resolveRef.current?.(value)
  }

  return {
    isPending,
    promise,
    resolve,
  }
}
