export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const boldify = (str: string, substr: string) => {
  const regex = new RegExp(substr, 'gi')
  return str.replace(regex, `<strong>$&</strong>`)
}

export const copyTextToClipboard = async (text: string) => {
  await navigator.clipboard.write([
    new ClipboardItem({
      'text/plain': new Blob([text], { type: 'text/plain' }),
    }),
  ])
}

export const dottedStringArray = (texts: string[]) => texts.join(' \u2022 ')
