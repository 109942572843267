import React from 'react'
import { Person } from '@local/src/Api/types'
import { TitleAccordion } from '@local/src/Components/TitleAccordion/TitleAccordion'
import { Link, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { Mail, Phone } from '@mui/icons-material'
import { useContent } from '@local/src/Utils/Content/useContent'

interface ContactInformationProps {
  person: Person
}

export const ContactInformation: React.FC<ContactInformationProps> = ({
  person,
}) => {
  const { title } = useContent().manageCandidate.contactInformation

  return (
    <TitleAccordion title={title}>
      <List>
        {person?.phone && (
          <ListItem>
            <ListItemIcon>
              <Phone color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={person.phone}
              primaryTypographyProps={{
                component: Link,
                href: `tel:${person.phone}`,
                'aria-label': 'Telefonnummer',
              }}
            />
          </ListItem>
        )}
        {person?.email && (
          <ListItem sx={{ overflowWrap: 'anywhere' }}>
            <ListItemIcon>
              <Mail color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={person.email}
              primaryTypographyProps={{
                component: Link,
                href: `mailto:${person.email}`,
                'aria-label': 'E-postadress',
              }}
            />
          </ListItem>
        )}
      </List>
    </TitleAccordion>
  )
}
