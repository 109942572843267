import React from 'react'
import { useContent } from '@local/src/Utils/Content/useContent'
import { Box, Typography } from '@mui/material'

interface MotivationProps {
  text: string
}

export const Motivation: React.FC<MotivationProps> = ({ text }) => {
  const { title } = useContent().manageCandidate.motivation

  return (
    <Box>
      <Typography variant="h6" mb={2}>
        {title}
      </Typography>
      <Typography variant="body1" color="text.secondary" whiteSpace="pre-line">
        {text}
      </Typography>
    </Box>
  )
}
