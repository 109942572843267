import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useSetStatusMutation } from '@local/src/Api/applicationsApi'
import { ApplicationStatus } from '@local/src/Api/types'
import { Formik } from 'formik'
import { useApplicationStatusFormValidationSchema } from '@local/src/Pages/DetailsPage/CandidateDrawer/Dialogs/ChangeApplicantStatusDialog/validationSchema'
import { useContent } from '@local/src/Utils/Content/useContent'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'
import { ApplicantStatusForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { useSnackbar } from '@local/src/Components/InfoSnackbar/Hooks/useSnackbar'
import { applicationStatusContentMapper } from '@local/src/Utils/Content/applicationStatusContentMapper'

interface ChangeApplicantStatusDialogProps {
  isOpen: boolean
  applicantName: string
  applicationId: string
  onConfirm: () => void
  onCancel: () => void
}

export const ChangeApplicantStatusDialog: React.FC<
  ChangeApplicantStatusDialogProps
> = ({ applicantName, applicationId, isOpen, onConfirm, onCancel }) => {
  const [setStatus, { isError, isLoading }] = useSetStatusMutation()
  const validationSchema = useApplicationStatusFormValidationSchema()
  const { showSnackbar } = useSnackbar()

  const {
    manageCandidate: {
      dialogInterested: {
        title,
        description,
        buttonCancel,
        buttonConfirm,
        snackbarMovedToInProgress,
      },
    },
    detailsPage: { applicationStatuses },
  } = useContent()

  const handleConfirm = async (values: ApplicantStatusForm) => {
    await setStatus({
      applicationId,
      status: values.status,
    })
      .then(() => {
        showSnackbar(snackbarMovedToInProgress)
        onConfirm()
      })
      .catch(console.error)
  }

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {replaceEpiVariables(title, { name: applicantName })}
        <IconButton onClick={onCancel}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={{ status: null }}
        validationSchema={validationSchema}
        onSubmit={(_, { setSubmitting }) => {
          setSubmitting(false)
        }}
      >
        {({ values, validateForm, handleChange, errors }) => (
          <>
            <DialogContent>
              <FormControl>
                <FormLabel focused={false} sx={{ mb: 1 }}>
                  {description}
                </FormLabel>
                <RadioGroup name="status" onChange={handleChange}>
                  {[
                    ApplicationStatus.InProgress,
                    ApplicationStatus.InterviewBooked,
                    ApplicationStatus.OfferPresented,
                  ].map((status) => (
                    <FormControlLabel
                      key={status}
                      value={status}
                      control={<Radio />}
                      label={applicationStatusContentMapper(
                        status,
                        applicationStatuses
                      )}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              {errors && (
                <FormHelperText sx={{ mx: 2 }} error>
                  {errors.status as string}
                </FormHelperText>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                variant="text"
                onClick={onCancel}
                color="primary"
                disabled={isLoading}
              >
                {buttonCancel}
              </Button>
              <Button
                variant="text"
                onClick={() =>
                  void validateForm().then(
                    async (errors) =>
                      Object.keys(errors).length === 0 &&
                      (await handleConfirm(values))
                  )
                }
                color="primary"
                disabled={isLoading}
              >
                {buttonConfirm}
              </Button>
            </DialogActions>
            {isError && (
              <FormHelperText error sx={{ mt: 2 }}>
                Något gick fel! Försök igen om en liten stund.
              </FormHelperText>
            )}
          </>
        )}
      </Formik>
    </Dialog>
  )
}
