import React from 'react'
import { useRecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/useRecruitmentForm'
import { FormControlLabel, Stack, Switch } from '@mui/material'
import { useContent } from '@local/src/Utils/Content/useContent'

export const ShowCompanyNameCheckbox: React.FC = () => {
  const { showCompanyNameCheckbox } = useContent().form.companyOwnerRadio
  const { values, handleChange } = useRecruitmentForm()

  const handleShowNameChange = (_event: unknown, checked: boolean) => {
    handleChange('showEmployingCompanyNameInJobAd')(values.id, checked)
  }

  return (
    <Stack spacing={2} mt={2} maxWidth={(t) => t.breakpoints.values.sm}>
      <FormControlLabel
        control={
          <Switch
            checked={values.showEmployingCompanyNameInJobAd}
            onChange={handleShowNameChange}
          />
        }
        label={showCompanyNameCheckbox}
      />
    </Stack>
  )
}
