import { WizardContext } from '@local/src/Components/Wizard/Hooks/WizardContext'
import { useContext } from 'react'

export interface WizardValues {
  step: number
  nextStep: () => void
  prevStep: () => void
}

export const useWizard = (): WizardValues => {
  const context = useContext(WizardContext)
  if (!context) {
    throw Error('Wrap your step with `Wizard`')
  } else {
    return context
  }
}
