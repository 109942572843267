import getConfig from '@local/src/Utils/getConfig'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getIdToken } from '@trr/app-shell-data'

export const prepareHeaders = (headers: Headers) => {
  const token = getIdToken()
  headers.set('authorization', `Bearer ${token}`)
  headers.set('Accept-Language', 'sv')
  return headers
}

const { API_URL } = getConfig()

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
    prepareHeaders,
  }),
  tagTypes: [
    'AllRecruitments',
    'Recruitment',
    'AllApplications',
    'Application',
  ],
  endpoints: () => ({}),
  reducerPath: 'baseApi',
})
