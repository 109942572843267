import { AppShellDataProps } from '@trr/app-shell-data'
import { LocationDescriptor } from 'history'

export const getAppShellDataBaseUrl = (
  appShellData: AppShellDataProps
): string =>
  appShellData.epiPagePath.replace(
    new RegExp(`${appShellData.currentKey}/?`),
    ''
  )

export const TAB_QUERY_KEY = 'tab'

export const getTabSearchLocation = <T extends string>(
  tabValue: T,
  pathname?: string
): LocationDescriptor => ({
  pathname,
  search: new URLSearchParams({
    [TAB_QUERY_KEY]: tabValue,
  }).toString(),
})
