import { baseApi } from '@local/src/Api/baseApi'

interface TotalCompletedMatchingProfileCountResponse {
  numberOfValidMatchProfiles: number
}

export const clientMatchProfileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTotalCompletedMatchingProfilesCount: builder.query<
      TotalCompletedMatchingProfileCountResponse,
      void
    >({
      query: () => `/client-match/profiles/count`,
    }),
  }),
})

export const { useGetTotalCompletedMatchingProfilesCountQuery } =
  clientMatchProfileApi
