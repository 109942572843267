import React, { useMemo } from 'react'
import { ApplicationStatus, RecruitmentApplication } from '@local/src/Api/types'
import { ApplicantCardProps } from '@local/src/Components/ApplicantCard/ApplicantCard'
import { ApplicantsList } from '@local/src/Pages/DetailsPage/Applicants/ActiveApplicants/ApplicantsList/ApplicantsList'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'
import { date } from '@trr/frontend-datetime'
import { useContent } from '@local/src/Utils/Content/useContent'
import { useDevice } from '@trr/app-shell-data'
import { CandidateDrawer } from '@local/src/Pages/DetailsPage/CandidateDrawer/CandidateDrawer'
import {
  useCurrentRecruitment,
  useCurrentRecruitmentId,
} from '@local/src/Utils/Hooks/useCurrentRecruitment'
import { FavoriteApplication } from '@local/src/Pages/DetailsPage/Components/FavoriteApplication'

export const RejectedApplicants: React.FC = () => {
  const [drawerApplicationId, setDrawerApplicationId] = React.useState<
    string | null
  >(null)

  const recruitmentId = useCurrentRecruitmentId()
  const { data: recruitment } = useCurrentRecruitment()

  const { emptyState, rejectedAtDateText, showCandidateLabel } =
    useContent().detailsPage.rejects

  const device = useDevice()

  const handleOpenDrawer = (applicationId: string) => {
    setDrawerApplicationId(applicationId)
  }

  const handleCloseDrawer = () => {
    setDrawerApplicationId(null)
  }

  const totalRequiredSkills = useMemo(
    () => recruitment?.position.skills.length ?? 0,
    [recruitment?.position.skills.length]
  )

  const mapRejectedApplicantsToCardProps = (
    applicant: RecruitmentApplication
  ): ApplicantCardProps => ({
    title: applicant.person.name,
    subtitle: replaceEpiVariables(rejectedAtDateText, {
      date: date.format(new Date(applicant.createdWhen), { year: true }),
    }),
    buttonLabel: showCandidateLabel,
    status: ApplicationStatus.Rejected,
    skillsFulfilled: applicant.skills,
    totalSkills: totalRequiredSkills,
    onShowApplicantClick: () => handleOpenDrawer(applicant.id),
    headerAction: (
      <FavoriteApplication
        applicationId={applicant.id}
        isFavorite={applicant.isFavourite}
      />
    ),
  })

  const calculateColumnsToShow = () => {
    if (device.isMobile) {
      return 1
    }
    if (device.isTablet) {
      return 2
    }
    return 3
  }

  return (
    <>
      <ApplicantsList
        recruitmentId={recruitmentId}
        columns={calculateColumnsToShow()}
        statuses={[ApplicationStatus.Rejected]}
        mapApplicationToCard={mapRejectedApplicantsToCardProps}
        emptyStateText={emptyState}
      />
      {recruitment && (
        <CandidateDrawer
          recruitment={recruitment}
          applicationId={drawerApplicationId}
          isOpen={!!drawerApplicationId}
          onClose={handleCloseDrawer}
        />
      )}
    </>
  )
}
