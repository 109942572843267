import React from 'react'
import { Editor } from '@tiptap/react'
import {
  Stack,
  StackProps,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import {
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  TextFields,
} from '@mui/icons-material'

const MARKS = [
  { value: 'bold', name: 'bold' },
  { value: 'italic', name: 'italic' },
  { value: 'bulletList', name: 'bulletList' },
  { value: 'orderedList', name: 'orderedList' },
  { value: 'heading6', name: 'heading', attr: { level: 6 } },
]

interface TextEditorToolbarProps extends StackProps {
  editor: Editor | null
}

const TextEditorToolbar: React.FC<TextEditorToolbarProps> = ({
  editor,
  ...props
}) => {
  if (!editor) return null

  const activeMarks = MARKS.filter((mark) =>
    editor.isActive(mark.name, mark.attr)
  ).map((m) => m.value)

  return (
    <Stack direction="row" spacing={2} {...props}>
      <ToggleButtonGroup value={activeMarks} size="small">
        <ToggleButton
          value="bold"
          onClick={() => editor.chain().focus().toggleMark('bold').run()}
        >
          <FormatBold fontSize="small" />
        </ToggleButton>
        <ToggleButton
          value="italic"
          onClick={() => editor.chain().focus().toggleMark('italic').run()}
        >
          <FormatItalic fontSize="small" />
        </ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup value={activeMarks} size="small">
        <ToggleButton
          value="heading6"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 6 }).run()
          }
        >
          <TextFields fontSize="small" />
        </ToggleButton>
        <ToggleButton
          value="bulletList"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        >
          <FormatListBulleted fontSize="small" />
        </ToggleButton>
        <ToggleButton
          value="orderedList"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        >
          <FormatListNumbered fontSize="small" />
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  )
}

export default TextEditorToolbar
