import { baseApi } from '@local/src/Api/baseApi'
import {
  ClosureReason,
  GetAllRecruitmentsRequest,
  GetAllRecruitmentsResponse,
  PatchApiRequests,
  RecruitmentResponse,
} from '@local/src/Api/types'

interface CloseRecruitmentRequest {
  id: string
  reason: ClosureReason.CandidateHired | ClosureReason.NotFilled
  applicationsToHire: string[]
}

export const recruitmentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createRecruitment: builder.mutation<{ id: string }, void>({
      query: () => ({
        url: '/recruitment/recruitments',
        method: 'POST',
      }),
      invalidatesTags: ['AllRecruitments'],
    }),
    getRecruitment: builder.query<RecruitmentResponse, string>({
      query: (id) => ({
        url: `/recruitment/recruitments/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: (_result, _error, id) => [{ type: 'Recruitment', id }],
    }),
    getAllRecruitments: builder.query<
      GetAllRecruitmentsResponse,
      GetAllRecruitmentsRequest
    >({
      query: ({ page = 1, pageSize = 10, status = null }) => ({
        url: '/recruitment/recruitments',
        method: 'GET',
        params: {
          page,
          pageSize,
          status,
        },
      }),
      providesTags: ['AllRecruitments'],
    }),
    patchRecruitment: builder.mutation<
      void,
      { id: string; patches: PatchApiRequests[] }
    >({
      query: ({ id, patches }) => ({
        url: `/recruitment/recruitments/${id}`,
        method: 'PATCH',
        body: patches,
      }),
      invalidatesTags: ['AllRecruitments'],
    }),
    publishRecruitment: builder.mutation<void, string>({
      query: (id) => ({
        url: `/recruitment/recruitments/${id}/publish`,
        method: 'PUT',
      }),
      invalidatesTags: ['AllRecruitments'],
    }),
    unpublishRecruitment: builder.mutation<void, string>({
      query: (id) => ({
        url: `/recruitment/recruitments/${id}/unpublishAd`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [
        'AllRecruitments',
        { type: 'Recruitment', id },
      ],
    }),
    deleteRecruitment: builder.mutation<void, string>({
      query: (id) => ({
        url: `/recruitment/recruitments/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AllRecruitments'],
      onQueryStarted: (arg, api) => {
        api.dispatch(
          recruitmentApi.util.updateQueryData(
            'getAllRecruitments',
            undefined,
            (data) => ({
              ...data,
              recruitments: data.recruitments.filter((r) => r.id !== arg),
            })
          )
        )
      },
    }),
    closeRecruitment: builder.mutation<void, CloseRecruitmentRequest>({
      query: ({ id, reason, applicationsToHire }) => ({
        url: `/recruitment/recruitments/${id}/closure`,
        method: 'PUT',
        body: { reason, applicationsToHire },
      }),
      invalidatesTags: (_result, _error, { id }) => [
        'AllRecruitments',
        { type: 'Recruitment', id },
      ],
    }),
    duplicateRecruitment: builder.mutation<{ id: string }, string>({
      query: (id) => ({
        url: `/recruitment/recruitments/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: ['AllRecruitments'],
    }),
  }),
})

export const {
  useCreateRecruitmentMutation,
  usePatchRecruitmentMutation,
  useGetRecruitmentQuery,
  useGetAllRecruitmentsQuery,
  usePublishRecruitmentMutation,
  useDeleteRecruitmentMutation,
  useUnpublishRecruitmentMutation,
  useCloseRecruitmentMutation,
  useDuplicateRecruitmentMutation,
} = recruitmentApi
