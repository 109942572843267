import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useSetStatusMutation } from '@local/src/Api/applicationsApi'
import { ApplicationStatus, ClosureReason } from '@local/src/Api/types'
import { Formik } from 'formik'
import { useContent } from '@local/src/Utils/Content/useContent'
import { useHiredApplicantFormValidationSchema } from '@local/src/Pages/DetailsPage/CandidateDrawer/Dialogs/HiredApplicantDialog/validationSchema'
import { ApplicantHiredForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { useCloseRecruitmentMutation } from '@local/src/Api/recruitmentApi'
import { useHotjar } from '@local/src/Utils/Hooks/useHotjar'

interface HiredApplicantDialogProps {
  applicationId: string
  recruitmentId: string
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
}

export const HiredApplicantDialog: React.FC<HiredApplicantDialogProps> = ({
  applicationId,
  recruitmentId,
  isOpen,
  onConfirm,
  onCancel,
}) => {
  const [setStatus, { isError, isLoading }] = useSetStatusMutation()
  const validationSchema = useHiredApplicantFormValidationSchema()
  const [closeRecruitment] = useCloseRecruitmentMutation()
  const { sendRecruitmentClosedEvent } = useHotjar()
  const {
    title,
    buttonCancel,
    buttonConfirm,
    description,
    optionEndProcess,
    optionKeepProcessGoing,
  } = useContent().manageCandidate.dialogOngoing

  const handleConfirm = async (values: ApplicantHiredForm) => {
    await updateRecruitmentStatus()

    if (String(values.endRecruitmentProcess) === 'true') {
      void closeRecruitment({
        id: recruitmentId,
        reason: ClosureReason.CandidateHired,
        applicationsToHire: null,
      })
        .then(() => {
          sendRecruitmentClosedEvent()
          onConfirm()
        })
        .catch((error) => console.log(error))
    } else {
      onConfirm()
    }
  }

  const updateRecruitmentStatus = async () => {
    await setStatus({
      applicationId,
      status: ApplicationStatus.Hired,
    }).catch(console.error)
  }

  const initialValues: ApplicantHiredForm = {
    endRecruitmentProcess: null,
  }

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {title}
        <IconButton onClick={onCancel}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(_, { setSubmitting }) => {
          setSubmitting(false)
        }}
      >
        {({ values, validateForm, handleChange, errors }) => (
          <>
            <DialogContent>
              <FormControl>
                <FormLabel focused={false} sx={{ mb: 1 }}>
                  {description}
                </FormLabel>
                <RadioGroup
                  name="endRecruitmentProcess"
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={'true'}
                    control={<Radio />}
                    label={optionEndProcess}
                  />
                  <FormControlLabel
                    value={'false'}
                    control={<Radio />}
                    label={optionKeepProcessGoing}
                  />
                </RadioGroup>
              </FormControl>
              {errors && (
                <FormHelperText sx={{ mx: 2 }} error>
                  {errors.endRecruitmentProcess}
                </FormHelperText>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                variant="text"
                onClick={onCancel}
                color="primary"
                disabled={isLoading}
              >
                {buttonCancel}
              </Button>
              <Button
                variant="text"
                onClick={() =>
                  void validateForm().then(
                    async (errors) =>
                      Object.keys(errors).length === 0 &&
                      (await handleConfirm(values))
                  )
                }
                color="primary"
                disabled={isLoading}
              >
                {buttonConfirm}
              </Button>
            </DialogActions>
            {isError && (
              <FormHelperText error sx={{ mt: 2 }}>
                Något gick fel! Försök igen om en liten stund.
              </FormHelperText>
            )}
          </>
        )}
      </Formik>
    </Dialog>
  )
}
