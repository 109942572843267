import React from 'react'
import { ClosureReasonField } from '@local/src/Pages/SettingsPage/ClosureSettings/ClosureSettingsForm/Fields/ClosureReasonField'
import { useCloseRecruitmentFormValidation } from '@local/src/Pages/SettingsPage/ClosureSettings/ClosureSettingsForm/closureSettingsFormValidation'
import { Formik, FormikProps } from 'formik'
import { CloseRecruitmentFormValues } from '@local/src/Pages/SettingsPage/ClosureSettings/ClosureSettingsForm/types'
import { ClosureInformationAlert } from '@local/src/Pages/SettingsPage/ClosureSettings/ClosureInformationAlert/ClosureInformationAlert'
import {
  ApplicationStatus,
  ClosureReason,
  RecruitmentApplicationsOrder,
} from '@local/src/Api/types'
import { HiredApplicantsField } from '@local/src/Pages/SettingsPage/ClosureSettings/ClosureSettingsForm/Fields/HiredApplicantsField'
import { Stack } from '@mui/material'
import { useGetRecruitmentApplicationsQuery } from '@local/src/Api/applicationsApi'
import { LoadingIndicator } from '@local/src/Components/LoadingIndicator/LoadingIndicator'

interface CloseRecruitmentFormProps {
  recruitmentId: string
}

// eslint-disable-next-line react/display-name
export const CloseRecruitmentForm = React.forwardRef<
  FormikProps<CloseRecruitmentFormValues>,
  CloseRecruitmentFormProps
>(({ recruitmentId }, ref) => {
  const validationSchema = useCloseRecruitmentFormValidation()

  const { data, isLoading } = useGetRecruitmentApplicationsQuery({
    recruitmentId,
    pageSize: 1000,
    page: 1,
    orderBy: RecruitmentApplicationsOrder.BestMatch,
  })

  const alreadyHiredApplicationIds =
    data?.applications
      .filter((a) => a.status === ApplicationStatus.Hired)
      .map((a) => a.id) || []

  if (isLoading) {
    return <LoadingIndicator />
  }

  const thereAreHiredCandidates = alreadyHiredApplicationIds.length > 0

  return (
    <Formik
      initialValues={{
        reason: null,
        applicationsToHire: alreadyHiredApplicationIds,
      }}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={ref}
    >
      {({ values }) => (
        <Stack spacing={3}>
          <ClosureReasonField
            disableOtherCandidateOptions={thereAreHiredCandidates}
            disableCandidateHiredOption={data?.applications.length === 0}
          />
          {values.reason === ClosureReason.CandidateHired && (
            <HiredApplicantsField applications={data?.applications} />
          )}
          <ClosureInformationAlert />
        </Stack>
      )}
    </Formik>
  )
})
