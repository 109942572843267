import { array, object, string } from 'yup'
import { ClosureReason } from '@local/src/Api/types'
import { CloseRecruitmentFormValues } from '@local/src/Pages/SettingsPage/ClosureSettings/ClosureSettingsForm/types'
import { useContent } from '@local/src/Utils/Content/useContent'

export const useCloseRecruitmentFormValidation = () => {
  const { reasonErrorMessage, hiredErrorMessage } =
    useContent().detailsPage.closure

  return object<CloseRecruitmentFormValues>().shape({
    reason: string().required(reasonErrorMessage),
    applicationsToHire: array()
      .of(string())
      .when('reason', {
        is: (val: string) => val === (ClosureReason.CandidateHired as string),
        then: (schema) => schema.min(1, hiredErrorMessage),
        otherwise: (schema) => schema.notRequired(),
      }),
  })
}
