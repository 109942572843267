import { useContext } from 'react'
import { SnackbarContext } from '@local/src/Components/InfoSnackbar/SnackbarProvider'

export const useSnackbar = () => {
  const context = useContext(SnackbarContext)
  if (!context) {
    throw new Error('useSnackbarContext must be used within a SnackbarProvider')
  }
  return context
}
