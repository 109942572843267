import { useEffect } from 'react'
import { useTrackVirtualView } from '@trr/gtm-tracking'
import { useWizard } from '@local/src/Components/Wizard/Hooks/useWizard'
import { useFeatureFlag } from '@local/src/Utils/Hooks/useFeatureFlag'

export const useWizardGtmTracker = (virtualSubviewName: string) => {
  const stepTrigger = useTrackVirtualView(virtualSubviewName)
  const { step } = useWizard()
  const { isGtmTrackingEnabled } = useFeatureFlag()

  useEffect(() => {
    if (isGtmTrackingEnabled) {
      stepTrigger(`Step: ${step}`)
    }
  }, [stepTrigger, step, isGtmTrackingEnabled])
}
