import { differenceInCalendarDays } from 'date-fns'

export const getTomorrowMidnightLocalTime = () => {
  const date = new Date()
  date.setDate(new Date().getDate() + 1)
  date.setHours(23, 59, 59, 0)
  return date
}

export const calculateDifferenceInDays = (date: Date) =>
  differenceInCalendarDays(date, new Date())
