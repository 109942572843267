import React from 'react'
import { ListItem, ListItemProps, ListItemText } from '@mui/material'

interface ApplicantsColumnHeaderProps extends ListItemProps {
  title: string
  subtitle: string
}

export const ApplicantsColumnHeader: React.FC<ApplicantsColumnHeaderProps> = ({
  title,
  subtitle,
  children,
  ...props
}) => (
  <ListItem {...props}>
    <ListItemText
      primary={title}
      secondary={subtitle}
      primaryTypographyProps={{ variant: 'h6' }}
      secondaryTypographyProps={{ variant: 'body2' }}
    />
    {children}
  </ListItem>
)
