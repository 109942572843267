import React from 'react'
import { usePatchRecruitment } from '@local/src/Api/Hooks/usePatchRecruitment'
import { ConfirmationDialog } from '@local/src/Components/ConfirmationDialog/ConfirmationDialog'
import {
  ContactNameField,
  ContactEmailField,
  ContactPhoneField,
} from '@local/src/Pages/SettingsPage/EditContactInfoDialog/Form/Fields'
import { ContactInfo } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { useContent } from '@local/src/Utils/Content/useContent'
import { FormHelperText, Typography } from '@mui/material'
import { Formik } from 'formik'
import { useContactInfoFormValidationSchema } from '@local/src/Pages/SettingsPage/EditContactInfoDialog/Form/validationSchema'
import { ContactInfoForm } from '@local/src/Pages/SettingsPage/EditContactInfoDialog/Form/contactInfoSchema'

interface EditContactInfoDialogProps {
  open: boolean
  recruitmentId: string
  onClose: (newContacts: ContactInfo[]) => void
  contacts?: ContactInfo[]
}

export const EditContactInfoDialog: React.FC<EditContactInfoDialogProps> = ({
  open,
  onClose,
  contacts,
  recruitmentId,
}) => {
  const { title, subtitle, recruiterFormTitle } = useContent().contactPopup

  const validationSchema = useContactInfoFormValidationSchema()

  const { patchContactInfo } = usePatchRecruitment()

  return open ? (
    <Formik
      initialValues={
        (contacts?.[0] ?? { name: '', email: '', phone: '' }) as ContactInfoForm
      }
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (contact, { setSubmitting }) => {
        try {
          await patchContactInfo(recruitmentId, contact)
          onClose([contact])
        } catch (rejected) {
          console.error(rejected)
        }
        setSubmitting(false)
      }}
    >
      {({ values, handleSubmit, handleChange, errors }) => (
        <ConfirmationDialog
          title={title}
          isOpen={open}
          onClose={() => onClose(null)}
          onConfirm={handleSubmit}
          confirmLabel="Spara"
          cancelLabel="Ångra"
        >
          <Typography variant="body1" gutterBottom>
            {subtitle}
          </Typography>
          <Typography variant="subtitle1">{recruiterFormTitle}</Typography>
          <ContactNameField
            value={values?.name}
            handleChange={handleChange}
            errors={errors}
          />
          <ContactEmailField
            value={values?.email}
            handleChange={handleChange}
            errors={errors}
          />
          <ContactPhoneField
            value={values?.phone}
            handleChange={handleChange}
            errors={errors}
          />
          {!!errors?.contactInfo && (
            <FormHelperText error>{errors.contactInfo}</FormHelperText>
          )}
        </ConfirmationDialog>
      )}
    </Formik>
  ) : null
}
