import React, { useMemo } from 'react'
import {
  ApplicationStatus,
  RecruitmentApplication,
  RecruitmentApplicationsOrder,
} from '@local/src/Api/types'
import { ApplicantCardProps } from '@local/src/Components/ApplicantCard/ApplicantCard'
import { ApplicantsList } from '@local/src/Pages/DetailsPage/Applicants/ActiveApplicants/ApplicantsList/ApplicantsList'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'
import { Grid2 as Grid } from '@mui/material'
import { date } from '@trr/frontend-datetime'
import { CandidateDrawer } from '@local/src/Pages/DetailsPage/CandidateDrawer/CandidateDrawer'
import { useContent } from '@local/src/Utils/Content/useContent'
import { useDevice } from '@trr/app-shell-data'
import { ChipTabs } from '@local/src/Pages/DetailsPage/Applicants/ActiveApplicants/ChipTabs/ChipTabs'
import { useSetStatusMutation } from '@local/src/Api/applicationsApi'
import {
  useCurrentRecruitment,
  useCurrentRecruitmentId,
} from '@local/src/Utils/Hooks/useCurrentRecruitment'
import { applicationStatusContentMapper } from '@local/src/Utils/Content/applicationStatusContentMapper'
import { dottedStringArray } from '@local/src/Utils/stringHelpers'
import { FavoriteApplication } from '@local/src/Pages/DetailsPage/Components/FavoriteApplication'

export const enum MobileTabs {
  Interested = 'Interested',
  Ongoing = 'Ongoing',
}

export const ActiveApplicants: React.FC = () => {
  const [drawerApplicationId, setDrawerApplicationId] = React.useState<
    string | null
  >(null)
  const recruitmentId = useCurrentRecruitmentId()
  const { data: recruitment } = useCurrentRecruitment()
  const [setStatus] = useSetStatusMutation()

  const { applicants: content, applicationStatuses } = useContent().detailsPage

  const handleOpenDrawer = (applicationId: string) => {
    setDrawerApplicationId(applicationId)
  }

  const handleChangeStatus = (
    applicationId: string,
    status: ApplicationStatus
  ) => {
    void setStatus({
      applicationId: applicationId,
      status,
    }).catch((error) => console.error(error))
  }

  const handleCloseDrawer = () => {
    setDrawerApplicationId(null)
  }

  const handleMobileTabClick = (tab: string) => {
    setActiveMobileTab(tab as MobileTabs)
  }

  const { isMobile } = useDevice()
  const [activeMobileTab, setActiveMobileTab] = React.useState(
    MobileTabs.Interested
  )
  const mobileTabs = [
    {
      value: MobileTabs.Interested,
      label: content.mobileInterestedTab,
      onClick: handleMobileTabClick,
    },
    {
      value: MobileTabs.Ongoing,
      label: content.mobileOngoingTab,
      onClick: handleMobileTabClick,
    },
  ]

  const totalRequiredSkills = useMemo(
    () => recruitment?.position.skills.length ?? 0,
    [recruitment?.position.skills.length]
  )

  const mapNewApplicantsToCardProps = (
    applicant: RecruitmentApplication
  ): ApplicantCardProps => ({
    title: applicant.person.name,
    subtitle: replaceEpiVariables(content.newApplicantDateText, {
      date: date.format(new Date(applicant.createdWhen), { year: true }),
    }),
    status: ApplicationStatus.Applied,
    buttonLabel: content.showCandidateLabel,
    skillsFulfilled: applicant.skills,
    totalSkills: totalRequiredSkills,
    onShowApplicantClick: () => handleOpenDrawer(applicant.id),
    headerAction: (
      <FavoriteApplication
        applicationId={applicant.id}
        isFavorite={applicant.isFavourite}
      />
    ),
  })

  const mapOngoingApplicantsToCardProps = (
    applicant: RecruitmentApplication
  ): ApplicantCardProps => ({
    title: applicant.person.name,
    subtitle: dottedStringArray([
      replaceEpiVariables(content.ongoingApplicantDateText, {
        date: date.format(new Date(applicant.lastModifiedWhen), { year: true }),
      }),
      applicationStatusContentMapper(applicant.status, applicationStatuses),
    ]),
    skillsFulfilled: applicant.skills,
    totalSkills: totalRequiredSkills,
    buttonLabel: content.showCandidateLabel,
    status: applicant.status,
    actionChip: {
      label: applicationStatusContentMapper(
        applicant.status,
        applicationStatuses
      ),
      optionGroups: [
        {
          subtitle: content.actionSubtitle,
          options: [
            ApplicationStatus.InProgress,
            ApplicationStatus.InterviewBooked,
            ApplicationStatus.OfferPresented,
            ApplicationStatus.Hired,
          ].map((status) => ({
            label: applicationStatusContentMapper(status, applicationStatuses),
            onClick: () => handleChangeStatus(applicant.id, status),
          })),
        },
      ],
    },
    onShowApplicantClick: () => handleOpenDrawer(applicant.id),
    headerAction: (
      <FavoriteApplication
        applicationId={applicant.id}
        isFavorite={applicant.isFavourite}
      />
    ),
  })

  return (
    <>
      {isMobile && <ChipTabs activeTab={activeMobileTab} tabs={mobileTabs} />}
      <Grid
        container
        columns={isMobile ? 1 : 2}
        columnSpacing={7}
        rowSpacing={3}
      >
        {(!isMobile || activeMobileTab === MobileTabs.Interested) && (
          <Grid size={1}>
            <ApplicantsList
              title={content.newApplicantsTitle}
              subtitle={content.newApplicantsSubtitle}
              recruitmentId={recruitmentId}
              statuses={[ApplicationStatus.Applied]}
              mapApplicationToCard={mapNewApplicantsToCardProps}
              orderOptions={[
                RecruitmentApplicationsOrder.BestMatch,
                RecruitmentApplicationsOrder.CreatedWhen,
              ]}
              emptyStateText={content.emptyNewApplicants}
            />
          </Grid>
        )}
        {(!isMobile || activeMobileTab === MobileTabs.Ongoing) && (
          <Grid size={1}>
            <ApplicantsList
              title={content.ongoingApplicantsTitle}
              subtitle={content.ongoingApplicantsSubtitle}
              recruitmentId={recruitmentId}
              statuses={[
                ApplicationStatus.InProgress,
                ApplicationStatus.InterviewBooked,
                ApplicationStatus.OfferPresented,
                ApplicationStatus.Hired,
              ]}
              mapApplicationToCard={mapOngoingApplicantsToCardProps}
              orderOptions={[
                RecruitmentApplicationsOrder.LastModified,
                RecruitmentApplicationsOrder.BestMatch,
              ]}
              emptyStateText={content.emptyOngoingApplicants}
            />
          </Grid>
        )}
      </Grid>
      {recruitment && (
        <CandidateDrawer
          recruitment={recruitment}
          applicationId={drawerApplicationId}
          isOpen={!!drawerApplicationId}
          onClose={handleCloseDrawer}
        />
      )}
    </>
  )
}
