import { useResolve } from '@local/src/Utils/Hooks/useResolve'
import { History, LocationDescriptor } from 'history'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const pushPathWithoutBlock = (
  history: History<unknown>,
  path: LocationDescriptor<unknown>
) => {
  history.block(() => {})
  history.push(path)
}

export const usePageLeave = (shouldBlockNavigation: boolean) => {
  const history = useHistory()

  const { isPending, promise, resolve } = useResolve<boolean>()

  const confirmProceedToPath = useCallback(
    async (path: string) => {
      const shouldProceed = await promise()
      if (shouldProceed) {
        pushPathWithoutBlock(history, path)
      }
    },
    [promise, history]
  )

  // Custom prompt for blocking internal navigation
  useEffect(() => {
    if (shouldBlockNavigation) {
      history.block((prompt) => {
        if (prompt.pathname) {
          void confirmProceedToPath(prompt.pathname + prompt.search)
          return false
        }
      })
    } else {
      history.block(() => {})
    }

    return () => {
      history.block(() => {})
    }
  }, [history, shouldBlockNavigation, confirmProceedToPath])

  // Browser prompt for blocking external navigation
  const onBeforeUnload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (shouldBlockNavigation) {
        e.preventDefault()
      }
    },
    [shouldBlockNavigation]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [onBeforeUnload])

  return {
    isAwaitingConfirmation: isPending,
    confirmPageLeave: () => resolve(true),
    cancelPageLeave: () => resolve(false),
  }
}
