import React from 'react'
import { useSetStatusMutation } from '@local/src/Api/applicationsApi'
import { Button } from '@mui/material'
import {
  RecruitmentApplication,
  RecruitmentResponse,
} from '@local/src/Api/types'
import { useContent } from '@local/src/Utils/Content/useContent'
import { CandidateHistoryLog } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/CandidateHistoryLog'
import { Motivation } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/Motivation'
import { SkillsChecklist } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/SkillsChecklist'
import { ChangeApplicantStatusDialog } from '@local/src/Pages/DetailsPage/CandidateDrawer/Dialogs/ChangeApplicantStatusDialog/ChangeApplicantStatusDialog'
import { StackDivider } from '@local/src/Components/StackDivider'
import { ContactInformation } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/ContactInformation'
import { ButtonStack } from '@local/src/Components/ButtonStack/ButtonStack'
import { useResolve } from '@local/src/Utils/Hooks/useResolve'
import { RejectApplicantDialog } from '@local/src/Pages/DetailsPage/CandidateDrawer/Dialogs/RejectApplicantDialog/RejectApplicantDialog'

interface AppliedCandidateProps {
  application: RecruitmentApplication
  recruitment: RecruitmentResponse
  onClose: () => void
}

export const AppliedCandidate: React.FC<AppliedCandidateProps> = ({
  application,
  recruitment,
  onClose,
}) => {
  const [, { isLoading: isSetStatusLoading }] = useSetStatusMutation()
  const { buttonPrimary, buttonSecondary } =
    useContent().manageCandidate.actionMoveToOngoing

  const {
    isPending: isPendingStatusChange,
    resolve: resolveStatusChange,
    promise: confirmStatusChange,
  } = useResolve<boolean>()

  const {
    isPending: isPendingReject,
    resolve: resolveReject,
    promise: confirmReject,
  } = useResolve<boolean>()

  const handleShowStatusChangeDialogClick = async () => {
    const confirmed = await confirmStatusChange()
    return confirmed ? onClose() : null
  }

  const handleShowRejectDialogClick = async () => {
    const confirmed = await confirmReject()
    confirmed && onClose()
  }

  return (
    <StackDivider data-testid="applied-candidate-wrapper">
      <CandidateHistoryLog historyLog={application?.history} />

      <Motivation text={application?.motivation} />

      <SkillsChecklist
        applicationSkills={application?.skills}
        recruitmentSkills={recruitment.position?.skills.map((x) => x.name)}
      />

      {(application?.person.phone || application?.person.email) && (
        <ContactInformation person={application.person} />
      )}

      <ButtonStack>
        <Button
          variant="contained"
          color="primary"
          onClick={() => void handleShowStatusChangeDialogClick()}
          disabled={isSetStatusLoading}
        >
          {buttonPrimary}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => void handleShowRejectDialogClick()}
          disabled={isSetStatusLoading}
        >
          {buttonSecondary}
        </Button>
      </ButtonStack>
      <ChangeApplicantStatusDialog
        applicantName={application?.person?.name ?? 'Användaren'}
        applicationId={application.id}
        isOpen={isPendingStatusChange}
        onConfirm={() => resolveStatusChange(true)}
        onCancel={() => resolveStatusChange(false)}
      />
      <RejectApplicantDialog
        applicationId={application.id}
        applicantName={application?.person?.name ?? 'Användaren'}
        isOpen={isPendingReject}
        onConfirm={() => resolveReject(true)}
        onCancel={() => resolveReject(false)}
      />
    </StackDivider>
  )
}
