import React from 'react'
import { useRecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/useRecruitmentForm'
import { TextField } from '@mui/material'
import { useContent } from '@local/src/Utils/Content/useContent'

export const ContactPhoneField: React.FC = () => {
  const { phoneLabel, phonePlaceholder } = useContent().form.contact

  const { values, errors, handleChange, handleBlur } = useRecruitmentForm()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange('contactInfo')(
      values.id,
      { ...values.contactInfo, phone: event.target.value },
      {
        validationType: 'onError',
      }
    )
  }

  return (
    <TextField
      fullWidth
      value={values?.contactInfo?.phone}
      label={phoneLabel}
      placeholder={phonePlaceholder}
      onChange={handleInputChange}
      error={!!errors.contactInfo?.phone}
      helperText={!!errors.contactInfo?.phone && errors.contactInfo?.phone}
      onBlur={() => handleBlur('contactInfo')(values.id)}
    />
  )
}
