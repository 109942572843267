import { Content } from '@local/src/Utils/Content/Content.types'
import { DeepPartial } from '@local/src/Utils/types'
import { merge } from 'lodash'

export const withFallbackContent = (content: DeepPartial<Content>): Content =>
  merge(fallbackContent, content)

export const fallbackContent: Content = {
  annonsera: {
    heading: 'annonsera.heading',
    ingress: 'annonsera.ingress',
    primaryActionLabel: 'annonsera.primaryActionLabel',
    secondaryActionLabel: 'annonsera.secondaryActionLabel',
  },
  skapadeAnnonser: {
    activeTab: 'skapadeAnnonser.activeTab',
    closedTab: 'skapadeAnnonser.closedTab',
    draftTab: 'skapadeAnnonser.draftTab',
    activeTabEmptyText: 'skapadeAnnonser.activeTabEmptyText',
    draftTabEmptyText: 'skapadeAnnonser.draftTabEmptyText',
    missingInformation: 'skapadeAnnonser.missingInformation',
    lastChangedDate: 'skapadeAnnonser.lastChangedDate',
    deleteTooltip: 'skapadeAnnonser.deleteTooltip',
    errorHeading: 'skapadeAnnonser.errorHeading',
    errorDescription: 'skapadeAnnonser.errorDescription',
    errorTryAgain: 'skapadeAnnonser.errorTryAgain',
    upcomingPublishEndDate: 'skapadeAnnonser.upcomingPublishEndDate',
    pastPublishEndDate: 'skapadeAnnonser.pastPublishEndDate',
    interestedApplicants: 'skapadeAnnonser.interestedApplicants',
    ongoingApplicants: 'skapadeAnnonser.ongoingApplicants',
    irrelevantApplicants: 'skapadeAnnonser.irrelevantApplicants',
    deleteDialogTitle: 'skapadeAnnonser.deleteDialogTitle',
    deleteDialogMessage: 'skapadeAnnonser.deleteDialogMessage',
    deleteDialogConfirmLabel: 'skapadeAnnonser.deleteDialogConfirmLabel',
    deleteDialogCancelLabel: 'skapadeAnnonser.deleteDialogCancelLabel',
    recruitmentDetailsPath: 'skapadeAnnonser.recruitmentDetailsPath',
  },
  closedRecruitments: {
    closedTabEmptyText: 'closedRecruitments.closedTabEmptyText',
    recruitmentEndDate: 'closedRecruitments.recruitmentEndDate',
    irrelevantApplicants: 'closedRecruitments.irrelevantApplicants',
    hiredApplicants: 'closedRecruitments.hiredApplicants',
  },
  onboarding: {
    title: 'onboarding.title',
    listItem1Heading: 'onboarding.listItem1Heading',
    listItem1Text: 'onboarding.listItem1Text',
    listItem2Heading: 'onboarding.listItem2Heading',
    listItem2Text: 'onboarding.listItem2Text',
  },
  form: {
    formSections: {
      companyInformationLabel: 'formSections.companyInformationLabel',
      recruitmentInformationLabel: 'formSections.recruitmentInformationLabel',
      employmentInformationLabel: 'formSections.employmentInformationLabel',
    },
    heading: {
      label: 'heading.label',
      placeholder: 'heading.placeholder',
    },
    jobLocation: {
      label: 'locations.label',
      placeholder: 'locations.placeholder',
      city: 'locations.city',
      municipality: 'locations.municipality',
      county: 'locations.county',
      country: 'locations.country',
    },
    skills: {
      label: 'skills.label',
      placeholder: 'skills.placeholder',
    },
    jobTitle: {
      label: 'jobTitle.label',
      placeholder: 'jobTitle.placeholder',
      description: 'jobTitle.description',
    },
    employmentDescription: {
      label: 'employmentDescription.label',
      placeholder: 'employmentDescription.placeholder',
    },
    companyDescription: {
      label: 'companyDescription.label',
      description: 'companyDescription.description',
    },
    companyOwnerRadio: {
      label: 'companyOwnerRadio.label',
      myCompanyCheckbox: 'companyOwnerRadio.myCompanyCheckbox',
      anotherCompanyCheckbox: 'companyOwnerRadio.anotherCompanyCheckbox',
      showCompanyNameCheckbox: 'showCompanyNameCheckbox',
      recruitingForEmploymentAgency: 'recruitingForEmploymentAgency',
    },
    employingCompanyName: {
      label: 'employingCompanyName.label',
      description: 'employingCompanyName.description',
      placeholder: 'employingCompanyName.placeholder',
      includeCompanyNameCheckboxLabel:
        'employingCompanyName.includeCompanyNameCheckboxLabel',
    },
    companyName: {
      label: 'companyName.label',
      placeholder: 'companyName.placeholder',
    },
    common: {
      maxChars: 'common.maxChars',
      minChars: 'common.minItems',
      minItems: 'common.minItems',
      maxItems: 'common.maxItems',
      requiredField: 'common.requiredField',
      requiredNamedField: 'common.requiredNamedField',
      requiredJobHeadingField: 'common.requiredJobHeadingField',
      requiredFieldEmploymentDescription:
        'common.requiredFieldEmploymentDescription',
      requiredNamedDateField: 'common.requiredNamedDateField',
      futureDate: 'common.futureDate',
      invalidDate: 'common.invalidDate',
      invalidPhone: 'common.invalidPhone',
      invalidPhoneNameRequired: 'common.invalidPhoneNameRequired',
      invalidEmail: 'common.invalidEmail',
    },
    recruitmentFormSteps: {
      startPagePath: '/recruitment',
      cancelLabel: 'recruitmentFormSteps.cancelLabel',
      nextLabel: 'recruitmentFormSteps.nextLabel',
      confirmCancelDialogTitle: 'recruitmentFormSteps.confirmCancelDialogTitle',
      confirmCancelDialogMessage:
        'recruitmentFormSteps.confirmCancelDialogMessage',
      confirmCancelDialogConfirmLabel:
        'recruitmentFormSteps.confirmCancelDialogConfirmLabel',
      confirmCancelDialogCancelLabel:
        'recruitmentFormSteps.confirmCancelDialogCancelLabel',
    },
    employmentInformation: {
      employmentTypeLabel: 'employmentInformation.employmentTypeLabel',
      workingHoursLabel: 'employmentInformation.workingHoursLabel',
      employmentTypeOptionEmployee:
        'employmentInformation.employmentTypeOptionEmployee',
      employmentTypeOptionHourly:
        'employmentInformation.employmentTypeOptionHourly',
      employmentTypeOptionSeasonal:
        'employmentInformation.employmentTypeOptionSeasonal',
      employmentTypeOptionOther:
        'employmentInformation.employmentTypeOptionOther',
      workingHoursOptionFullTime:
        'employmentInformation.workingHoursOptionFullTime',
      workingHoursOptionPartTime:
        'employmentInformation.workingHoursOptionPartTime',
    },
    contact: {
      title: 'contact.title',
      nameLabel: 'contact.nameLabel',
      namePlaceholder: 'contact.namePlaceholder',
      emailLabel: 'contact.emailLabel',
      emailPlaceholder: 'contact.emailPlaceholder',
      phoneLabel: 'contact.phoneLabel',
      phonePlaceholder: 'contact.phonePlaceholder',
      errorNotOnlyName: 'contact.errorNotOnlyName',
    },
  },
  publish: {
    heading: 'publish.heading',
    title: 'publish.title',
    description: 'publish.description',
    manualExpirationLabel: 'publish.manualTerminationLabel',
    automaticExpirationLabel: 'publish.automaticTerminationLabel',
    datePickerLabel: 'publish.datePickerLabel',
    datePickerErrorText: 'publish.datePickerErrorText',
    backButtonLabel: 'publish.backButtonLabel',
    publishButtonLabel: 'publish.publishButtonLabel',
    confirmDialogTitle: 'publish.confirmDialogTitle',
    confirmDialogMessage: 'publish.confirmDialogMessage',
    confirmDialogConfirmLabel: 'publish.confirmDialogConfirmLabel',
    confirmDialogCancelLabel: 'publish.confirmDialogCancelLabel',
    publishedDialogTitle: 'publish.publishedDialogTitle',
    publishedDialogMessage: 'publish.publishedDialogMessage',
    onPublishUrl: '/',
  },
  preview: {
    subtitle: 'preview.subtitle',
    companyNameLabel: 'preview.companyNameLabel',
    jobTitleLabel: 'preview.jobTitleLabel',
    locationLabel: 'preview.locationLabel',
    employmentDescriptionLabel: 'preview.employmentDescriptionLabel',
    companyDescriptionLabel: 'preview.companyDescriptionLabel',
    contactInformationLabel: 'preview.contactInformationLabel',
    skillsLabel: 'preview.skillsLabel',
    publicationEndDate: 'preview.publicationEndDate',
    publicationStartDate: 'preview.publicationStartDate',
    recruitingCompany: 'preview.recruitingCompany',
    unknownCompany: 'preview.unknownCompany',
    ongoingRecruitment: 'preview.ongoingRecruitment',
    employmentTypeOptionEmployee: 'preview.employmentTypeOptionEmployee',
    employmentTypeOptionHourly: 'preview.employmentTypeOptionHourly',
    employmentTypeOptionSeasonal: 'preview.employmentTypeOptionSeasonal',
    employmentTypeOptionOther: 'preview.employmentTypeOptionOther',
    workingHoursOptionFullTime: 'preview.workingHoursOptionFullTime',
    workingHoursOptionPartTime: 'preview.workingHoursOptionPartTime',
    employmentTypeLabel: 'preview.employmentTypeLabel',
    workingHoursLabel: 'preview.workingHoursLabel',
    contactNameLabel: 'preview.contactNameLabel',
    contactEmailLabel: 'preview.contactEmailLabel',
    contactPhoneLabel: 'preview.contactPhoneLabel',
    noContactContent: 'preview.noContactContent',
    contactTitle: 'preview.contactTitle',
    contactUpdatePromptLabel: 'preview.contactUpdatePromptLabel',
  },
  pageLeavePrompt: {
    title: 'pageLeavePrompt.title',
    body: 'pageLeavePrompt.body',
    confirmButtonLabel: 'pageLeavePrompt.confirmButtonLabel',
    cancelButtonLabel: 'pageLeavePrompt.cancelButtonLabel',
  },
  links: {
    startPagePath: '/links.startPagePath',
    createRecruitmentPagePath: '/links.createRecruitmentPagePath',
  },
  errorMessage: {
    heading: 'emptyResult.errorHeading',
    description: 'emptyResult.errorDescription',
    actionLabel: 'emptyResult.errorActionLabel',
    startPagePath: '/emptyResult.startPagePath',
  },
  detailsPage: {
    publication: {
      publicationStart: 'publicationDates.publicationStart',
      publicationWillExpire: 'publicationDates.publicationWillExpire',
      today: 'publicationDates.today',
      ongoingRecruitment: 'publicationDates.ongoingRecruitment',
      daysLeftPlural: 'publicationDates.daysLeftPlural',
      daysLeftSingular: 'publicationDates.daysLeftSingular',
      buttonLabel: 'publicationDates.buttonLabel',
      dialogTitle: 'publicationDates.dialogTitle',
      dialogMessage: 'publicationDates.dialogMessage',
      dialogConfirmLabel: 'publicationDates.dialogConfirmLabel',
      dialogCancelLabel: 'publicationDates.dialogCancelLabel',
      unpublishHeading: 'publicationDates.publishedHeading',
      unpublishedHeading: 'publicationDates.unpublishedHeading',
      unpublishMessage: 'publicationDates.publishedMessage',
    },
    rejects: {
      emptyState: 'rejects.emptyState',
      rejectedAtDateText: 'rejects.rejectedAtDateText',
      showCandidateLabel: 'rejects.showCandidateLabel',
    },
    settingsButton: {
      label: 'settingsButton.label',
      closedLabel: 'settingsButton.closedLabel',
      path: '/settingsButton.path',
    },
    errorMessage: {
      heading: 'errorMessage.heading',
      description: 'errorMessage.description',
      actionLabel: 'errorMessage.actionLabel',
      startPagePath: '/errorMessage.startPagePath',
    },
    settingsView: {
      heading: 'settingsView.heading',
      previewSubtitle: 'settingsView.previewSubtitle',
      contactSubtitle: 'settingsView.contactSubtitle',
    },
    closure: {
      reasonErrorMessage: 'closure.reasonErrorMessage',
      reasonLabel: 'closure.reasonLabel',
      reasonNotFilled: 'closure.reasonNotFilled',
      reasonCandidateHired: 'closure.reasonCandidateHired',
      reasonAnotherCandidateHired: 'closure.reasonAnotherCandidateHired',
      hiredPeopleLabel: 'closure.hiredPeopleLabel',
      hiredPeopleDescription: 'closure.hiredPeopleDescription',
      hiredErrorMessage: 'closure.hiredErrorMessage',
      confirmationDialogTitle: 'closure.confirmationDialogTitle',
      confirmationDialogMessage: 'closure.confirmationDialogMessage',
      confirmationDialogConfirmLabel: 'closure.confirmationDialogConfirmLabel',
      confirmationDialogCancelLabel: 'closure.confirmationDialogCancelLabel',
      closeActionButtonLabel: 'closure.closeActionButtonLabel',
      closeStatusTitle: 'closure.closeStatusTitle',
      closeStatusMessage: 'closure.closeStatusMessage',
      closedStatusTitle: 'closure.closedStatusTitle',
      informationAlert: 'closure.informationAlert',
    },
    applicants: {
      activeTab: 'applicants.activeTab',
      notRelevantTab: 'applicants.notRelevantTab',
      sortLabel: 'applicants.sortLabel',
      sortOptionBestMatchLabel: 'applicants.sortOptionBestMatchLabel',
      sortOptionApplicationDateLabel:
        'applicants.sortOptionApplicationDateLabel',
      sortOptionLastUpdatedLabel: 'applicants.sortOptionLastUpdatedLabel',
      newApplicantsTitle: 'applicants.newApplicantsTitle',
      newApplicantsSubtitle: 'applicants.newApplicantsSubtitle',
      newApplicantDateText: 'applicants.newApplicantDateText',
      ongoingApplicantsTitle: 'applicants.ongoingApplicantsTitle',
      ongoingApplicantsSubtitle: 'applicants.ongoingApplicantsSubtitle',
      ongoingApplicantDateText: 'applicants.ongoingApplicantDateText',
      emptyNewApplicants: 'applicants.emptyNewApplicants',
      emptyOngoingApplicants: 'applicants.emptyOngoingApplicants',
      mobileInterestedTab: 'applicants.mobileInterestedTab',
      mobileOngoingTab: 'applicants.mobileOngoingTab',
      actionSubtitle: 'applicants.actionSubtitle',
      closedRecruitmentApplicantsText:
        'applicants.closedRecruitmentApplicantsText',
      showCandidateLabel: 'applicants.showCandidateLabel',
      closedApplicantDateText: 'applicants.closedApplicantDateText',
    },
    applicationStatuses: {
      applied: 'applicationStatuses.applied',
      inProgress: 'applicationStatuses.inProgress',
      interviewBooked: 'applicationStatuses.interviewBooked',
      offerPresented: 'applicationStatuses.offerPresented',
      rejected: 'applicationStatuses.rejected',
      hired: 'applicationStatuses.hired',
    },
    common: {
      futureDate: 'common.futureDate',
      invalidDate: 'common.invalidDate',
      invalidPhone: 'common.invalidPhone',
      maxChars: 'common.maxChars',
      maxItems: 'common.maxItems',
      minChars: 'common.minChars',
      minItems: 'common.minItems',
      requiredField: 'common.requiredField',
      requiredFieldEmploymentDescription:
        'common.requiredFieldEmploymentDescription',
      requiredNamedField: 'common.requiredNamedField',
      requiredNamedDateField: 'common.requiredNamedDateField',
      requiredJobHeadingField: 'common.requiredJobHeadingField',
      invalidPhoneNameRequired: 'common.invalidPhoneNameRequired',
      invalidEmail: 'common.invalidEmail',
    },
  },
  manageCandidate: {
    actionMoveToHired: {
      buttonPrimary: 'manageCandidate.actionMoveToHired.buttonPrimary',
      buttonSecondary: 'manageCandidate.actionMoveToHired.buttonSecondary',
      description: 'manageCandidate.actionMoveToHired.description',
      title: 'manageCandidate.actionMoveToHired.title',
      rejectedSnackbarMessage:
        'manageCandidate.actionMoveToHired.rejectedSnackbarMessage',
    },
    actionMoveToOngoing: {
      buttonPrimary: 'manageCandidate.actionMoveToOngoing.buttonPrimary',
      buttonSecondary: 'manageCandidate.actionMoveToOngoing.buttonSecondary',
      rejectedSnackbarMessage:
        'manageCandidate.actionMoveToOngoing.rejectedSnackbarMessage',
    },
    actionEndRecruitmentProcess: {
      title: 'manageCandidate.actionEndRecruitmentProcess.title',
      description: 'manageCandidate.actionEndRecruitmentProcess.description',
      buttonText: 'manageCandidate.actionEndRecruitmentProcess.buttonText',
    },
    dialogCloseRecruitment: {
      title: 'manageCandidate.dialogCloseRecruitment.title',
      description: 'manageCandidate.dialogCloseRecruitment.description',
      confirmButton: 'manageCandidate.dialogCloseRecruitment.confirmButton',
      cancelButton: 'manageCandidate.dialogCloseRecruitment.cancelButton',
      onCloseUrl: '/manageCandidate.dialogCloseRecruitment.onCloseUrl',
      confirmSnackbarMessage:
        'manageCandidate.dialogCloseRecruitment.confirmSnackbarMessage',
    },
    history: {
      title: 'manageCandidate.history.title',
      emptyState: 'manageCandidate.history.emptyState',
      showLess: 'manageCandidate.history.showLess',
      showMore: 'manageCandidate.history.showMore',
    },
    motivation: {
      title: 'manageCandidate.motivation.title',
    },
    skills: {
      title: 'manageCandidate.skills.title',
      description: 'manageCandidate.skills.description',
    },
    contactInformation: {
      title: 'manageCandidate.contactInformation.title',
    },
    dialogInterested: {
      title: 'confirmOngoing.title',
      description: 'confirmOngoing.description',
      radioButtonInProgress: 'confirmOngoing.radioButtonInProgress',
      radioButtonInterview: 'confirmOngoing.radioButtonInterview',
      radioButtonOfferPresented: 'confirmOngoing.radioButtonOffer',
      buttonConfirm: 'confirmOngoing.confirmButton',
      buttonCancel: 'confirmOngoing.cancelButton',
      requiredField: 'confirmOngoing.requiredField',
      snackbarMovedToInProgress: 'confirmOngoing.snackbarMovedToInProgress',
    },
    dialogOngoing: {
      title: 'ongoingDialog.title',
      description: 'ongoingDialog.description',
      optionEndProcess: 'ongoingDialog.optionEndProcess',
      optionKeepProcessGoing: 'ongoingDialog.optionKeepProcessGoing',
      buttonConfirm: 'ongoingDialog.confirmButton',
      buttonCancel: 'ongoingDialog.cancelButton',
      requiredField: 'ongoingDialog.requiredField',
    },
    actionFavorite: {
      label: 'actionFavorite.label',
    },
    dialogReject: {
      title: 'rejectDialog.title',
      description: 'rejectDialog.description',
      confirmButton: 'rejectDialog.confirmButton',
      cancelButton: 'rejectDialog.cancelButton',
    },
  },
  contactPopup: {
    title: 'contactPopup.title',
    emailPlaceholder: 'contactPopup.emailPlaceholder',
    namePlaceholder: 'contactPopup.namePlaceholder',
    phonePlaceholder: 'contactPopup.phonePlaceholder',
    recruiterFormTitle: 'contactPopup.recruiterFormTitle',
    subtitle: 'contactPopup.subtitle',
  },
}
