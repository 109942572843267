import React, { PropsWithChildren, useState } from 'react'
import { Snackbar } from '@mui/material'

interface SnackbarContextProps extends PropsWithChildren {
  show?: boolean
  message?: string
  timeout?: number
  showSnackbar?: (message: string, timeout?: number) => void
}

export const SnackbarContext = React.createContext<SnackbarContextProps | null>(
  null
)

export const SnackbarProvider: React.FC<SnackbarContextProps> = ({
  children,
}) => {
  const [show, setShow] = useState<boolean>(false)
  const [message, setMessage] = useState<string>(null)
  const [timeout, setTimeout] = useState<number>(null)

  const showSnackbar = (message: string, timeout = 6000) => {
    setShow(true)
    setMessage(message)
    setTimeout(timeout)
  }

  return (
    <SnackbarContext.Provider value={{ show, message, timeout, showSnackbar }}>
      {children}
      <Snackbar
        open={show}
        message={message}
        autoHideDuration={timeout}
        sx={{ width: 462 }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setShow(false)}
      />
    </SnackbarContext.Provider>
  )
}
