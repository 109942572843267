import React, { useId } from 'react'
import { useRecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/useRecruitmentForm'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { EmploymentType } from '@local/src/Api/types'
import { useContent } from '@local/src/Utils/Content/useContent'

export const EmploymentTypeField: React.FC = () => {
  const { handleChange, values, errors } = useRecruitmentForm()

  const labelId = useId()

  const content = useContent().form.employmentInformation

  const handleChangeEmploymentType = (
    event: SelectChangeEvent<EmploymentType>
  ) => {
    const value = event.target.value as EmploymentType
    handleChange('employmentType')(values.id, value)
  }

  return (
    <FormControl fullWidth error={!!errors.employmentType}>
      <InputLabel id={labelId}>{content.employmentTypeLabel}</InputLabel>
      <Select
        labelId={labelId}
        label={content.employmentTypeLabel}
        value={values.employmentType}
        defaultValue=""
        onChange={handleChangeEmploymentType}
        data-testid="employment-type-field"
      >
        <MenuItem value={EmploymentType.Employee}>
          {content.employmentTypeOptionEmployee}
        </MenuItem>
        <MenuItem value={EmploymentType.Hourly}>
          {content.employmentTypeOptionHourly}
        </MenuItem>
        <MenuItem value={EmploymentType.Seasonal}>
          {content.employmentTypeOptionSeasonal}
        </MenuItem>
        <MenuItem value={EmploymentType.Other}>
          {content.employmentTypeOptionOther}
        </MenuItem>
      </Select>
      <FormHelperText>{errors.employmentType}</FormHelperText>
    </FormControl>
  )
}
