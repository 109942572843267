import {
  ExpirationType,
  PublicationForm,
} from '@local/src/Pages/DraftPage/PublicationFormView/Form/types'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'
import { useContent } from '@local/src/Utils/Content/useContent'
import { getTomorrowMidnightLocalTime } from '@local/src/Utils/dateHelpers'
import { date, mixed, object } from 'yup'

const tomorrow = getTomorrowMidnightLocalTime()

export const usePublicationFormValidationSchema = () => {
  const { futureDate, invalidDate, requiredNamedDateField } =
    useContent().form.common

  return object<PublicationForm>().shape({
    endDateRadio: mixed<ExpirationType>().required(),
    endDate: date()
      .min(tomorrow, futureDate)
      .typeError(invalidDate)
      .when('endDateRadio', {
        is: (val: ExpirationType) => val === ExpirationType.Auto,
        then: (schema) =>
          schema.required(
            replaceEpiVariables(requiredNamedDateField, { name: 'slutdatum' })
          ),
        otherwise: (schema) => schema.nullable().notRequired(),
      }),
  })
}
