import React from 'react'
import { useGetAllRecruitmentsQuery } from '@local/src/Api/recruitmentApi'
import {
  RecruitmentStatus,
  RecruitmentsWithApplicationStatusCategories,
} from '@local/src/Api/types'
import { useContent } from '@local/src/Utils/Content/useContent'
import {
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material'
import EmptyResult from '@local/src/Components/EmptyResult/EmptyResult'

interface RecruitmentListProps {
  status: RecruitmentStatus
  emptyText: string
  renderListItem: (
    recruitment: RecruitmentsWithApplicationStatusCategories
  ) => React.ReactNode
}

export const RecruitmentsList: React.FC<RecruitmentListProps> = ({
  emptyText,
  renderListItem,
  status,
}) => {
  const [page, setPage] = React.useState(1)
  const { data, isLoading, isError, refetch } = useGetAllRecruitmentsQuery({
    page,
    pageSize: 10,
    status,
  })

  const {
    skapadeAnnonser: { errorDescription, errorHeading, errorTryAgain },
  } = useContent()

  if (isLoading) {
    return <CircularProgress sx={{ m: 1, alignSelf: 'center' }} />
  }

  if (isError) {
    return (
      <EmptyResult
        m={2}
        heading={errorHeading}
        description={errorDescription}
        action={{
          label: errorTryAgain,
          onClick: () => {
            void refetch()
          },
        }}
      />
    )
  }

  if (!data?.recruitments?.length) {
    if (page > 1) {
      setPage((prev) => prev - 1)
    } else {
      return (
        <Typography mt={3} ml={1} color="text.secondary">
          {emptyText}
        </Typography>
      )
    }
  }

  return (
    <Stack spacing={4}>
      <TableContainer>
        <Table sx={{ tableLayout: 'fixed' }} component="div">
          <TableBody component="div">
            {data.recruitments.map(renderListItem)}
          </TableBody>
        </Table>
      </TableContainer>
      {data.totalPages > 1 && (
        <Pagination
          page={page}
          onChange={(_, newPage) => setPage(newPage)}
          count={data.totalPages}
          sx={{ alignSelf: 'center' }}
        />
      )}
    </Stack>
  )
}
