import React from 'react'
import { Stack, Tabs, Tab, Box } from '@mui/material'
import { useContent } from '@local/src/Utils/Content/useContent'
import { RecruitmentDrafts } from '@local/src/Pages/StartPage/CreatedRecruitments/RecruitmentDrafts/RecruitmentDrafts'
import { ActiveRecruitments } from '@local/src/Pages/StartPage/CreatedRecruitments/ActiveRecruitments/ActiveRecruitments'
import { ClosedRecruitments } from '@local/src/Pages/StartPage/CreatedRecruitments/ClosedRecruitments/ClosedRecruitments'
import { useQuery } from '@local/src/Utils/Hooks/useQuery'
import { useHistory } from 'react-router-dom'
import {
  TAB_QUERY_KEY,
  getTabSearchLocation,
} from '@local/src/Utils/urlHelpers'

export enum CreatedRecruitmentsTabs {
  Active = 'aktiva',
  Closed = 'avslutade',
  Drafts = 'utkast',
}

const CreatedRecruitments = () => {
  const { activeTab, draftTab, closedTab } = useContent().skapadeAnnonser

  const query = useQuery<typeof TAB_QUERY_KEY, CreatedRecruitmentsTabs>()
  const tab = query.get(TAB_QUERY_KEY) ?? CreatedRecruitmentsTabs.Active

  const history = useHistory()

  const handleChangeTab = (event: unknown, value: CreatedRecruitmentsTabs) => {
    history.replace(getTabSearchLocation(value), { preventScroll: true })
  }

  return (
    <Stack spacing={0}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} variant="scrollable" onChange={handleChangeTab}>
          <Tab label={activeTab} value={CreatedRecruitmentsTabs.Active} />
          <Tab
            label={closedTab}
            value={CreatedRecruitmentsTabs.Closed}
            data-testid="closed-tab"
          />
          <Tab
            label={draftTab}
            value={CreatedRecruitmentsTabs.Drafts}
            data-testid="drafts-tab"
          />
        </Tabs>
      </Box>

      {tab === CreatedRecruitmentsTabs.Active && <ActiveRecruitments />}
      {tab === CreatedRecruitmentsTabs.Closed && <ClosedRecruitments />}
      {tab === CreatedRecruitmentsTabs.Drafts && <RecruitmentDrafts />}
    </Stack>
  )
}

export default CreatedRecruitments
