import React from 'react'
import { StatusListItem } from '@local/src/Components/StatusListItem/StatusListItem'
import { useContent } from '@local/src/Utils/Content/useContent'
import { date } from '@trr/frontend-datetime'

interface PublicationStatusListItemProps {
  isPublished?: boolean
  endDate?: Date
  onUnpublishClick?: () => void
}

export const PublicationStatusListItem: React.FC<
  PublicationStatusListItemProps
> = ({ isPublished = false, endDate, onUnpublishClick }) => {
  const {
    unpublishHeading,
    unpublishedHeading,
    unpublishMessage,
    buttonLabel,
  } = useContent().detailsPage.publication

  const primarytext = isPublished ? unpublishHeading : unpublishedHeading
  const secondaryText = isPublished
    ? unpublishMessage
    : date.format(endDate, { year: true })

  return (
    <StatusListItem
      primaryText={primarytext}
      secondaryText={secondaryText}
      isChecked={!isPublished}
      actionButton={
        isPublished && onUnpublishClick
          ? { label: buttonLabel, onClick: onUnpublishClick }
          : undefined
      }
    />
  )
}
