import { Chip, Stack } from '@mui/material'
import React from 'react'

interface SkillListProps {
  skills: string[]
}

const SkillsList: React.FC<SkillListProps> = ({ skills }) => (
  <Stack direction="row" flexWrap="wrap" gap={1}>
    {skills.map((skill) => (
      <Chip
        label={skill}
        key={skill}
        variant="filled"
        data-testid="skill-chip"
      />
    ))}
  </Stack>
)

export default SkillsList
