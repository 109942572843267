/**
 *  Replaces Epi variables in a string with the provided values.
 *  Example usage:
 *
 *  replaceEpiVariables(titleWithName, {
 *    name: 'John Doe',
 *  })
 */
export const replaceEpiVariables = (
  text: string,
  variables: { [key: string]: string } = {}
) => {
  let result = text
  Object.keys(variables).forEach((key) => {
    const regex = new RegExp(`{{${key}}}`, 'g')
    result = result?.replace(regex, variables[key])
  })
  return result
}
