import React, { useEffect } from 'react'
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'
import Illustration from '@local/src/Pages/StartPage/AnnonseraCard/Illustration'
import { useContent } from '@local/src/Utils/Content/useContent'
import { useGetTotalCompletedMatchingProfilesCountQuery } from '@local/src/Api/clientMatchProfileApi'
import { useDevice } from '@trr/app-shell-data'
import { useHistory } from 'react-router-dom'
import { useCreateRecruitmentMutation } from '@local/src/Api/recruitmentApi'
import { OnboardingDialog } from '@local/src/Pages/StartPage/AnnonseraCard/OnboardingDialog'

const StyledBackdrop = styled(Backdrop)(() => ({
  zIndex: 1,
  '&&': {
    margin: 0,
  },
}))

interface AnnonseraCardProps {
  showSecondaryButton: boolean
}

const AnnonseraCard: React.FC<AnnonseraCardProps> = ({
  showSecondaryButton,
}) => {
  const {
    links: { createRecruitmentPagePath },
    annonsera: { heading, ingress, primaryActionLabel, secondaryActionLabel },
  } = useContent()

  const history = useHistory()
  const [showOnboardingDialog, setShowOnboardingDialog] = React.useState(false)

  const {
    data: completedMatchingProfilesResponse,
    isLoading,
    isError,
  } = useGetTotalCompletedMatchingProfilesCountQuery()

  const [
    createRecruitment,
    {
      data: createdRecruitment,
      isSuccess: isSuccessCreateRecruitment,
      isLoading: isLoadingCreateRecruitment,
    },
  ] = useCreateRecruitmentMutation()

  const { isXL, isDesktop } = useDevice()

  const isDesktopOrXL = isXL || isDesktop

  const handleCreateRecruitmentClicked = () => {
    void createRecruitment()
  }

  const handleToggleOnboardingDialog = () => {
    setShowOnboardingDialog(!showOnboardingDialog)
  }

  useEffect(() => {
    if (isSuccessCreateRecruitment && createdRecruitment?.id) {
      history.push(`${createRecruitmentPagePath}${createdRecruitment.id}`)
    }
  }, [
    isSuccessCreateRecruitment,
    createdRecruitment,
    history,
    createRecruitmentPagePath,
  ])

  if (
    isLoading ||
    isError ||
    !completedMatchingProfilesResponse?.numberOfValidMatchProfiles
  )
    return null

  return (
    <>
      <StyledBackdrop open={!!isLoadingCreateRecruitment}>
        <CircularProgress />
      </StyledBackdrop>
      <Card
        variant="outlined"
        sx={{ position: 'relative', bgcolor: 'transparent' }}
      >
        <Box>
          <Stack direction="row" justifyContent="space-between">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                width: isDesktopOrXL ? 'calc(100% - 160px)' : undefined,
              }}
            >
              <CardContent>
                <Typography variant="h6">
                  {replaceEpiVariables(heading, {
                    count:
                      completedMatchingProfilesResponse.numberOfValidMatchProfiles.toString(),
                  })}
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom>
                  {ingress}
                </Typography>
              </CardContent>
              <CardActions sx={{ px: 3 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleCreateRecruitmentClicked}
                >
                  {primaryActionLabel}
                </Button>
                {showSecondaryButton && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleToggleOnboardingDialog}
                  >
                    {secondaryActionLabel}
                  </Button>
                )}
              </CardActions>
            </Box>
            {isDesktopOrXL && (
              <Illustration
                style={{
                  height: 'auto',
                  width: '160px',
                  alignSelf: 'flex-end',
                }}
                data-testid="annonsera-card-illustration"
              />
            )}
          </Stack>
        </Box>
      </Card>
      <OnboardingDialog
        isOpen={showOnboardingDialog}
        onClose={handleToggleOnboardingDialog}
      />
    </>
  )
}

export default AnnonseraCard
