import React from 'react'
import { useRecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/useRecruitmentForm'
import { TextField } from '@mui/material'
import { useContent } from '@local/src/Utils/Content/useContent'

export const ContactEmailField: React.FC = () => {
  const { emailLabel, emailPlaceholder } = useContent().form.contact

  const { values, errors, handleChange, handleBlur } = useRecruitmentForm()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange('contactInfo')(
      values.id,
      { ...values.contactInfo, email: event.target.value },
      {
        validationType: 'onError',
      }
    )
  }

  return (
    <TextField
      fullWidth
      value={values?.contactInfo?.email}
      label={emailLabel}
      placeholder={emailPlaceholder}
      onChange={handleInputChange}
      error={!!errors.contactInfo?.email}
      helperText={!!errors.contactInfo?.email && errors.contactInfo?.email}
      onBlur={() => handleBlur('contactInfo')(values.id)}
    />
  )
}
