import { ApplicationStatus } from '@local/src/Api/types'
import { ApplicationStatusContent } from '@local/src/Utils/Content/Content.types'

export const applicationStatusContentMapper = (
  status: ApplicationStatus,
  content: ApplicationStatusContent
) =>
  ({
    [ApplicationStatus.Hired]: content.hired,
    [ApplicationStatus.Rejected]: content.rejected,
    [ApplicationStatus.InProgress]: content.inProgress,
    [ApplicationStatus.InterviewBooked]: content.interviewBooked,
    [ApplicationStatus.OfferPresented]: content.offerPresented,
    [ApplicationStatus.Applied]: content.applied,
    [ApplicationStatus.Withdrawn]: '',
  })[status] ?? ''
