import React from 'react'
import {
  ContactNameField,
  ContactEmailField,
  ContactPhoneField,
} from '@local/src/Pages/DraftPage/RecruitmentFormView/Fields/ContactInfo/Fields'
import { ResponsiveWrapper } from '@local/src/Components/ResponsiveWrapper/ResponsiveWrapper'
import { Typography } from '@mui/material'
import { useRecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/useRecruitmentForm'

export const ContactInfoForm = () => {
  const { errors } = useRecruitmentForm()

  const contactFormError: string =
    typeof errors.contactInfo === 'string' ? errors.contactInfo : undefined

  return (
    <>
      <ResponsiveWrapper size={{ xs: 12, sm: 8 }} gap={2}>
        <ContactNameField />
        <ContactEmailField />
        <ContactPhoneField />
      </ResponsiveWrapper>
      {contactFormError && (
        <Typography variant="body2" color="error">
          {contactFormError}
        </Typography>
      )}
    </>
  )
}
