import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  CircularProgress,
  Stack,
  Step,
  StepButton,
  StepLabel,
  Stepper,
} from '@mui/material'
import { WizardContext } from '@local/src/Components/Wizard/Hooks/WizardContext'
import { WizardValues } from '@local/src/Components/Wizard/Hooks/useWizard'
import { useDevice } from '@trr/app-shell-data'

interface WizardProps extends PropsWithChildren {
  labels?: string[]
  onStepChange?: () => void
  isLoading?: boolean
  isError?: boolean
  errorComponent?: React.ReactNode
}

export const Wizard: React.FC<WizardProps> = ({
  children,
  labels,
  onStepChange,
  isError,
  isLoading,
  errorComponent,
}) => {
  const [step, setStep] = useState(0)

  const device = useDevice()

  const minStep = 0
  const maxStep = labels?.length - 1

  const nextStep = useCallback(() => {
    setStep((prev) => Math.min(prev + 1, maxStep))
    onStepChange?.()
  }, [maxStep, onStepChange])

  const prevStep = useCallback(() => {
    setStep((prev) => Math.max(prev - 1, minStep))
    onStepChange?.()
  }, [minStep, onStepChange])

  const handleLabelClick = (index: number) => () => {
    setStep(index)
  }

  const scrollToTop = useCallback(() => {
    const stepperTop = document.getElementById('stepper-top')
    const hasScrolledPastStepper = stepperTop?.getBoundingClientRect().top < 0
    if (hasScrolledPastStepper) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [])

  useEffect(() => {
    scrollToTop()
  }, [step, scrollToTop])

  const contextValue = useMemo<WizardValues>(
    () => ({
      step,
      nextStep,
      prevStep,
    }),
    [step, nextStep, prevStep]
  )

  const Content = () => {
    if (isLoading) {
      return <CircularProgress sx={{ m: 1, alignSelf: 'center' }} />
    }

    if (isError) {
      return errorComponent
    }

    return children
  }

  return (
    <Stack spacing={5} sx={{ maxWidth: (t) => t.breakpoints.values.md }}>
      <Stepper
        activeStep={step}
        id="stepper-top"
        orientation={device?.isMobile ? 'vertical' : 'horizontal'}
      >
        {labels?.map((label, index) => (
          <Step key={label}>
            {index < step ? (
              <StepButton onClick={handleLabelClick(index)}>{label}</StepButton>
            ) : (
              <StepLabel>{label}</StepLabel>
            )}
          </Step>
        ))}
      </Stepper>
      <WizardContext.Provider value={contextValue}>
        <Content />
      </WizardContext.Provider>
    </Stack>
  )
}
