import React from 'react'
import { useUnpublishRecruitmentMutation } from '@local/src/Api/recruitmentApi'
import { useContent } from '@local/src/Utils/Content/useContent'
import { ConfirmationDialog } from '@local/src/Components/ConfirmationDialog/ConfirmationDialog'
import { useResolve } from '@local/src/Utils/Hooks/useResolve'
import { PublicationStatusListItem } from '@local/src/Pages/DetailsPage/PublicationDetails/PublicationStatusListItem'

interface PublicationSettingsProps {
  recruitmentId: string
  endDate?: Date
}

export const PublicationSettings: React.FC<PublicationSettingsProps> = ({
  recruitmentId,
  endDate,
}) => {
  const { dialogTitle, dialogMessage, dialogConfirmLabel, dialogCancelLabel } =
    useContent().detailsPage.publication

  const {
    isPending: isPendingConfirmation,
    promise: confirm,
    resolve: resolveConfirmation,
  } = useResolve<boolean>()

  const [unpublish] = useUnpublishRecruitmentMutation()

  const handleUnpublish = async () => {
    const isConfirmed = await confirm()
    if (isConfirmed) {
      void unpublish(recruitmentId)
    }
  }

  const isPublished = !endDate || new Date(endDate) > new Date()

  return (
    <>
      <PublicationStatusListItem
        isPublished={isPublished}
        endDate={endDate}
        onUnpublishClick={() => void handleUnpublish()}
      />

      <ConfirmationDialog
        isOpen={isPendingConfirmation}
        title={dialogTitle}
        message={dialogMessage}
        confirmLabel={dialogConfirmLabel}
        cancelLabel={dialogCancelLabel}
        onConfirm={() => resolveConfirmation(true)}
        onClose={() => resolveConfirmation(false)}
      />
    </>
  )
}
