import React, { useState } from 'react'
import AnnonseraCard from '@local/src/Pages/StartPage/AnnonseraCard/AnnonseraCard'
import { Grid2 as Grid, Stack } from '@mui/material'
import CreatedRecruitments from '@local/src/Pages/StartPage/CreatedRecruitments/CreatedRecruitments'
import OnboardingCard from '@local/src/Pages/StartPage/OnboardingCard/OnboardingCard'
import { hasClosedOnboardingCardCookie } from '@local/src/Utils/cookie'

const StartPage: React.FC = () => {
  const [isOnboardingCookieSet, setIsOnboardingCookieSet] = useState(
    hasClosedOnboardingCardCookie()
  )

  return (
    <Grid container columns={3}>
      <Grid size={{ xs: 3, md: 2 }}>
        <Stack spacing={3}>
          <OnboardingCard
            isOpen={!isOnboardingCookieSet}
            onClose={() => setIsOnboardingCookieSet(true)}
          />
          <AnnonseraCard showSecondaryButton={isOnboardingCookieSet} />
          <CreatedRecruitments />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default StartPage
