import React from 'react'
import { Chip, ListSubheader, Menu, MenuItem } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'

export interface ChipSelectOptionGroup {
  subtitle?: string
  options: {
    label: string
    onClick: () => void
  }[]
}

interface ChipSelectProps {
  label: string
  optionGroups: ChipSelectOptionGroup[]
}

export const ChipSelect: React.FC<ChipSelectProps> = ({
  label,
  optionGroups,
}) => {
  const [isActionMenuOpen, setIsActionMenuOpen] = React.useState<boolean>(false)
  const anchorEl = React.useRef<HTMLDivElement>()
  const handleActionMenuToggle = () => {
    setIsActionMenuOpen(!isActionMenuOpen)
  }
  const handleOptionClick = (onOptionClick: () => void) => {
    onOptionClick()
    setIsActionMenuOpen(false)
  }

  return (
    <>
      <Chip
        label={label}
        onClick={handleActionMenuToggle}
        ref={anchorEl}
        deleteIcon={<ArrowDropDown />}
        onDelete={handleActionMenuToggle}
      />
      <Menu
        open={isActionMenuOpen}
        onClose={handleActionMenuToggle}
        anchorEl={anchorEl.current}
      >
        {optionGroups?.map((item, key) => (
          <div key={key}>
            {item.subtitle && (
              <ListSubheader
                sx={{ fontSize: (t) => t.typography.body2.fontSize }}
              >
                {item.subtitle}
              </ListSubheader>
            )}
            {item.options.map((option) => (
              <MenuItem
                key={option.label}
                onClick={() => handleOptionClick(option.onClick)}
              >
                {option.label}
              </MenuItem>
            ))}
          </div>
        ))}
      </Menu>
    </>
  )
}
