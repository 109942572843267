import { Close } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material'
import React, { PropsWithChildren } from 'react'

interface ConfirmationDialogProps extends PropsWithChildren {
  isOpen: boolean
  title: string
  message?: string
  showCloseButton?: boolean
  confirmLabel?: string
  cancelLabel?: string
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  isOpen,
  title,
  message,
  showCloseButton,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onClose,
  children,
}) => (
  <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      {title}
      {showCloseButton && (
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      )}
    </DialogTitle>
    <DialogContent>
      {message && (
        <DialogContentText mb={children ? 2 : 0}>{message}</DialogContentText>
      )}
      {children}
    </DialogContent>

    {(cancelLabel || confirmLabel) && (
      <DialogActions>
        {cancelLabel && (
          <Button variant="text" onClick={onClose} color="primary">
            {cancelLabel}
          </Button>
        )}
        {confirmLabel && (
          <Button variant="text" onClick={onConfirm} color="primary">
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    )}
  </Dialog>
)
