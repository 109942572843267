import React from 'react'
import { useContent } from '@local/src/Utils/Content/useContent'
import { FormikHandleChange } from '@local/src/Utils/types'
import { TextField } from '@mui/material'
import { FormikErrors } from 'formik'
import { ContactInfoForm } from '@local/src/Pages/SettingsPage/EditContactInfoDialog/Form/contactInfoSchema'

interface ContactEmailFieldProps {
  value: string
  handleChange: FormikHandleChange
  errors: FormikErrors<ContactInfoForm>
}

export const ContactEmailField: React.FC<ContactEmailFieldProps> = ({
  value,
  handleChange,
  errors,
}) => {
  const { emailPlaceholder } = useContent().contactPopup

  return (
    <TextField
      fullWidth
      value={value}
      placeholder={emailPlaceholder}
      onChange={handleChange('email')}
      error={!!errors?.email}
      helperText={!!errors?.email && errors?.email}
      margin="dense"
    />
  )
}
