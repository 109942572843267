import React from 'react'
import { useContent } from '@local/src/Utils/Content/useContent'
import {
  AccessTimeFilled,
  Business,
  Place,
  Work,
  WorkHistory,
} from '@mui/icons-material'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { capitalizeFirstLetter } from '@local/src/Utils/stringHelpers'
import { EmploymentType, WorkingHours } from '@local/src/Api/types'

interface PreviewDetailsProps {
  jobTitle: string
  location: string
  companyName: string
  workingHours?: WorkingHours[]
  employmentType?: EmploymentType
}

export const PreviewDetails: React.FC<PreviewDetailsProps> = ({
  jobTitle,
  location,
  companyName,
  workingHours,
  employmentType,
}) => {
  const {
    jobTitleLabel,
    locationLabel,
    companyNameLabel,
    employmentTypeOptionEmployee,
    employmentTypeOptionHourly,
    employmentTypeOptionSeasonal,
    employmentTypeOptionOther,
    workingHoursOptionFullTime,
    workingHoursOptionPartTime,
    workingHoursLabel,
    employmentTypeLabel,
  } = useContent().preview

  const employmentTypeToLabelMapper = {
    [EmploymentType.Employee]: employmentTypeOptionEmployee,
    [EmploymentType.Hourly]: employmentTypeOptionHourly,
    [EmploymentType.Seasonal]: employmentTypeOptionSeasonal,
    [EmploymentType.Other]: employmentTypeOptionOther,
  }

  const workingHoursMapper = {
    [WorkingHours.FullTime]: workingHoursOptionFullTime,
    [WorkingHours.PartTime]: workingHoursOptionPartTime,
  }

  const items = [
    {
      Icon: Business,
      label: companyNameLabel,
      value: companyName,
    },
    {
      Icon: Work,
      label: jobTitleLabel,
      value: capitalizeFirstLetter(jobTitle),
    },
    {
      Icon: Place,
      label: locationLabel,
      value: location,
    },
    {
      Icon: AccessTimeFilled,
      label: workingHoursLabel,
      value: workingHours?.map((wh) => workingHoursMapper[wh]).join(', ') || '',
    },
    {
      Icon: WorkHistory,
      label: employmentTypeLabel,
      value: employmentTypeToLabelMapper[employmentType],
    },
  ].filter(({ value }) => !!value)

  return (
    <Table>
      <TableBody>
        {items.map(({ Icon, label, value }) => (
          <TableRow key={label}>
            <TableCell padding="checkbox">
              <Icon sx={{ fill: (t) => t.palette.text.secondary }} />
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="text.secondary">
                {label}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" aria-label={label}>
                {value}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
