import { Theme } from '@mui/material'

export const textEditorStyles = (theme: Theme) => ({
  h6: {
    ...theme.typography.h6,
    marginBlockStart: theme.spacing(2),
    marginBlockEnd: 0,
  },
  p: {
    margin: 'revert',
    ...theme.typography.body1,
  },
  'p:empty::after': {
    content: '" "',
    display: 'inline-block',
  },
  'li + li': {
    marginTop: theme.spacing(2),
  },
})
