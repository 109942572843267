import React, { useId } from 'react'
import { Avatar, Stack, Typography } from '@mui/material'

interface ApplicantDataProps {
  label: string
  value: string | number
}

export const ApplicantsIcon: React.FC<ApplicantDataProps> = ({
  label,
  value,
}) => {
  const id = useId()
  return (
    <Stack spacing={1} alignItems="center">
      <Avatar
        sx={{
          bgcolor: (t) => t.palette.surface?.blue,
          color: (t) => t.palette.text?.primary,
          fontSize: (t) => t.typography.subtitle1.fontSize,
        }}
        aria-labelledby={id}
      >
        {value}
      </Avatar>
      <Typography
        variant="body2"
        color="text.secondary"
        component="label"
        id={id}
      >
        {label}
      </Typography>
    </Stack>
  )
}
