import React from 'react'
import { useContent } from '@local/src/Utils/Content/useContent'
import { ActiveRecruitmentListItem } from '@local/src/Pages/StartPage/CreatedRecruitments/ActiveRecruitments/ActiveRecruitmentListItem/ActiveRecruitmentListItem'
import { RecruitmentsList } from '@local/src/Pages/StartPage/CreatedRecruitments/RecruitmentList/RecruitmentsList'
import { RecruitmentStatus } from '@local/src/Api/types'

export const ActiveRecruitments: React.FC = () => {
  const {
    skapadeAnnonser: { activeTabEmptyText },
  } = useContent()

  return (
    <RecruitmentsList
      status={RecruitmentStatus.Ongoing}
      emptyText={activeTabEmptyText}
      renderListItem={(recruitment) => (
        <ActiveRecruitmentListItem
          key={recruitment.id}
          recruitment={recruitment}
        />
      )}
    />
  )
}
