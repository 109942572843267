import React from 'react'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { ThemeProvider, createTheme } from '@mui/material'
import { Provider as StoreProvider } from 'react-redux'
import Store from '@local/src/Utils/store'
import { AppShellRouter } from '@local/src/Utils/Router/AppShellRouter'
import { KeyRoute } from '@local/src/Utils/Router/KeyRoute'
import StartPage from '@local/src/Pages/StartPage/StartPage'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { withFallbackContent } from '@local/src/Utils/Content/fallbackContent'
import { Content } from '@local/src/Utils/Content/Content.types'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { svSE } from '@mui/x-date-pickers/locales'
import { sv } from 'date-fns/locale'
import { Redirect, Route, Switch } from 'react-router-dom'
import { GTMTracker } from '@trr/gtm-tracking'
import DraftPage from '@local/src/Pages/DraftPage/DraftPage'
import { DetailsPage } from '@local/src/Pages/DetailsPage/DetailsPage'
import { SettingsPage } from '@local/src/Pages/SettingsPage/SettingsPage'
import { getAppShellDataBaseUrl } from '@local/src/Utils/urlHelpers'
import { SnackbarProvider } from '@local/src/Components/InfoSnackbar/SnackbarProvider'
import { customThemeOptions } from '@local/src/themeOptions'
import { deepmerge } from '@mui/utils'

interface AppShellConfiguration {
  currentKey: string
  appShellData: AppShellDataProps
  epiPagePath: string
}

const App: React.FunctionComponent<AppShellConfiguration> = ({
  currentKey,
  appShellData,
}) => {
  const theme = createTheme(
    deepmerge(themeOptions, customThemeOptions),
    defaultLocale
  )

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        adapterLocale={sv}
        dateAdapter={AdapterDateFns}
        localeText={
          svSE.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <GTMTracker mfName="trr-matchning-kundforetag">
          <StoreProvider store={Store}>
            <SnackbarProvider>
              <AppShellDataProvider
                value={{
                  ...appShellData,
                  content: withFallbackContent(appShellData.content as Content),
                }}
              >
                <AppShellRouter currentKey={currentKey}>
                  <KeyRoute urlKey="start">
                    <StartPage />
                  </KeyRoute>
                  <KeyRoute urlKey="annonsera">
                    <Switch>
                      <Route path={`${appShellData.epiPagePath}:recruitmentId`}>
                        <DraftPage />
                      </Route>
                      <Redirect to={getAppShellDataBaseUrl(appShellData)} />
                    </Switch>
                  </KeyRoute>
                  <KeyRoute urlKey="annons">
                    <Switch>
                      <Route
                        path={`${appShellData.epiPagePath}:recruitmentId/hantera`}
                      >
                        <SettingsPage />
                      </Route>
                      <Route path={`${appShellData.epiPagePath}:recruitmentId`}>
                        <DetailsPage />
                      </Route>
                      <Redirect to={getAppShellDataBaseUrl(appShellData)} />
                    </Switch>
                  </KeyRoute>
                </AppShellRouter>
              </AppShellDataProvider>
            </SnackbarProvider>
          </StoreProvider>
        </GTMTracker>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
