import React, { useId } from 'react'
import { CheckRounded } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  SxProps,
} from '@mui/material'

interface ActionButton {
  label: string
  onClick: () => void
}

interface StatusListItemProps {
  primaryText: string
  secondaryText: string
  isChecked: boolean
  actionButton?: ActionButton
}

export const StatusListItem: React.FC<StatusListItemProps> = ({
  primaryText,
  secondaryText,
  isChecked,
  actionButton,
}) => {
  const primaryTextId = useId()

  const checkedAvatarSx: SxProps = {
    bgcolor: 'info.main',
    color: 'common.white',
  }

  const uncheckedAvatarSx: SxProps = {
    bgcolor: 'surface.grey',
    color: 'grey.700',
  }

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      justifyContent="space-between"
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      spacing={{ xs: 1, sm: 2 }}
    >
      <ListItem>
        <ListItemAvatar>
          <Avatar
            sx={isChecked ? checkedAvatarSx : uncheckedAvatarSx}
            aria-checked={isChecked}
            aria-labelledby={primaryTextId}
            role="checkbox"
          >
            <CheckRounded />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={primaryText}
          secondary={secondaryText}
          primaryTypographyProps={{
            id: primaryTextId,
          }}
        />
      </ListItem>
      {actionButton && (
        <Box sx={{ flexShrink: 0, pl: { xs: 7, sm: 0 } }}>
          <Button
            size="small"
            variant="outlined"
            onClick={actionButton.onClick}
          >
            {actionButton.label}
          </Button>
        </Box>
      )}
    </Stack>
  )
}
