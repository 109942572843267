import React from 'react'
import { StatusListItem } from '@local/src/Components/StatusListItem/StatusListItem'
import { date } from '@trr/frontend-datetime'
import { useContent } from '@local/src/Utils/Content/useContent'

interface ClosureDetailsProps {
  closureDate?: Date
  isClosed: boolean
  onCloseClick?: () => void
}

export const ClosureDetails: React.FC<ClosureDetailsProps> = ({
  closureDate,
  isClosed,
  onCloseClick,
}) => {
  const {
    closeStatusTitle,
    closeStatusMessage,
    closedStatusTitle,
    closeActionButtonLabel,
  } = useContent().detailsPage.closure

  const closedDateText = closureDate
    ? date.format(closureDate, { year: true })
    : ''

  const primaryText = isClosed ? closedStatusTitle : closeStatusTitle
  const secondaryText = isClosed ? closedDateText : closeStatusMessage

  return (
    <StatusListItem
      primaryText={primaryText}
      secondaryText={secondaryText}
      isChecked={isClosed}
      actionButton={
        !isClosed && onCloseClick
          ? {
              label: closeActionButtonLabel,
              onClick: onCloseClick,
            }
          : undefined
      }
    />
  )
}
