import { textEditorStyles } from '@local/src/Components/TextEditor/styles'
import { Box, styled } from '@mui/material'
import React from 'react'
import parse from 'html-react-parser'

interface HtmlPreviewProps {
  html: string
}

const StyledBox = styled(Box)(({ theme }) => ({
  ...textEditorStyles(theme),
}))

const HtmlPreview: React.FC<HtmlPreviewProps> = ({ html }) => (
  <StyledBox>{parse(html)}</StyledBox>
)

export default HtmlPreview
