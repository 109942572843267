import React, { useState } from 'react'
import {
  ApplicationStatus,
  RecruitmentApplication,
  RecruitmentApplicationsOrder,
} from '@local/src/Api/types'
import { useGetRecruitmentApplicationsQuery } from '@local/src/Api/applicationsApi'
import { ApplicantCardProps } from '@local/src/Components/ApplicantCard/ApplicantCard'
import {
  Grid2 as Grid,
  Grid2Props as GridProps,
  Stack,
  Typography,
} from '@mui/material'
import { ApplicantsColumnHeader } from '@local/src/Pages/DetailsPage/Applicants/ActiveApplicants/ApplicantsColumnHeader/ApplicantsColumnHeader'
import { useContent } from '@local/src/Utils/Content/useContent'
import { OrderSelect } from '@local/src/Components/OrderSelect/OrderSelect'
import { PartialApplicantsList } from '@local/src/Pages/DetailsPage/Applicants/PartialApplicantsList'

type MapApplicationToApplicantCardProps = (
  application: RecruitmentApplication
) => ApplicantCardProps

interface ApplicantsListProps {
  title?: string
  subtitle?: string
  recruitmentId: string
  statuses?: ApplicationStatus[]
  mapApplicationToCard: MapApplicationToApplicantCardProps
  orderOptions?: RecruitmentApplicationsOrder[]
  emptyStateText?: string
  columns?: number
}

export const ApplicantsList: React.FC<ApplicantsListProps> = ({
  recruitmentId,
  statuses,
  mapApplicationToCard,
  orderOptions,
  columns = 1,
  title,
  subtitle,
  emptyStateText,
}) => {
  const [orderBy, setOrderBy] = useState<RecruitmentApplicationsOrder>(
    orderOptions?.[0]
  )

  const content = useContent().detailsPage.applicants

  const { data: applicationsResponse } = useGetRecruitmentApplicationsQuery({
    recruitmentId,
    orderBy,
    statuses,
    page: 1,
    pageSize: 12,
  })

  const optionsWithLabels = {
    [RecruitmentApplicationsOrder.BestMatch]: content.sortOptionBestMatchLabel,
    [RecruitmentApplicationsOrder.LastModified]:
      content.sortOptionLastUpdatedLabel,
    [RecruitmentApplicationsOrder.CreatedWhen]:
      content.sortOptionApplicationDateLabel,
  }

  const handleOrderChange = (order: RecruitmentApplicationsOrder) => {
    setOrderBy(order)
  }

  const gridContainerProps: GridProps = {
    container: true,
    columns,
    columnSpacing: 3,
    rowSpacing: 3,
  }

  const renderListContent = () => {
    if (applicationsResponse?.applications.length === 0) {
      return (
        <Grid size={columns}>
          <Typography>{emptyStateText}</Typography>
        </Grid>
      )
    }

    const pages = applicationsResponse?.totalPages ?? 1

    return Array.from({ length: pages }).map((_, index) => (
      <Grid size={columns} key={index}>
        <PartialApplicantsList
          recruitmentId={recruitmentId}
          page={index + 1}
          pageSize={12}
          statuses={statuses}
          orderBy={orderBy}
          mapApplicationToCard={mapApplicationToCard}
          containerProps={gridContainerProps}
        />
      </Grid>
    ))
  }

  return (
    <Grid container {...gridContainerProps}>
      {title && (
        <Grid size={columns}>
          <Stack direction="row" spacing={3}>
            <ApplicantsColumnHeader title={title} subtitle={subtitle} />
            <OrderSelect
              orderOptions={orderOptions?.map((option) => ({
                value: option,
                label: optionsWithLabels[option],
              }))}
              label={content.sortLabel}
              orderValue={orderBy}
              onOrderChange={handleOrderChange}
            />
          </Stack>
        </Grid>
      )}
      {renderListContent()}
    </Grid>
  )
}
