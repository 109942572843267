import React, { PropsWithChildren } from 'react'
import {
  ListItemText,
  SxProps,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'

interface RecruitmentListItemProps extends PropsWithChildren {
  primaryText: React.ReactNode
  secondaryText?: string
  tertiaryText?: string
  to?: string
}

const typographySx: SxProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

export const RecruitmentListItem: React.FC<RecruitmentListItemProps> = ({
  primaryText,
  secondaryText,
  tertiaryText,
  to,
  children,
}) => (
  <TableRow
    sx={{
      display: 'flex',
      cursor: to ? 'pointer' : 'default',
      textDecoration: 'none',
    }}
    hover={!!to}
    component={to ? Link : 'div'}
    to={to}
  >
    <TableCell
      component="div"
      sx={{
        overflow: 'hidden',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <ListItemText
        sx={{ my: 0, flexGrow: 0 }}
        primary={primaryText}
        secondary={secondaryText}
        primaryTypographyProps={{
          sx: typographySx,
        }}
        secondaryTypographyProps={{
          sx: typographySx,
        }}
      />
      {tertiaryText && (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mt: 1, ...typographySx }}
        >
          {tertiaryText}
        </Typography>
      )}
    </TableCell>
    {children}
  </TableRow>
)
