import React from 'react'
import {
  ApplicationStatus,
  RecruitmentResponse,
  RecruitmentStatus,
} from '@local/src/Api/types'
import { Drawer } from '@local/src/Components/Drawer/Drawer'
import { useGetApplicationQuery } from '@local/src/Api/applicationsApi'
import { AppliedCandidate } from '@local/src/Pages/DetailsPage/CandidateDrawer/Pages/AppliedCandidate/AppliedCandidate'
import { OngoingCandidate } from '@local/src/Pages/DetailsPage/CandidateDrawer/Pages/OngoingCandidate/OngoingCandidate'
import { HiredCandidate } from '@local/src/Pages/DetailsPage/CandidateDrawer/Pages/HiredCandidate/HiredCandidate'
import { RejectedCandidate } from '@local/src/Pages/DetailsPage/CandidateDrawer/Pages/RejectedCandidate/RejectedCandidate'
import { FavoriteApplication } from '@local/src/Pages/DetailsPage/Components/FavoriteApplication'
import { useContent } from '@local/src/Utils/Content/useContent'

interface CandidateDrawerProps {
  isOpen: boolean
  onClose: () => void
  applicationId: string
  recruitment: RecruitmentResponse
}

export const CandidateDrawer: React.FC<CandidateDrawerProps> = ({
  isOpen,
  onClose,
  applicationId,
  recruitment,
}) => {
  const {
    data: application,
    isLoading,
    isFetching,
    isError,
  } = useGetApplicationQuery(
    { applicationId },
    {
      skip: !applicationId,
    }
  )

  const { label: favoriteLabel } = useContent().manageCandidate.actionFavorite

  const isRejected = application?.status === ApplicationStatus.Rejected
  const isApplied = application?.status === ApplicationStatus.Applied
  const isHired = application?.status === ApplicationStatus.Hired
  const isOngoing = [
    ApplicationStatus.InProgress,
    ApplicationStatus.InterviewBooked,
    ApplicationStatus.OfferPresented,
  ].find((status) => status === application?.status)

  const isFetchingNewApplication =
    isFetching && applicationId !== application?.id

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading || isFetchingNewApplication}
      isError={isError}
      title={application?.person?.name}
      subtitle={
        <FavoriteApplication
          applicationId={applicationId}
          isFavorite={application?.isFavourite}
          label={favoriteLabel}
          checkboxSx={{ ml: -1.5 }}
        />
      }
    >
      {recruitment.status === RecruitmentStatus.Ongoing ? (
        <>
          {isApplied && (
            <AppliedCandidate
              application={application}
              recruitment={recruitment}
              onClose={onClose}
            />
          )}
          {isOngoing && (
            <OngoingCandidate
              application={application}
              recruitment={recruitment}
              onClose={onClose}
            />
          )}
          {isHired && (
            <HiredCandidate
              application={application}
              recruitment={recruitment}
              onClose={onClose}
            />
          )}
          {isRejected && (
            <RejectedCandidate
              application={application}
              recruitment={recruitment}
            />
          )}
        </>
      ) : (
        <RejectedCandidate
          application={application}
          recruitment={recruitment}
        />
      )}
    </Drawer>
  )
}
