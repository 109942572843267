import React from 'react'
import { useContent } from '@local/src/Utils/Content/useContent'
import { TextField } from '@mui/material'
import { FormikHandleChange } from '@local/src/Utils/types'
import { FormikErrors } from 'formik'
import { ContactInfoForm } from '@local/src/Pages/SettingsPage/EditContactInfoDialog/Form/contactInfoSchema'

interface ContactNameFieldProps {
  value: string
  handleChange: FormikHandleChange
  errors: FormikErrors<ContactInfoForm>
}

export const ContactNameField: React.FC<ContactNameFieldProps> = ({
  value,
  handleChange,
  errors,
}) => {
  const { namePlaceholder } = useContent().contactPopup

  return (
    <TextField
      fullWidth
      value={value}
      placeholder={namePlaceholder}
      onChange={handleChange('name')}
      error={!!errors?.name}
      helperText={!!errors?.name && errors?.name}
      margin="dense"
    />
  )
}
