import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'
import { Close } from '@mui/icons-material'
import { useContent } from '@local/src/Utils/Content/useContent'
import { ApplicationStatus } from '@local/src/Api/types'
import { useSetStatusMutation } from '@local/src/Api/applicationsApi'
import { useSnackbar } from '@local/src/Components/InfoSnackbar/Hooks/useSnackbar'

interface RejectApplicantDialogProps {
  applicationId: string
  isOpen: boolean
  applicantName: string
  onConfirm: () => void
  onCancel: () => void
}

export const RejectApplicantDialog: React.FC<RejectApplicantDialogProps> = ({
  applicationId,
  applicantName,
  isOpen,
  onConfirm,
  onCancel,
}) => {
  const {
    dialogReject: { title, description, confirmButton, cancelButton },
    actionMoveToHired: { rejectedSnackbarMessage },
  } = useContent().manageCandidate

  const { showSnackbar } = useSnackbar()

  const [setStatus] = useSetStatusMutation()

  const handleConfirmClick = async () => {
    await setStatus({
      applicationId: applicationId,
      status: ApplicationStatus.Rejected,
    })
      .unwrap()
      .then(() => {
        showSnackbar(
          replaceEpiVariables(rejectedSnackbarMessage, {
            name: applicantName ?? 'Användaren',
          }),
          6000
        )
        onConfirm()
      })
      .catch((error) => console.error(error))
  }

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {replaceEpiVariables(title, {
          name: applicantName,
        })}
        <IconButton onClick={onCancel}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          {cancelButton}
        </Button>
        <Button variant="text" onClick={() => void handleConfirmClick()}>
          {confirmButton}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
