import React from 'react'
import {
  Box,
  CircularProgress,
  Divider,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material'
import { useContent } from '@local/src/Utils/Content/useContent'
import { WizardActions } from '@local/src/Components/Wizard/WizardActions'
import { usePublishRecruitmentMutation } from '@local/src/Api/recruitmentApi'
import { RecruitmentPreview } from '@local/src/Components/RecruitmentPreview/RecruitmentPreview'
import { RecruitmentResponse } from '@local/src/Api/types'
import { PublishedDialogIllustration } from '@local/src/Pages/DraftPage/PublishView/PublishedDialogIllustration'
import { useDevice } from '@trr/app-shell-data'
import { useResolve } from '@local/src/Utils/Hooks/useResolve'
import { ConfirmationDialog } from '@local/src/Components/ConfirmationDialog/ConfirmationDialog'
import { ContactInfoPreview } from '@local/src/Pages/DraftPage/PublishView/ContactInfo/ContactInfoPreview'
import { useHotjar } from '@local/src/Utils/Hooks/useHotjar'
import { pushPathWithoutBlock } from '@local/src/Utils/Hooks/usePageLeave'
import { useHistory } from 'react-router-dom'

interface PublishViewProps {
  recruitment: RecruitmentResponse
}

const PublishView: React.FC<PublishViewProps> = ({ recruitment }) => {
  const {
    publish: {
      publishButtonLabel,
      backButtonLabel,
      confirmDialogTitle,
      confirmDialogMessage,
      confirmDialogConfirmLabel,
      confirmDialogCancelLabel,
      publishedDialogTitle,
      publishedDialogMessage,
      onPublishUrl,
    },
    preview: { subtitle },
  } = useContent()

  const history = useHistory()

  const {sendRecruitmentPublishedEvent} = useHotjar()

  const [
    publishRecruitment,
    { isError: isPublishError, isLoading: isPublishLoading, status },
  ] = usePublishRecruitmentMutation()

  const {
    isPending: isPendingConfirmation,
    resolve: resolveConfirmation,
    promise: confirm,
  } = useResolve<boolean>()

  const {
    isPending: isPendingPublishedDialog,
    resolve: resolvePublishedDialog,
    promise: openPublishedDialog,
  } = useResolve<boolean>()

  const { isDesktop, isXL } = useDevice()
  const isDesktopOrXL = isDesktop || isXL

  const handlePublishClicked = async () => {
    const confirmed = await confirm()
    if (!confirmed) return

    try {
      await publishRecruitment(recruitment.id).unwrap()
      await openPublishedDialog()
      sendRecruitmentPublishedEvent()
      pushPathWithoutBlock(history, onPublishUrl)
    } catch (rejected) {
      console.error(rejected)
    }
  }

  if (isPublishLoading) {
    return <CircularProgress sx={{ m: 1, alignSelf: 'center' }} />
  }

  return (
    <>
      <Box>
        <Stack spacing={3} mb={8}>
          <Box>
            <Typography variant="subtitle2" color="text.secondary" mb={1}>
              {subtitle}
            </Typography>
            <Divider />
          </Box>
          <RecruitmentPreview recruitment={recruitment} />
          <Divider />
          <ContactInfoPreview contacts={recruitment.contacts} />
          {isPublishError && (
            <FormHelperText error>
              {`Någonting gick fel! Rekryteringsbehovet kunde inte skapas. Försök igen. (${status})`}
            </FormHelperText>
          )}
        </Stack>
        <WizardActions
          primaryButtonText={publishButtonLabel}
          secondaryButtonText={backButtonLabel}
          onPrimaryButtonClicked={() => void handlePublishClicked()}
          onSecondaryButtonClicked={({ prevStep }) => {
            prevStep()
          }}
        />
      </Box>
      <ConfirmationDialog
        title={confirmDialogTitle}
        message={confirmDialogMessage}
        confirmLabel={confirmDialogConfirmLabel}
        cancelLabel={confirmDialogCancelLabel}
        isOpen={isPendingConfirmation}
        onConfirm={() => resolveConfirmation(true)}
        onClose={() => resolveConfirmation(false)}
      />

      <ConfirmationDialog
        title={publishedDialogTitle}
        showCloseButton
        isOpen={isPendingPublishedDialog}
        onClose={() => resolvePublishedDialog(false)}
        onConfirm={() => resolvePublishedDialog(false)}
      >
        <Stack spacing={4} direction="row">
          <Typography variant="body1" color="text.secondary">
            {publishedDialogMessage}
          </Typography>
          {isDesktopOrXL && (
            <Box>
              <PublishedDialogIllustration />
            </Box>
          )}
        </Stack>
      </ConfirmationDialog>
    </>
  )
}

export default PublishView
