import React from 'react'

export const PublishedDialogIllustration = () => {
  const width = 120
  const height = 100

  return (
    <svg
      preserveAspectRatio="xMidYMin slice"
      height={height}
      width={width}
      role="img"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M97.8559 71.4988C97.8559 71.897 95.203 72.6366 90.3996 72.6366C85.0057 72.6366 84.5067 72.8299 77.6283 72.8867C71.3908 72.9381 64.6563 72.9665 57.6319 72.9665C51.262 72.9665 42.6448 72.7995 36.9053 72.7564C29.4396 72.7007 25.1205 72.8383 19.3253 72.6408C12.4973 72.4076 11.0391 71.8991 11.0391 71.4988C11.0391 71.0985 13.1613 70.6488 18.7044 70.6488C29.0383 70.6488 42.6784 70.3105 57.6329 70.3105C70.4515 70.3105 76.5314 70.5637 86.1646 70.5637C95.7977 70.5637 97.8569 71.0565 97.8569 71.4988H97.8559Z"
        fill="#F2EDF8"
      />
      <path
        d="M18.961 55.6016C17.6792 60.7506 15.7797 68.8225 15.2334 71.3724H17.01C18.0343 68.8225 21.1095 60.7506 23.0006 55.6016H18.961Z"
        fill="#96A0B5"
      />
      <path
        d="M23.0013 55.6016H18.9617C18.5761 57.1512 18.1338 58.9667 17.6904 60.8158H21.0598C21.7553 58.9667 22.4319 57.1512 23.0013 55.6016Z"
        fill="#737F8B"
      />
      <path
        d="M87.0543 55.6016C88.336 60.7506 90.2355 68.8225 90.7819 71.3724H89.0053C87.9809 68.8225 84.9058 60.7506 83.0146 55.6016H87.0543Z"
        fill="#96A0B5"
      />
      <path
        d="M83.0156 55.6016H87.0552C87.4408 57.1512 87.8831 58.9667 88.3265 60.8158H84.9572C84.2617 58.9667 83.5851 57.1512 83.0156 55.6016Z"
        fill="#737F8B"
      />
      <path
        d="M95.7696 34.7788C91.9464 23.1032 85.4525 20.3086 80.3192 20.3086H24.959C19.8257 20.3086 13.3318 23.1043 9.50861 34.7788C8.43068 38.0703 7.16048 42.5386 6.51855 47.59H98.7586C98.1177 42.5386 96.8465 38.0703 95.7686 34.7788H95.7696Z"
        fill="#FFC700"
      />
      <path
        d="M24.1372 23.1904H81.1427C85.03 23.1904 89.6653 24.5121 93.437 29.2441C89.6243 22.1797 84.5173 20.3086 80.319 20.3086H24.9598C20.7615 20.3086 15.6545 22.1787 11.8418 29.2441C15.6135 24.5121 20.2499 23.1904 24.1361 23.1904H24.1372Z"
        fill="#DCB145"
      />
      <path
        d="M95.7697 34.7799C95.2034 33.0506 94.5783 31.5177 93.908 30.1582C90.7267 33.7471 88.6097 39.0338 86.9119 42.2004C86.1093 43.6986 83.8494 45.3712 80.3246 45.3712H24.9528C21.428 45.3712 19.1681 43.6986 18.3654 42.2004C16.6686 39.0328 14.5506 33.7471 11.3693 30.1582C10.699 31.5177 10.0739 33.0506 9.50764 34.7799C8.4297 38.0715 7.15951 42.5397 6.51758 47.5911H98.7576C98.1168 42.5397 96.8455 38.0715 95.7676 34.7799H95.7697Z"
        fill="#B17C29"
      />
      <path
        d="M101.044 28.5723C93.1141 28.5723 91.7252 38.7643 88.8938 43.9197C88.068 45.4231 85.744 47.102 82.1194 47.102H23.8805C20.2559 47.102 17.9319 45.4231 17.1061 43.9197C14.2747 38.7643 12.8858 28.5723 4.9557 28.5723C0.612442 28.5723 -0.707134 32.1381 0.341383 35.0042C2.23355 40.1763 4.04691 45.0712 5.68903 48.8387C9.46285 57.4937 12.6242 59.0297 18.8575 59.0297H87.1424C93.3757 59.0297 96.537 57.4947 100.311 48.8387C101.953 45.0712 103.766 40.1763 105.659 35.0042C106.707 32.1381 105.388 28.5723 101.044 28.5723Z"
        fill="#FFC700"
      />
      <path
        d="M15.969 45.5271C16.8284 47.0306 19.2469 48.7095 23.0186 48.7095H82.9806C86.7533 48.7095 89.1708 47.0306 90.0302 45.5271C92.9761 40.3718 94.4228 30.1797 102.674 30.1797C103.754 30.1797 104.654 30.3835 105.384 30.7323C104.645 29.4726 103.227 28.5723 101.044 28.5723C93.1138 28.5723 91.7249 38.7643 88.8934 43.9197C88.0676 45.4231 85.7437 47.102 82.119 47.102H23.8801C20.2555 47.102 17.9315 45.4231 17.1057 43.9197C14.2743 38.7643 12.8854 28.5723 4.95534 28.5723C2.77215 28.5723 1.35382 29.4726 0.615234 30.7323C1.34541 30.3835 2.24474 30.1797 3.32478 30.1797C11.5774 30.1797 13.023 40.3718 15.969 45.5271Z"
        fill="#E9C352"
      />
      <path
        d="M60.9277 23.6383C60.7197 23.5133 60.0547 23.2034 59.5987 23.2097C59.3466 23.3441 59.0724 23.5332 58.8339 23.6583C58.0238 24.0838 57.9471 23.6204 58.3705 23.2254C59.3592 22.3019 59.957 22.3881 60.9677 22.6917C61.0496 23.0342 61.0591 23.2906 60.9277 23.6383Z"
        fill="#E2A367"
      />
      <path
        d="M61.2127 24.145C61.0162 24.0462 60.2062 23.6071 59.7943 23.6438C59.5758 23.7836 59.3404 23.9716 59.1335 24.1019C58.4306 24.5421 58.3298 24.1282 58.6849 23.7426C59.5149 22.8401 60.2587 22.8979 61.1937 23.1028C61.2915 23.4074 61.3072 23.8224 61.2127 24.146V24.145Z"
        fill="#E2A367"
      />
      <path
        d="M61.1689 24.5281C60.984 24.4378 60.5165 24.1173 60.1351 24.0396C59.9323 24.172 59.7159 24.3506 59.5236 24.4745C58.8722 24.8927 58.7735 24.5082 59.1013 24.1436C59.8661 23.2915 60.3767 23.321 61.2508 23.5017C61.3454 23.7843 61.254 24.2255 61.1699 24.5281H61.1689Z"
        fill="#E2A367"
      />
      <path
        d="M63.6598 24.6484C62.9349 23.9865 61.8475 22.4536 61.5407 22.1647C61.3348 21.9682 59.8124 21.6121 59.4836 21.6856C59.1022 21.7697 58.4803 22.4358 58.2449 22.7362C57.9213 23.147 58.4592 23.23 58.7408 23.0872C59.1358 22.8875 59.2052 22.6753 59.6853 22.5041C60.2369 22.6701 60.6823 22.8623 60.7328 22.8949C60.9208 23.1281 61.0553 23.7049 61.0501 23.8898C61.0448 24.0884 61.4325 25.1127 62.397 25.8324C63.3625 26.5531 63.9246 27.0133 64.556 27.588C65.1464 27.0249 65.5383 26.2201 65.5215 25.5078C65.0351 25.2588 63.9214 24.8154 63.6598 24.6473V24.6484Z"
        fill="#F1BE8F"
      />
      <path
        d="M64.3441 21.1387L59.0448 22.9048C58.9145 22.9499 57.907 23.3586 57.3313 23.6276C57.2262 23.6843 57.1674 23.7516 57.1443 23.8167C57.0886 23.8409 57.035 23.865 56.9888 23.8871C56.8522 23.9606 56.911 24.0836 57.0476 24.0562C57.097 24.0457 57.1527 24.0331 57.2104 24.0195C57.2714 24.0625 57.3659 24.0825 57.4826 24.0604C58.1066 23.9302 59.1457 23.6402 59.2749 23.5971L64.5742 21.8247C64.6803 21.5999 64.5574 21.2322 64.3441 21.1397V21.1387Z"
        fill="#30303A"
      />
      <path
        d="M58.7032 23.191C58.2179 23.4064 59.0384 23.9916 59.4965 24.5831C59.9262 25.1578 60.8297 25.2954 62.3972 25.8333C63.0434 25.1683 62.3321 23.6428 61.0503 23.8908C60.7814 23.9433 60.3412 24.0904 60.0732 23.8141C59.6446 23.3728 59.1939 22.9746 58.7022 23.1921L58.7032 23.191Z"
        fill="#F1BE8F"
      />
      <path
        d="M71.5728 27.4223C70.3068 26.8822 68.4241 26.5061 65.5202 25.5091C65.3805 26.2004 65.0474 26.9285 64.5547 27.5893C65.4624 28.3027 70.2154 32.4463 73.1256 32.3455C73.6415 32.3276 74.4956 31.9767 74.8917 31.4188C75.3477 30.7779 77.8198 20.6405 77.8198 20.6405L75.2752 16.3887C73.5932 17.0842 72.9397 18.7967 72.7033 20.8107C72.3839 23.5339 71.8145 26.0985 71.5718 27.4212L71.5728 27.4223Z"
        fill="#D4C1EE"
      />
      <path
        d="M61.199 44.0177C58.5357 49.5451 54.3711 59.9924 52.3434 63.7515C52.0292 64.3346 50.0551 66.5745 47.8047 68.6947C47.8047 68.6947 48.2239 69.6035 50.3913 69.3933C51.1961 69.3156 55.9596 67.4696 55.8146 64.6015C56.8043 62.0274 58.6387 60.3139 60.5413 58.219C62.1908 56.4035 63.8897 54.3002 65.1462 51.1063C65.4939 50.2217 65.89 48.8821 68.0953 48.3032C68.9599 48.0763 69.6922 48.0017 70.6304 48.0017C72.0151 46.7063 75.4422 39.0105 69.335 40.3616C68.0396 40.7188 64.1523 41.7915 63.6186 41.927C62.1803 42.2916 61.6224 43.1384 61.199 44.0188V44.0177Z"
        fill="#DB9256"
      />
      <path
        d="M69.3358 40.3625C68.0404 40.7197 64.1531 41.7924 63.6193 41.9279C63.1539 42.0456 62.781 42.2147 62.4752 42.4186C60.6009 47.4804 60.5894 52.7104 60.6125 58.0812C62.6423 56.0514 63.9167 54.237 65.148 51.1082C65.4958 50.2236 65.8918 48.8841 68.0971 48.3052C68.9617 48.0782 69.694 48.0036 70.6322 48.0036C72.0169 46.7082 75.4441 39.0125 69.3368 40.3636L69.3358 40.3625Z"
        fill="#D1864C"
      />
      <path
        d="M55.8115 64.584C53.247 66.182 52.7332 67.8062 50.8011 68.7497C50.3157 68.9871 48.0338 68.9377 47.8268 68.6646C47.8268 68.6646 45.4713 69.1752 45.5365 70.1333C45.589 70.9024 50.6551 71.6462 51.442 70.5483C51.8854 69.9295 55.4049 68.0994 56.8516 67.4459C57.1216 66.0527 56.3799 65.021 55.8115 64.584Z"
        fill="#2F3445"
      />
      <path
        d="M56.8527 67.4453C54.6391 68.0641 52.6639 69.3364 50.9808 70.3251C49.6581 71.1571 46.9055 70.8073 45.609 69.8134C45.5575 69.9143 45.5302 70.0204 45.5386 70.1328C45.5911 70.9018 48.7062 71.7928 51.4231 70.8367C52.1239 70.5898 54.3428 70.6833 55.4207 70.5194C55.863 70.4522 56.3127 70.2977 56.4682 70.0834C56.4682 70.0834 56.8328 67.5682 56.8527 67.4453Z"
        fill="#091019"
      />
      <path
        d="M62.4747 42.418C60.6004 47.4798 60.5195 52.8496 60.5416 58.2203C62.5714 56.1905 63.9162 54.2364 65.1465 51.1076"
        fill="#D1864C"
      />
      <path
        d="M63.3663 67.6968C62.8105 67.9983 59.6397 67.2839 59.251 67.3753C57.9419 69.3946 58.1773 71.872 60.2438 72.0821C61.9973 72.2607 63.5953 70.9958 63.3663 67.6958V67.6968Z"
        fill="#091019"
      />
      <path
        d="M63.3658 67.6968C62.81 67.9983 59.6393 67.2839 59.2505 67.3753C58.6401 68.5562 58.367 70.8067 60.3884 71.2826C61.606 71.5694 63.2544 70.3738 63.3658 67.6968Z"
        fill="#2F3445"
      />
      <path
        d="M69.0496 39.1395C68.2217 37.4438 66.2812 37.4312 65.384 38.5059C61.3402 43.3514 62.1596 56.5913 61.2435 61.9757C60.942 63.7439 59.9555 65.3766 59.2505 67.3748C58.6054 69.2019 63.2019 69.184 63.3658 67.6963C63.5811 65.7453 63.7398 63.3625 63.868 62.666C64.3439 60.0741 66.6784 54.5258 69.0864 47.4404C70.22 44.1047 69.7157 40.5063 69.0486 39.1405L69.0496 39.1395Z"
        fill="#F9B874"
      />
      <path
        d="M73.7179 12.6371C74.1234 12.3471 74.5436 12.1737 74.6813 12.1286C74.8136 12.0844 74.8746 12.2053 74.8693 12.2862C74.8567 12.5089 74.7569 12.7211 74.3839 12.884C74.2158 12.9575 74.0204 12.9953 73.9364 12.9985C73.8586 13.0016 73.7798 12.8745 73.7578 12.8083C73.7262 12.7148 73.7168 12.6528 73.7179 12.6381V12.6371Z"
        fill="white"
      />
      <path
        d="M74.4485 12.6325C74.25 12.6997 73.9779 12.8048 73.7572 12.8048C73.7352 12.7386 73.7131 12.6724 73.7173 12.6346C74.1229 12.3446 74.8625 12.146 75.0001 12.1008C75.1325 12.0567 75.1735 12.0546 75.1903 12.1334C75.246 12.3814 74.7574 12.7144 74.3834 12.8815C74.4359 12.8258 74.4706 12.6986 74.4485 12.6325Z"
        fill="#E4E8F1"
      />
      <path
        d="M80.9835 11.906C80.5927 9.55049 82.7601 5.57285 79.5315 3.38336C78.649 2.78451 73.959 3.36235 72.8643 5.20723C72.1888 6.344 72.4125 7.72347 72.5943 8.3738C72.7214 8.82767 72.9252 9.12919 72.9389 9.35823C72.9788 10.0411 72.4766 10.64 72.4094 10.7818C72.2949 11.0245 72.3632 11.2136 72.4262 11.2935C72.5691 11.4731 73.024 11.4469 73.0713 11.4479L73.1889 11.5813C73.4421 11.8597 73.335 11.9953 73.3318 12.1655C73.3696 12.5836 74.6104 12.363 74.6514 12.487C74.6934 12.7444 73.9349 13.1058 73.8319 13.2098C73.6113 13.4315 73.9181 13.5996 74.0105 13.6122C74.5978 13.4956 74.6157 14.2646 74.9225 14.5893C75.1662 14.8477 75.9195 15.019 77.1739 14.4779C77.5648 14.3098 77.5879 15.1419 77.3084 15.6315C75.3427 17.3587 73.5976 21.8207 75.1599 22.3775C77.8411 22.0455 82.6224 15.352 82.6224 15.352C81.9931 15.0725 81.1957 13.1678 80.9856 11.906H80.9835Z"
        fill="#F1BE8F"
      />
      <path
        d="M72.8838 5.18507C74.2055 1.31985 86.4893 0.366936 82.02 10.8164C81.3234 12.4448 82.1681 15.0052 82.6209 15.353C82.6209 15.5631 80.8044 17.7673 80.8097 17.6612C80.9904 14.0733 79.1182 12.1086 78.8229 10.8101C79.2694 10.6672 79.4176 9.93072 79.6014 9.60502C79.7842 9.28248 80.0637 8.75717 79.6928 8.06482C79.2358 7.21276 77.9194 7.63932 77.8984 8.68678C77.8858 9.3182 76.9591 9.32871 76.4433 8.92737C75.6669 8.32222 75.4599 8.10894 74.6509 6.96377C74.2317 6.36912 73.3009 5.62633 72.8848 5.18402L72.8838 5.18507Z"
        fill="#DA9B76"
      />
      <path
        d="M72.4258 11.295C72.5456 11.5177 72.9017 11.5345 73.0866 11.5765C73.2453 11.6122 73.5058 11.6059 73.6414 11.4546C73.8368 11.2372 73.6151 10.8884 73.468 10.8789C73.5563 10.984 73.6151 11.1941 73.5289 11.2802C73.4291 11.3811 73.3115 11.3139 73.218 11.296C73.0824 11.2697 72.9679 11.2865 72.8187 11.338C72.6916 11.3811 72.5844 11.4095 72.4258 11.2939V11.295Z"
        fill="#D0825D"
      />
      <path
        d="M74.6927 12.9206C74.721 13.1444 74.512 13.5688 74.6412 13.9113C74.7231 14.1288 74.9879 14.7665 75.5594 14.6006C77.6354 13.9964 78.2122 13.1833 78.415 11.8837C78.4371 11.7439 78.5684 11.7282 78.5453 12.0244C78.457 13.1381 78.8395 13.7653 77.1732 14.4787C75.9145 15.0176 75.3178 15.1931 74.7914 14.692C74.5403 14.4524 74.6086 13.9744 74.4374 13.7643C74.3197 13.6193 74.0455 13.6203 74.0098 13.6119C74.139 13.5804 74.5172 13.2747 74.6927 12.9206Z"
        fill="#DA9B76"
      />
      <path
        d="M72.9298 11.4363C73.0065 11.3144 73.1777 11.3606 73.3416 11.4615C73.2502 11.4563 73.2334 11.4531 73.1294 11.4962C72.9854 11.5561 72.9067 11.4731 72.9298 11.4363Z"
        fill="#B26F4A"
      />
      <path
        d="M73.4341 9.34957C73.516 9.29914 73.7598 9.13419 74.117 9.00601C73.9541 8.95348 73.703 8.76963 73.4435 8.83687C73.2912 8.87679 73.2555 8.95874 73.2334 8.9892C73.2439 9.09111 73.2975 9.32645 73.4341 9.35062V9.34957Z"
        fill="white"
      />
      <path
        d="M73.2708 9.08544C73.3243 9.29347 73.463 9.34495 73.5733 9.31658C73.6826 9.28821 73.7173 9.19156 73.6689 8.98143C73.6332 8.82594 73.5334 8.76185 73.4157 8.77026C73.3023 8.77866 73.2161 8.87637 73.2708 9.08439V9.08544ZM73.4304 9.08229C73.4178 9.03396 73.4325 8.98773 73.4641 8.97933C73.4956 8.97093 73.5313 9.00455 73.5439 9.05287C73.5565 9.1012 73.5418 9.14743 73.5103 9.15584C73.4788 9.16424 73.443 9.13062 73.4304 9.08229Z"
        fill="#592A14"
      />
      <path
        d="M73.4699 9.65745C73.6622 9.75306 74.0236 9.46939 74.2705 9.21094C74.1676 9.39585 73.9007 9.94952 73.5424 9.87388C73.4395 9.85182 73.4216 9.75411 73.4699 9.65745Z"
        fill="#D0825D"
      />
      <path
        d="M72.5859 7.66671C72.5838 7.48496 73.4432 7.23701 73.688 7.34103C73.9003 7.43033 73.1333 7.49442 73.044 7.66567C72.899 7.94303 73.1018 8.28238 73.2993 8.53348C73.2783 8.56394 73.209 8.68266 73.2195 8.86022C73.1785 8.88123 73.1365 8.89909 73.0923 8.91275C72.9347 8.54503 72.5922 8.20673 72.587 7.66671H72.5859Z"
        fill="#DA9B76"
      />
      <path
        d="M73.3539 9.32643C73.2909 9.25919 73.2394 9.13732 73.2236 9.05222C73.2404 9.03646 73.2562 9.00809 73.2688 8.99023C73.2804 9.11526 73.3455 9.19826 73.4211 9.276C73.6218 9.11736 73.8656 9.04697 74.0725 9.00914C74.1534 8.99864 74.2123 9.01125 74.2679 9.02385C74.0347 9.14152 73.4338 9.26444 73.3749 9.81287C73.2457 9.61535 73.2741 9.41678 73.3518 9.32643H73.3539Z"
        fill="#592A14"
      />
      <path
        d="M73.1252 7.71173C72.9592 7.84831 73.2492 8.40304 73.4152 8.40199C73.9899 8.39778 74.0634 8.6762 74.304 8.78861C74.0288 8.7676 73.7966 8.5081 73.4404 8.53541C73.2828 8.54802 73.1925 8.76865 73.2954 8.92414C73.2839 8.9315 73.2093 8.9441 73.1736 8.9336C73.1189 8.87056 73.1357 8.62157 73.2345 8.52701C73.078 8.47238 72.8657 7.93236 72.9529 7.76111C72.9729 7.70858 73.0202 7.69072 73.1252 7.71173Z"
        fill="#D0825D"
      />
      <path
        d="M72.6953 8.75892C72.8477 8.88815 73.1198 8.76418 73.3635 8.7211C73.769 8.65071 74.141 8.91441 74.452 8.81355C74.4278 8.93543 74.3322 9.01842 74.2691 9.02473C73.9655 9.05309 73.8268 8.88184 73.477 8.92702C73.3614 8.94173 73.3089 9.00266 73.228 9.0615C73.083 9.16656 72.7857 8.96064 72.6953 8.75892Z"
        fill="#592A14"
      />
      <path
        d="M72.6621 7.52651C72.8775 7.35526 73.7558 7.04743 74.5438 7.64313C74.6047 7.68936 74.5848 7.75449 74.4734 7.71772C73.4375 7.36892 72.8985 7.87952 72.8124 7.94991C72.5392 8.17264 72.5319 7.63052 72.6621 7.52651Z"
        fill="#592A14"
      />
      <path
        d="M73.5858 12.7338C73.3399 12.6456 74.3348 12.4113 74.4683 12.3493C74.5954 12.2904 74.671 12.3934 74.65 12.488C74.6259 12.4491 74.5796 12.4638 74.5439 12.4838C74.3464 12.593 73.8306 12.779 73.5858 12.7338Z"
        fill="#B26F4A"
      />
      <path
        d="M73.5864 12.7333C73.2754 12.6546 73.2271 12.415 73.3301 12.166C73.5402 12.4108 74.1285 12.3352 74.4637 12.249C74.5183 12.2354 74.6759 12.1566 74.7484 12.2847C74.8251 12.4203 74.7316 12.6188 74.6497 12.4875C74.6255 12.4486 74.6045 12.352 74.5194 12.3961C74.3187 12.5001 73.9573 12.6903 73.5854 12.7333H73.5864Z"
        fill="#CB8A6A"
      />
      <path
        d="M79.0015 1.11371C81.7236 1.25344 82.9675 3.06996 83.62 5.09135C83.7429 5.68705 84.0077 6.56116 83.6757 7.83976C83.3195 8.55629 82.9297 10.1091 82.0199 10.8151C80.7938 11.1114 80.0941 10.5251 79.6003 9.60375C79.6791 9.38628 80.0111 8.78952 79.6392 8.11293C79.1822 7.28189 77.996 7.66747 77.8973 8.68551C77.7838 8.89879 77.3772 9.21607 76.8498 8.85466C76.2972 8.47644 75.9767 8.05199 75.2066 6.99297C74.7255 6.33213 73.4405 5.42965 72.8848 5.1817C72.9825 3.46184 75.7109 0.94246 79.0025 1.11161L79.0015 1.11371Z"
        fill="#742A1E"
      />
      <path
        d="M79.0015 1.11461C75.732 2.39952 74.7444 3.43543 74.9272 4.44192C75.1909 5.89282 76.1165 7.37525 76.8488 8.85662C76.2962 8.4784 75.9758 8.05395 75.2057 6.99492C74.7245 6.33408 73.4396 5.43161 72.8838 5.18366C72.9815 3.4638 75.7099 0.944413 79.0015 1.11356V1.11461Z"
        fill="#893C28"
      />
      <path
        d="M81.7287 8.92235C82.4505 8.75846 83.2899 8.41805 83.6209 5.0918C83.7438 5.6875 84.0086 6.56161 83.6766 7.84021C83.5116 8.70382 82.9306 10.1095 82.0208 10.8156C80.7947 11.1118 80.095 10.5256 79.6012 9.6042C79.68 9.38672 80.012 8.78997 79.6401 8.11337C79.5728 7.9915 79.4909 7.89695 79.3984 7.82551C80.0004 7.68893 80.2253 9.2638 81.7287 8.9213V8.92235Z"
        fill="#5E1B12"
      />
      <path
        d="M85.604 9.37689C85.5095 9.85597 85.6881 11.2848 86.0243 12.3092C84.8455 10.7091 84.8917 8.16658 84.3864 7.81462C84.3801 7.65703 84.3212 5.21434 84.3223 5.06095C86.3016 4.16898 87.6969 5.47699 88.2537 6.92685C88.7149 8.12875 88.6855 9.41681 88.6099 10.6429C88.4775 9.70048 88.2568 8.75072 87.8965 7.91968C88.4565 10.2321 87.9721 12.3995 88.5332 13.6182C87.9784 12.9017 87.6853 12.0906 87.4037 10.9518C87.4489 12.2136 87.9259 13.7979 89.0774 14.8264C87.6674 14.3043 86.0716 13.2001 85.604 9.37899V9.37689Z"
        fill="#742A1E"
      />
      <path
        d="M83.6212 5.09222C83.7063 4.88315 84.0824 4.82116 84.323 5.05965C84.6413 5.57761 84.7359 7.0863 84.3871 7.81332C84.2074 8.09384 83.7556 8.05917 83.6758 7.84064C83.7903 6.76796 83.8271 6.47694 83.6201 5.09222H83.6212Z"
        fill="#282D3E"
      />
      <path
        d="M78.8023 10.2336C78.7393 10.3197 78.5869 10.3229 78.5155 10.2452C78.4819 10.2084 78.4966 10.1863 78.5407 10.1758C78.5943 10.1632 78.65 10.159 78.7025 10.1432C78.7561 10.1275 78.8023 10.0939 78.857 10.0823C78.8643 10.0802 78.8832 10.0907 78.8801 10.0981C78.8643 10.138 78.8328 10.1905 78.8023 10.2325V10.2336Z"
        fill="#D0825D"
      />
      <path
        d="M79.5358 8.75286C79.5347 8.96404 79.4423 9.25191 79.3572 9.34331C79.3088 9.39584 79.2511 9.43156 79.3183 9.30444C79.4023 9.14685 79.3761 8.62259 79.2763 8.49336C79.1386 8.31371 78.9359 8.24332 78.8571 8.32737C78.8024 8.38515 78.9506 8.45134 79.0052 8.57531C79.0378 8.6499 78.9212 8.63414 78.8802 8.60473C78.7636 8.51858 78.668 8.51753 78.6081 8.52068C78.5671 8.52278 78.5146 8.61944 78.5156 8.65621C78.523 8.85162 78.7961 8.87894 78.9306 8.95669C79.0262 9.01132 79.1292 9.10167 79.0882 9.27923C79.0767 9.3286 79.0294 9.4862 78.9905 9.52087C78.9086 9.59336 78.8308 9.57445 78.7299 9.6669C78.6165 9.77092 78.4915 9.97894 78.3444 9.92851C78.2488 9.89594 78.2278 9.79403 78.2719 9.70893C78.3013 9.65325 78.5188 9.54924 78.5272 9.4011C78.5314 9.31915 78.4862 9.18887 78.4169 9.13739C78.2215 8.99031 78.1395 8.77178 78.3286 8.37359C78.4463 8.1267 78.7279 8.06051 78.8907 8.05C79.2406 8.02689 79.5389 8.34838 79.5358 8.75286Z"
        fill="#D0825D"
      />
      <path
        d="M78.6785 10.5574C78.6911 10.6067 78.7258 10.6404 78.7552 10.632C78.7846 10.6246 78.7983 10.5784 78.7857 10.529C78.7731 10.4796 78.7384 10.446 78.709 10.4544C78.6796 10.4618 78.6659 10.508 78.6785 10.5574Z"
        fill="#D0825D"
      />
      <path
        d="M78.7922 11.2691L78.8531 11.2564C78.7732 11.1178 79.0128 10.5473 78.7207 10.5C78.7029 10.5095 78.7144 10.5557 78.7354 10.563C78.9161 10.5588 78.7407 11.1304 78.7922 11.2691Z"
        fill="#4F5F7D"
      />
      <path
        d="M77.7611 13.0929L78.6625 13.8556C78.8884 14.0468 79.2299 14.0185 79.42 13.7926L80.1828 12.8912C80.374 12.6653 80.3456 12.3238 80.1197 12.1337L79.2183 11.3709C78.9924 11.1797 78.651 11.2081 78.4608 11.4339L77.6981 12.3354C77.5068 12.5613 77.5352 12.9027 77.7611 13.0929ZM78.1939 12.1977L78.6541 11.6546C78.7581 11.5317 78.943 11.5169 79.0649 11.6199L79.6081 12.0801C79.731 12.1841 79.7457 12.369 79.6427 12.4919L79.1826 13.0351C79.0786 13.158 78.8937 13.1727 78.7707 13.0698L78.2276 12.6096C78.1046 12.5056 78.0899 12.3207 78.1929 12.1977H78.1939Z"
        fill="#597997"
      />
      <path
        d="M66.8779 37.7706C71.924 36.5361 73.8814 35.0684 73.9507 34.6481C74.3405 32.3084 73.8456 27.6363 73.4464 26.3146C71.4334 25.3501 71.8946 22.8665 72.4819 21.2958C72.6952 20.7253 73.8729 17.5094 75.2713 16.3905C75.4489 16.3548 76.469 15.986 76.8493 15.8189C77.0563 15.7275 77.2129 15.6613 77.3053 15.633C76.8651 17.2415 80.5381 18.082 82.6183 15.3535C82.7885 15.3798 83.0081 15.3987 83.1772 15.4239C84.9276 15.675 86.9521 16.962 87.428 17.8456C88.1319 19.4215 87.6266 22.1594 86.1736 24.3016C84.7353 26.7433 84.853 31.1117 85.0368 32.7927C85.3068 35.2596 86.6621 38.3116 86.3679 42.0193C85.8342 48.7411 76.1255 47.6464 70.6307 48.0036C70.6759 45.77 70.2284 42.7767 69.6032 41.0338C68.7564 38.6751 68.084 37.9954 66.8779 37.7727V37.7706Z"
        fill="#6222C3"
      />
      <path
        d="M83.179 15.4227C81.1513 19.1976 76.6231 18.8047 76.85 15.8188C76.9005 15.7337 77.0959 15.5624 77.306 15.6328C76.8658 17.2413 80.5387 18.0818 82.619 15.3534C82.8291 15.283 83.1086 15.3534 83.1779 15.4238L83.179 15.4227Z"
        fill="#4C565F"
      />
      <path
        d="M81.5816 30.7833C80.1034 29.074 73.847 27.6367 73.4477 26.314C74.1044 26.7563 75.6099 26.6439 76.2581 26.1186C77.6597 27.6514 80.6949 27.2879 81.8506 24.7906C83.3824 21.4833 85.6706 23.1548 86.1749 24.3C84.8343 27.5075 84.8543 31.1101 85.0382 32.7911C85.3082 35.2579 86.7927 38.3488 86.5363 42.0596C86.0341 49.3393 74.5183 48.0019 70.631 48.0019C70.4965 44.9194 70.1393 42.7751 69.6035 41.0321C73.267 43.8467 83.4643 44.0694 83.811 40.138C84.3468 34.0623 75.4565 36.2066 73.9814 34.6422C78.2701 33.4803 82.4253 31.7594 81.5806 30.7833H81.5816Z"
        fill="#213EAB"
      />
      <path
        d="M64.2041 32.1551C64.0811 31.6382 65.0435 31.9366 65.7915 31.9429C66.5091 31.9366 66.8274 31.82 67.5629 31.861C67.2656 32.1425 66.5679 32.9547 66.0699 33.1207C66.0185 32.941 65.8336 32.7666 65.4995 32.7782C64.8849 32.8002 64.327 32.6783 64.203 32.1551H64.2041Z"
        fill="#E2A367"
      />
      <path
        d="M51.4911 26.5137C53.4326 28.803 58.3894 34.698 60.0578 36.6574C60.166 36.7845 60.3247 36.858 60.4917 36.8601C62.5615 36.8749 66.3741 36.898 68.5899 36.8864C68.8389 36.8854 68.9723 36.5933 68.8095 36.4042C66.7324 33.9962 62.0866 28.41 60.2627 26.1428C60.1104 25.9537 59.8782 25.8465 59.6355 25.8539C57.1445 25.9264 54.3309 25.9936 51.7138 26.0251C51.4627 26.0283 51.3282 26.3225 51.4911 26.5147V26.5137Z"
        fill="#457A88"
      />
      <path
        d="M51.4902 26.5129C53.4318 28.8022 58.3886 34.6972 60.057 36.6566C60.1652 36.7837 60.3238 36.8573 60.4909 36.8594C62.5606 36.8741 66.3733 36.8972 68.589 36.8856C68.0784 36.3278 60.3879 27.0991 59.6893 26.3301C57.1982 26.4026 52.7678 26.4698 51.4913 26.5139L51.4902 26.5129Z"
        fill="#346674"
      />
      <path
        d="M64.8237 32.9083C64.6861 32.8957 63.4443 32.901 62.7761 32.5207C62.0428 32.1025 61.9808 32.6888 62.3832 32.9756C63.1732 33.5398 64.1356 33.6637 65.0003 33.8014C64.3657 33.8949 62.9904 33.6774 62.3579 33.2456C61.9356 32.9567 61.7812 33.4095 62.2256 33.8066C62.9169 34.4244 64.1366 34.6061 64.9687 34.6555C64.6378 34.706 63.728 34.7469 62.9852 34.3698C62.4903 34.1187 62.4346 34.7627 63.0839 35.0537C63.9444 35.4393 64.8269 35.4729 65.3785 35.4088C64.8931 35.5475 64.4214 35.4708 63.7143 35.4246C63.4516 35.4078 63.4663 35.8648 64.1188 35.9667C65.0717 36.1159 66.8199 36.1159 67.6615 35.7965C67.0321 35.0506 66.1507 33.9999 65.2009 32.8674C65.0643 32.902 64.9372 32.9188 64.8237 32.9083Z"
        fill="#2A505E"
      />
      <path
        d="M62.7653 33.3119C63.4335 33.6838 64.8234 33.7742 65.4465 33.6229C64.5723 33.5651 63.6037 33.5305 62.7653 33.0409C62.3387 32.7919 62.3461 32.2014 63.1151 32.5513C63.8148 32.8696 65.0514 32.7498 65.1901 32.7498C65.832 32.7509 66.8207 31.8516 67.5193 31.8516C68.3073 31.8516 69.9463 32.0239 70.3161 31.8516C71.1849 32.1363 70.7195 33.7563 70.5262 34.2984C69.7761 34.6273 68.9524 35.16 68.0173 35.4573C67.7284 35.5497 66.0873 35.9017 64.7678 35.8607C64.108 35.8397 64.0523 35.3659 64.3149 35.3585C65.0231 35.3396 65.5 35.3722 65.9707 35.1904C65.4275 35.3049 64.545 35.3522 63.653 35.0465C62.9807 34.8164 62.9765 34.1692 63.4923 34.3741C64.2666 34.6819 65.1691 34.558 65.4937 34.4771C64.6606 34.5033 63.4293 34.435 62.6844 33.8824C62.2063 33.5284 62.3177 33.0629 62.7653 33.3119Z"
        fill="#F1BE8F"
      />
      <path
        d="M86.0356 17.2015C83.9302 17.0229 82.6757 20.7589 82.5801 21.1508C82.1389 22.9473 80.2057 28.3454 79.7571 29.5011C78.3924 29.8573 72.5719 31.1915 70.3162 31.8524C70.0368 32.6214 70.1198 33.2896 70.5264 34.2993C73.0552 34.3392 80.8855 34.17 82.6631 33.0564C84.0793 32.1686 87.206 24.373 87.705 22.5555C88.0601 21.2643 88.7903 17.4348 86.0366 17.2015H86.0356Z"
        fill="#D4C1EE"
      />
      <path
        d="M47.4494 67.5255C46.7676 66.5726 46.2948 66.3709 46.1078 65.418C45.5835 65.7321 43.2375 65.627 42.5084 65.5409C42.5283 65.9443 42.2825 66.8605 42.2321 67.1725C41.5061 69.4502 46.1498 70.005 46.818 70.0764C48.2899 70.233 49.9331 69.1077 49.9331 69.1077C48.7469 68.4837 48.1491 68.5047 47.4484 67.5255H47.4494Z"
        fill="#D7D9DF"
      />
      <path
        d="M42.2317 67.1719C43.5881 68.6774 44.4496 68.8854 46.5319 69.3845C48.1005 69.7606 49.7416 69.3645 49.9422 69.0998C49.9422 69.0998 51.951 69.4044 52.1013 70.2176C52.2378 70.9552 48.4903 71.887 46.1369 71.0896C44.7742 70.6284 42.715 71.014 41.6907 69.956C41.428 68.6007 41.6781 67.5974 42.2317 67.1719Z"
        fill="#457A88"
      />
      <path
        d="M41.6905 69.9561C43.3967 70.4856 44.7404 70.0927 46.2281 70.6527C47.5151 71.4627 50.8403 71.1843 52.1011 70.2177C52.2482 70.3259 52.2545 70.4562 52.2471 70.5665C52.1956 71.3146 49.3043 72.2276 46.2985 71.3934C45.6881 71.2242 44.42 71.3545 43.4787 71.302C42.7044 71.2589 42.2274 71.179 42.0477 70.9983C41.8071 70.7577 41.6895 70.2261 41.6895 69.9551L41.6905 69.9561Z"
        fill="#234E5E"
      />
      <path
        d="M49.5116 48.7736C49.3771 53.8775 45.9248 60.2537 46.1076 65.4164C46.1422 66.3872 42.4661 65.9417 42.5082 65.5394C42.7466 63.2963 42.8654 58.247 42.7393 56.2487C42.6111 54.2116 42.2402 51.2909 43.0797 48.3544C41.1791 47.8785 32.8908 45.979 27.6535 45.2813C25.9756 38.6393 34.015 35.5642 37.2309 36.1231C37.6575 36.3143 38.104 36.5549 38.6986 36.8922C41.8063 38.654 46.7968 42.0885 48.3223 43.3209C48.815 43.7191 49.3246 44.4429 49.5105 45.628C49.6839 46.7354 49.5242 48.264 49.5105 48.7736H49.5116Z"
        fill="#1C2438"
      />
      <path
        d="M44.9662 49.6834C45.5871 47.2344 45.7352 44.9997 44.3368 43.8104C42.2062 41.9992 37.6822 39.878 38.6982 36.8921C38.279 36.6126 37.5793 36.2628 37.2305 36.123C34.4337 36.3332 26.4637 37.8713 29.5704 45.8077C34.8077 46.5053 41.1787 47.8795 43.0792 48.3554C42.2398 51.2919 42.6107 54.2115 42.7388 56.2497C43.1717 55.0237 44.7539 50.5218 44.9662 49.6844V49.6834Z"
        fill="#151228"
      />
      <path
        d="M37.0292 67.9992C36.4272 66.9948 36.507 66.9559 36.3988 65.9914C35.8504 66.2625 33.5201 65.9673 32.8015 65.8223C32.7889 66.2268 31.9337 66.9181 31.858 67.2248C30.9492 69.4353 35.5331 70.3651 36.1939 70.4902C37.648 70.7654 39.3773 69.7768 39.3773 69.7768C38.2458 69.0592 37.648 69.0309 37.0292 67.9981V67.9992Z"
        fill="#D7D9DF"
      />
      <path
        d="M31.8576 67.2246C33.0868 68.8352 33.9294 69.1126 35.9644 69.7787C37.4973 70.2809 39.1657 70.0193 39.3863 69.7713C39.3863 69.7713 41.3635 70.2378 41.4476 71.0604C41.5243 71.8064 37.7137 72.4315 35.4318 71.446C34.1111 70.8755 32.0277 71.093 31.0916 69.9562C30.9393 68.5841 31.2703 67.6039 31.8565 67.2246H31.8576Z"
        fill="#457A88"
      />
      <path
        d="M31.0927 69.9551C32.7506 70.6212 34.1216 70.3386 35.5589 71.0173C36.7765 71.9292 40.1122 71.9208 41.4476 71.0593C41.5852 71.179 41.5821 71.3093 41.5652 71.4186C41.4539 72.1603 38.4974 72.8359 35.5694 71.7611C34.9747 71.5426 33.7003 71.5699 32.7663 71.4417C31.9983 71.3366 31.5297 71.2179 31.3637 71.0236C31.1431 70.7641 31.0696 70.2251 31.0916 69.9551H31.0927Z"
        fill="#234E5E"
      />
      <path
        d="M27.4193 36.8203C26.5095 40.0173 25.3223 44.5108 25.3223 47.4463C29.5174 48.0756 35.8789 48.914 37.6271 49.6137C35.7822 51.5994 35.2937 52.8801 34.9039 55.0485C34.5646 56.9375 33.6873 63.298 32.8027 65.8237C33.6411 66.6064 35.6372 66.3784 36.4 65.9928C37.1354 62.6897 42.6648 53.917 43.9896 48.9854C44.0916 48.6062 44.3101 47.9275 44.4088 47.2372C44.5486 46.258 44.2691 44.5108 43.1502 43.8815C41.5154 42.9622 34.2704 38.3805 27.4203 36.8203H27.4193Z"
        fill="#282D3E"
      />
      <path
        d="M36.2371 40.093C33.5686 38.7965 30.4556 37.5116 27.4193 36.8203C26.5095 40.0173 25.3223 44.5108 25.3223 47.4463C26.0157 47.5503 26.7679 47.6595 27.5485 47.7741C28.3522 42.9055 32.5474 39.969 36.2371 40.093Z"
        fill="#1B1C32"
      />
      <path
        d="M38.2794 2.21707C33.6493 3.77303 32.127 12.4732 32.127 15.748C32.127 19.6952 36.6015 24.0993 41.0761 25.0785C41.0057 22.7714 40.8345 19.6321 41.4953 16.4792C42.1761 13.2317 44.1523 11.5739 44.1523 9.06815C44.1523 5.56329 41.0698 1.27887 38.2794 2.21707Z"
        fill="#1A1A1A"
      />
      <path
        d="M38.2793 2.21707C40.5161 4.03464 41.635 5.29328 42.7539 6.97112C43.5933 7.39031 44.0125 8.22976 44.1523 9.06815C44.1523 5.56329 41.0697 1.27887 38.2793 2.21707Z"
        fill="#1D222E"
      />
      <path
        d="M32.9306 9.139C34.5191 7.43699 33.9865 4.22315 37.6826 3.32488C38.6933 3.07903 42.2506 4.91866 42.7602 6.94741C42.9976 7.89296 43.1867 9.42161 42.5427 10.4008C42.4387 10.5584 42.195 10.8778 42.0122 11.0511C41.7464 11.3033 41.4448 11.4346 41.345 11.6342C41.178 11.9673 41.1475 12.4863 41.2631 12.8855C41.3639 13.2333 41.2578 13.4959 41.0803 13.6157C40.992 13.6745 40.8775 13.7008 40.66 13.6136C40.6295 13.601 40.2723 13.6451 40.2723 13.6451C40.0317 13.8016 40.1694 14.0611 40.2103 14.1715C39.975 14.6526 38.128 14.369 38.1312 14.5907C38.1406 14.8008 38.6302 14.9405 38.8078 15.1643C39.1146 15.5499 38.6365 15.9123 38.4096 15.8724C38.0976 15.5919 37.9211 15.5541 37.7698 15.5593C36.4544 16.1046 36.2632 15.2988 35.1747 14.5812C34.994 14.661 34.5738 15.2904 34.7135 15.7096C35.6223 17.1079 35.9018 19.6242 35.1327 21.5121C33.2668 20.3817 28.7376 14.6936 29.2135 13.5684C30.468 13.374 31.3694 10.8126 32.9296 9.14005L32.9306 9.139Z"
        fill="#694139"
      />
      <path
        d="M40.9948 11.4273C40.9486 11.3538 40.8183 11.1248 40.583 10.8789C40.7343 10.901 41.0106 10.8537 41.1913 11.0102C41.2974 11.1027 41.2943 11.1836 41.2995 11.2162C41.2512 11.2939 41.1136 11.462 40.9948 11.4273Z"
        fill="white"
      />
      <path
        d="M41.2394 10.9704C41.148 10.9168 41.0418 10.9284 40.9525 11.0398C40.8307 11.189 40.8464 11.3287 40.9231 11.3949C40.9998 11.4611 41.1049 11.4274 41.231 11.2814C41.357 11.1354 41.3266 11.023 41.2394 10.9704ZM41.0103 11.2425C40.9883 11.2236 40.9946 11.1805 41.024 11.1459C41.0534 11.1123 41.0954 11.0996 41.1175 11.1186C41.1396 11.1375 41.1332 11.1805 41.1038 11.2152C41.0744 11.2488 41.0324 11.2614 41.0103 11.2425Z"
        fill="#141211"
      />
      <path
        d="M40.9851 11.4646C40.9515 11.5129 40.8885 11.598 40.7992 11.5707C40.5985 11.5087 40.5491 11.3564 40.4893 11.2188C40.4977 11.4089 40.567 11.6012 40.7719 11.6863C40.8591 11.722 40.983 11.6274 41.0261 11.5339C41.0251 11.5224 40.9862 11.4772 40.9851 11.4646Z"
        fill="#42261E"
      />
      <path
        d="M42.3476 10.4131C42.4212 10.2681 42.0797 10.1988 42.0829 10.3711C42.0892 10.6516 41.7645 10.6243 41.5061 10.7482C41.5113 10.7808 41.4431 10.9815 41.3643 11.1202C41.3884 11.1538 41.4157 11.1842 41.4462 11.2137C41.7194 10.9815 42.1291 10.8449 42.3487 10.4131H42.3476Z"
        fill="#533330"
      />
      <path
        d="M41.0356 11.4812C41.1133 11.4528 41.2783 11.3121 41.3245 11.2501C41.3172 11.2301 41.2993 11.1933 41.2961 11.1734C41.2373 11.27 41.0934 11.3803 41.0009 11.414C40.9032 11.207 40.7351 11.0526 40.5828 10.9401C40.5218 10.8992 40.4693 10.8855 40.4199 10.875C40.5607 11.062 41.085 11.6283 41.0377 11.9309C41.1133 11.7743 41.0986 11.581 41.0356 11.4812Z"
        fill="#141211"
      />
      <path
        d="M42.0127 10.4299C41.9486 10.6137 41.6881 10.6579 41.5557 10.5906C41.0955 10.3584 40.8896 10.4036 40.6133 10.4572C40.8423 10.5497 41.1228 10.5465 41.3981 10.7104C41.52 10.7829 41.4212 11.0571 41.2762 11.1422C41.2825 11.1527 41.3382 11.1926 41.3697 11.1979C41.4391 11.1685 41.6418 10.8911 41.5998 10.7766C41.7469 10.7955 41.9833 10.6768 42.0799 10.5539C42.0841 10.5034 42.1052 10.4551 42.0117 10.4299H42.0127Z"
        fill="#42261E"
      />
      <path
        d="M41.9694 11.2059C41.6847 11.4738 41.4829 11.0819 41.3033 10.9506C41.0049 10.732 40.3945 10.7257 40.3199 10.3633C40.1928 10.5976 40.3735 10.8445 40.4218 10.8749C40.654 11.0189 40.8673 10.8949 41.131 11.0714C41.2182 11.1292 41.2224 11.208 41.277 11.2784C41.3821 11.4128 41.7498 11.5936 41.9694 11.2069V11.2059Z"
        fill="#141211"
      />
      <path
        d="M42.4192 10.3225C42.3141 10.0987 41.7794 9.2519 40.9084 9.41789C40.8412 9.4305 40.8012 9.48618 40.9063 9.49354C41.5272 9.53871 42.0966 10.3267 41.9401 10.6723C42.1523 10.8194 42.4822 10.457 42.4192 10.3214V10.3225Z"
        fill="#141211"
      />
      <path
        d="M42.7605 6.87864C42.4043 2.9861 33.7357 -0.579698 32.0305 9.05447C31.7132 9.98952 29.4386 13.5417 28.7021 13.9619C28.7841 14.9558 29.9997 15.6681 30.0301 15.5694C30.7225 13.3662 33.4572 11.7157 35.1267 10.3016C34.9071 10.2028 34.407 9.94539 34.7127 8.9326C34.1801 6.09803 37.687 6.73891 36.6847 8.61006C36.2519 9.69745 37.3109 9.89812 38.0695 9.27825C39.6076 8.01961 41.5764 7.19908 42.7594 6.87864H42.7605Z"
        fill="#533330"
      />
      <path
        d="M33.0351 2.94654C31.3573 4.73365 31.4581 8.07986 32.0297 9.12418C33.0687 9.91004 33.9197 9.73564 34.7119 9.00231C34.7077 8.76382 34.5848 8.06831 35.1763 7.53354C35.9023 6.87586 36.9308 7.65121 36.685 8.67977C36.602 9.13784 36.9308 9.52447 37.5108 9.20823C38.2798 8.78903 40.7267 6.55122 42.7607 6.94835C42.9645 3.475 37.6978 -2.01868 33.0362 2.94654H33.0351Z"
        fill="#1D222E"
      />
      <path
        d="M33.0352 2.94654C37.5801 -0.649722 40.8654 3.19554 40.8654 4.73365C40.8654 6.03641 38.7684 7.8792 37.5097 9.20823C38.2788 8.78903 40.7257 6.55122 42.7597 6.94835C42.9635 3.47501 37.6968 -2.01868 33.0352 2.94654Z"
        fill="#2A3142"
      />
      <path
        d="M33.0864 7.45127C32.4697 6.99845 31.978 6.23465 33.036 2.94727C31.2184 4.8741 31.3582 8.15938 32.0306 9.1249C33.0696 9.91076 33.9206 9.73636 34.7128 9.00303C34.7086 8.76454 34.5857 8.06903 35.1772 7.53427C35.2833 7.43761 35.3957 7.37352 35.5102 7.33465C34.9691 6.99845 34.3703 8.39472 33.0875 7.45127H33.0864Z"
        fill="#1A1A1A"
      />
      <path
        d="M35.0618 8.19223C34.9914 8.3992 34.9851 8.71124 35.0377 8.8289C35.0671 8.89614 35.1122 8.95078 35.0881 8.80369C35.0587 8.62193 35.2614 8.11869 35.4033 8.02518C35.5987 7.89701 35.8193 7.89596 35.8676 8.00417C35.9013 8.07981 35.7342 8.09347 35.6397 8.19643C35.5829 8.25842 35.7016 8.28258 35.751 8.26682C35.8939 8.2227 35.9874 8.25317 36.0452 8.27628C36.0851 8.29204 36.103 8.40446 36.0893 8.44018C36.0158 8.62824 35.7405 8.5631 35.5829 8.59357C35.4716 8.61458 35.3402 8.66816 35.3203 8.85622C35.315 8.90875 35.3077 9.07895 35.3339 9.12518C35.3896 9.22394 35.4716 9.23129 35.5388 9.35527C35.6144 9.495 35.6659 9.73979 35.8277 9.73979C35.9317 9.73979 35.9874 9.64839 35.9727 9.55068C35.9633 9.48659 35.7846 9.31219 35.8267 9.16405C35.8498 9.0821 35.938 8.97074 36.0231 8.94342C36.2637 8.86568 36.4171 8.67972 36.3667 8.22795C36.3352 7.94639 36.082 7.78774 35.9265 7.7226C35.5924 7.58287 35.1931 7.79615 35.0597 8.19118L35.0618 8.19223Z"
        fill="#42261E"
      />
      <path
        d="M40.6962 13.5438C40.5723 13.4524 40.4609 13.4041 40.3264 13.3957C40.2329 13.3894 39.9955 13.382 40.1005 13.1782C40.1552 13.0731 40.4399 12.966 40.567 13.1068C40.4147 12.5962 39.7475 13.0532 39.8873 13.4724C39.9503 13.6604 40.2245 13.6867 40.3821 13.6909C40.566 13.6962 40.9022 13.7876 41.0787 13.6153C40.8969 13.6752 40.8013 13.6216 40.6962 13.5438Z"
        fill="#42261E"
      />
      <path
        d="M37.8648 15.0504C37.7755 15.2301 37.7451 15.4402 37.6211 15.4843C37.3942 15.5652 36.7207 15.7764 36.2112 15.1965C35.3034 14.1627 34.7088 12.8032 35.1217 11.1274C35.1542 10.9929 35.0576 10.8921 34.9052 11.139C34.6352 11.575 34.4104 12.219 34.5806 13.2381C34.5922 13.3106 34.8285 14.045 35.1742 14.5819C35.6869 15.3772 36.1502 16.2324 36.8489 16.1168C37.1819 16.0622 37.7083 15.7302 37.9562 15.7386C38.1622 15.7449 38.1916 15.8647 38.4091 15.872C38.2336 15.7071 37.9646 15.4539 37.8659 15.0504H37.8648Z"
        fill="#533330"
      />
      <path
        d="M40.5645 13.5969C40.5361 13.5171 40.4016 13.4499 40.2567 13.495C40.1968 13.5139 40.2367 13.5759 40.3092 13.6095C40.4468 13.6736 40.5792 13.6369 40.5645 13.5969Z"
        fill="#2E1512"
      />
      <path
        d="M35.4105 9.7339C35.4284 9.83686 35.5586 9.9062 35.6542 9.87048C35.6994 9.85367 35.6963 9.8274 35.6626 9.79903C35.6217 9.76436 35.5754 9.736 35.5366 9.70028C35.4977 9.66351 35.4714 9.61413 35.4284 9.57946C35.4221 9.5742 35.4021 9.57525 35.401 9.58261C35.3968 9.62463 35.401 9.68347 35.4105 9.7339Z"
        fill="#42261E"
      />
      <path
        d="M35.2387 10.0089C35.2303 10.0625 35.1978 10.1024 35.1652 10.0972C35.1326 10.0919 35.1127 10.0446 35.1211 9.99106C35.1295 9.93748 35.162 9.89756 35.1946 9.90281C35.2272 9.90806 35.2471 9.95534 35.2387 10.0089Z"
        fill="#42261E"
      />
      <path
        d="M35.1757 10.6546L35.1095 10.6515C35.1715 10.4918 34.8658 10.0411 35.1715 9.95703C35.1915 9.96544 35.1852 10.0159 35.1631 10.0253C34.9708 10.0411 35.2072 10.4991 35.1757 10.6546Z"
        fill="#F08D16"
      />
      <path
        d="M35.6921 11.3462C35.7777 10.9736 35.545 10.6021 35.1724 10.5164C34.7997 10.4308 34.4282 10.6635 34.3425 11.0362C34.2569 11.4088 34.4896 11.7804 34.8623 11.866C35.2349 11.9516 35.6065 11.7189 35.6921 11.3462Z"
        fill="#FFC100"
      />
      <path
        d="M35.1127 10.873C35.284 10.8982 35.4237 11.0054 35.4972 11.1493C35.4668 10.9266 35.2934 10.7406 35.0591 10.7049C34.7755 10.6629 34.5107 10.8583 34.4676 11.142C34.4508 11.2544 34.4718 11.3636 34.5202 11.4571C34.5139 11.4099 34.5139 11.3605 34.5202 11.3101C34.5622 11.0264 34.8269 10.8299 35.1117 10.873H35.1127Z"
        fill="#FFD845"
      />
      <path
        d="M43.987 20.129C43.1287 20.067 41.9961 19.1887 42.0539 18.5688C42.3008 18.095 42.6149 16.3342 43.0877 15.7133C43.5993 15.0419 44.1992 13.9356 44.3747 14.1888C44.5806 14.4872 44.3863 15.1764 44.1688 15.687C44.0921 15.8667 43.6676 16.601 43.7569 16.6861C44.0007 16.5769 44.381 16.1881 45.1007 15.6324C45.6995 14.9001 46 14.4231 46.7354 13.6236C47.3238 12.9838 47.8008 13.2674 47.4572 13.6908C46.6388 14.6984 46.3215 15.3708 45.7857 16.0705C46.3288 15.6355 47.2418 14.2361 47.8501 13.7003C48.2242 13.3704 48.7579 13.4534 48.3965 13.902C47.6075 14.8801 46.8752 16.0222 46.4003 16.5821C46.8426 16.3184 47.6369 15.2478 48.2347 14.815C48.7148 14.4683 48.8419 14.7456 48.5793 15.1165C48.1359 15.7427 47.0075 16.9288 46.581 17.3512C47.0569 17.0276 47.7997 16.186 48.1979 15.9118C48.4175 15.7605 48.8587 15.749 48.4385 16.2648C47.8564 16.9793 46.9372 17.9637 46.8741 18.0351C46.5894 18.3598 46.1376 18.717 45.8372 18.9534C45.3129 19.3652 44.2644 19.9483 43.986 20.1301L43.987 20.129Z"
        fill="#694139"
      />
      <path
        d="M42.2871 17.8945C42.202 18.1877 42.1253 18.4324 42.0539 18.569C41.9961 19.1889 43.1276 20.0672 43.987 20.1292C44.1992 19.9905 44.859 19.6186 45.3938 19.2666C44.2297 19.2404 42.9259 18.9367 42.2871 17.8945Z"
        fill="#533330"
      />
      <path
        d="M40.7262 30.321C41.6276 26.8066 43.0575 23.0023 43.9873 20.1279C43.1321 19.8274 42.2822 19.2884 42.0542 18.5677C41.0435 20.5334 39.4665 22.9792 38.978 24.0981C38.4191 20.7425 37.1604 18.2252 36.2516 17.3164C35.1789 17.9121 33.7344 27.1744 36.5311 30.7391C37.5502 32.0387 40.0265 33.0463 40.7262 30.3199V30.321Z"
        fill="#089188"
      />
      <path
        d="M42.6861 19.4267C42.3867 19.1809 42.1566 18.893 42.0547 18.5684C41.044 20.5341 39.4671 22.9799 38.9785 24.0988C39.0489 24.7975 39.1182 25.4972 39.1182 26.3356C39.2317 25.8618 41.6639 21.4103 42.6861 19.4257V19.4267Z"
        fill="#54909E"
      />
      <path
        d="M36.5322 30.7403C36.2821 30.422 36.0667 30.0574 35.8818 29.6582C36.0373 30.0186 36.2506 30.381 36.5322 30.7403Z"
        fill="#7D4884"
      />
      <path
        d="M28.6752 14.0352C28.0963 14.1297 27.9502 14.6119 27.1717 14.862C25.7649 15.6405 24.4811 16.7342 23.9474 18.5066C22.2611 24.1043 23.4693 25.1833 21.1475 30.1937C20.1473 32.3527 14.6494 45.0725 25.3415 47.4396C25.2754 45.0032 26.1348 42.1045 27.863 40.1787C28.9651 38.9516 30.7995 38.3612 33.4555 38.0113C34.7435 37.8422 35.6492 36.5352 37.2303 36.1234C35.7605 34.9824 34.2592 34.4108 34.0848 33.5367C33.6656 31.4397 35.4128 28.6429 36.3048 27.5745C39.9399 25.9134 37.4468 19.7074 36.2522 17.3173C36.1125 17.0378 35.9024 16.688 35.5378 16.2814C35.5504 21.7625 34.7351 22.7773 32.0571 18.2965C31.4625 17.3015 29.7499 15.4304 28.6752 14.0352Z"
        fill="#089188"
      />
      <path
        d="M23.9229 18.5889C23.9323 18.5584 23.9418 18.53 23.947 18.5059C23.9386 18.5342 23.9302 18.5615 23.9229 18.5889Z"
        fill="#9861A2"
      />
      <path
        d="M34.7131 26.8957C34.0838 28.853 29.8645 28.7112 30.0988 26.1267C30.3783 23.0505 29.749 19.9742 28.5607 18.5065C26.9533 18.1567 25.6946 18.1567 23.9464 18.5065C23.9401 18.5317 23.9317 18.5601 23.9222 18.5895C22.2738 24.1147 23.4568 25.2084 21.1465 30.1936C20.1463 32.3526 14.6484 45.0725 25.3406 47.4395C25.2744 45.0031 26.1338 42.1045 27.8621 40.1787C25.3448 39.48 24.7858 36.9627 28.3516 35.7745C29.3876 35.4288 29.8897 35.3553 34.0838 33.5377C33.6657 31.4449 35.4044 28.6566 36.2975 27.5828C36.2975 27.5765 36.2964 27.5713 36.2943 27.566C35.6219 27.7352 34.9926 27.525 34.7131 26.8957Z"
        fill="#B5EDE9"
      />
      <path
        d="M28.3521 31.5106C27.3036 31.2312 27.1639 29.9725 26.9537 28.924C26.2645 25.292 25.8569 21.8281 25.4776 18.2812C24.9985 18.3222 24.4974 18.3968 23.9479 18.5071C23.9416 18.5323 23.9332 18.5607 23.9238 18.5901C22.7166 22.6371 23.0276 24.3065 22.373 26.869C22.6199 28.2253 22.828 29.6185 23.1095 30.9517C23.389 32.2797 24.0183 34.1676 25.3463 34.5868C28.4792 35.6101 31.0533 34.1193 34.0254 33.0078C34.0002 32.3438 34.1505 31.6346 34.3921 30.9465C32.3812 31.5232 30.3451 32.1736 28.3521 31.5096V31.5106Z"
        fill="#089188"
      />
      <path
        d="M34.9937 22.3521C32.55 21.7333 30.4256 17.0612 27.1719 14.8622C27.4429 14.4525 27.9987 13.965 28.7016 13.9629C31.0087 15.7111 33.7109 20.0512 34.7143 20.6049C35.5159 20.6858 35.3467 17.8039 34.7143 15.7111C34.8719 15.7458 35.311 15.9937 35.5379 16.2837C35.974 17.5423 36.2923 22.1567 34.9937 22.3531V22.3521Z"
        fill="#B5EDE9"
      />
      <path
        d="M46.1937 30.3575C46.8283 30.2997 47.0269 29.9772 46.0403 29.7713C44.9446 29.5422 43.2205 29.5748 42.2749 28.9728C41.8515 28.7039 41.321 28.2784 41.5174 28.0409C41.6288 27.9075 41.9093 27.6795 42.0722 27.5986C42.6374 27.3202 42.9568 27.0996 43.0272 26.6825C43.0881 26.3284 42.754 26.4083 41.8694 26.7581C41.0142 27.0796 39.8659 27.5156 39.8028 27.5366C38.8426 27.8592 38.1586 28.1523 37.6911 28.2321C36.4345 29.1609 36.962 30.7137 38.1828 31.0194C38.6829 30.9932 40.0928 31.8641 41.1319 32.0165C41.7748 32.4314 43.1459 32.9589 43.7195 33.045C44.4602 33.1564 44.5106 32.7645 44.0977 32.5691C43.5283 32.2991 42.5996 32.1383 41.8683 31.4985C42.442 31.7601 43.8193 32.236 44.4812 32.38C45.4299 32.5859 45.4972 32.1803 44.8279 31.903C43.9244 31.529 43.0083 31.2306 42.4819 30.8849C43.4653 31.2411 44.8468 31.6288 45.8933 31.5258C46.5657 31.4596 46.676 31.0005 46.0992 30.928C45.2545 30.8219 43.6996 30.533 43.0072 30.1243C43.9885 30.2777 45.0654 30.4605 46.1906 30.3586L46.1937 30.3575Z"
        fill="#694139"
      />
      <path
        d="M38.6902 27.9297C38.2826 28.0757 37.9537 28.1871 37.6911 28.2312C36.4345 29.16 36.962 30.7128 38.1828 31.0185C38.3939 31.007 38.769 31.1572 39.2103 31.3452C38.4864 30.3871 38.1176 29.0318 38.6902 27.9297Z"
        fill="#533330"
      />
      <path
        d="M24.5774 31.4395C23.5247 27.4934 22.9216 22.193 23.9481 18.5064C24.7287 15.7054 27.9478 16.0836 28.5624 18.5064C29.4649 22.0648 28.7536 27.5606 29.1759 29.5484C31.5199 29.146 33.8512 29.0861 37.6922 28.2319C37.5252 29.5158 37.6975 30.2628 38.1839 31.0192C35.8085 31.7767 31.5346 33.7676 28.1621 33.8569C25.7005 33.9231 24.9756 32.9261 24.5784 31.4395H24.5774Z"
        fill="#B5EDE9"
      />
    </svg>
  )
}
