import React from 'react'
import { Chip, Stack } from '@mui/material'

interface ChipTabsProps {
  activeTab: string
  tabs: { value: string; label: string; onClick: (newTab: string) => void }[]
}

export const ChipTabs: React.FC<ChipTabsProps> = ({ activeTab, tabs }) => {
  const getChipVariant = (tab: string) =>
    activeTab === tab ? 'filled' : 'outlined'

  return (
    <Stack direction="row" spacing={2} mb={2}>
      {tabs.map(({ value, label, onClick }) => (
        <Chip
          key={value}
          variant={getChipVariant(value)}
          label={label}
          onClick={() => onClick(value)}
        />
      ))}
    </Stack>
  )
}
