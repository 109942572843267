import React, { PropsWithChildren, Children } from 'react'
import { Grid2 as Grid, Grid2Props as GridProps } from '@mui/material'

interface ResponsiveWrapperProps extends GridProps, PropsWithChildren {
  containterProps?: GridProps
}

export const ResponsiveWrapper: React.FC<ResponsiveWrapperProps> = ({
  children,
  containterProps,
  ...itemProps
}) => (
  <Grid container spacing={2} {...containterProps}>
    {Children.map(children, (child) => (
      <Grid {...itemProps}>{child}</Grid>
    ))}
  </Grid>
)
