import { useDeleteRecruitmentMutation } from '@local/src/Api/recruitmentApi'
import { RecruitmentResponse } from '@local/src/Api/types'
import { ConfirmationDialog } from '@local/src/Components/ConfirmationDialog/ConfirmationDialog'
import { RecruitmentListItem } from '@local/src/Pages/StartPage/CreatedRecruitments/RecruitmentList/RecruitmentListItem'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'
import { useContent } from '@local/src/Utils/Content/useContent'
import { useResolve } from '@local/src/Utils/Hooks/useResolve'
import { DeepPartial } from '@local/src/Utils/types'
import { Delete } from '@mui/icons-material'
import { IconButton, TableCell, Typography } from '@mui/material'
import { useDevice } from '@trr/app-shell-data'
import React from 'react'

interface RecruitmentDraftItemProps {
  recruitment: DeepPartial<RecruitmentResponse>
}

const RecruitmentDraftItem: React.FC<RecruitmentDraftItemProps> = ({
  recruitment,
}) => {
  const { heading, jobTitle, locations: location } = recruitment.position ?? {}

  const companyName = recruitment.company?.name

  const {
    skapadeAnnonser: {
      lastChangedDate,
      missingInformation,
      deleteTooltip,
      deleteDialogCancelLabel,
      deleteDialogConfirmLabel,
      deleteDialogTitle,
      deleteDialogMessage,
    },
    links: { createRecruitmentPagePath },
  } = useContent()

  const [deleteRecruitment] = useDeleteRecruitmentMutation()

  const { isMobile } = useDevice()

  const {
    isPending: isPendingConfirmation,
    resolve: resolveConfirmation,
    promise: confirm,
  } = useResolve<boolean>()

  const secondaryText = [jobTitle?.name, companyName, location?.[0]?.name]
    .filter(Boolean)
    .join(', ')

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    void (async () => {
      const isConfirmed = await confirm()
      isConfirmed && void deleteRecruitment(recruitment.id)
    })()
  }

  const lastChangedDateText = recruitment.metadata?.lastModifiedWhen
    ? replaceEpiVariables(lastChangedDate, {
        date: new Date(
          recruitment.metadata.lastModifiedWhen
        ).toLocaleDateString('sv-SE'),
      })
    : null

  return (
    <>
      <RecruitmentListItem
        primaryText={heading || <i>{missingInformation}</i>}
        secondaryText={secondaryText}
        tertiaryText={isMobile ? lastChangedDateText : undefined}
        to={`${createRecruitmentPagePath}${recruitment.id}`}
      >
        {!isMobile && (
          <TableCell
            sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}
            component="div"
          >
            <Typography variant="body2">{lastChangedDateText}</Typography>
          </TableCell>
        )}

        <TableCell
          sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}
          component="div"
        >
          <IconButton onClick={handleDelete} title={deleteTooltip}>
            <Delete />
          </IconButton>
        </TableCell>
      </RecruitmentListItem>
      <ConfirmationDialog
        isOpen={isPendingConfirmation}
        title={deleteDialogTitle}
        message={deleteDialogMessage}
        confirmLabel={deleteDialogConfirmLabel}
        cancelLabel={deleteDialogCancelLabel}
        onConfirm={() => resolveConfirmation(true)}
        onClose={() => resolveConfirmation(false)}
      />
    </>
  )
}

export default RecruitmentDraftItem
