import React, { PropsWithChildren } from 'react'

interface AppShellRouterProps extends PropsWithChildren {
  currentKey: string
}

export const AppShellRouter: React.FunctionComponent<AppShellRouterProps> = ({
  children,
  currentKey,
}) =>
  (React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.props.urlKey === currentKey
  ) as React.ReactElement) ?? null
