import React from 'react'
import { ConfirmationDialog } from '@local/src/Components/ConfirmationDialog/ConfirmationDialog'
import { useContent } from '@local/src/Utils/Content/useContent'
import { useCloseRecruitmentMutation } from '@local/src/Api/recruitmentApi'
import { ClosureReason, RecruitmentResponse } from '@local/src/Api/types'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from '@local/src/Components/InfoSnackbar/Hooks/useSnackbar'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'
import { useHotjar } from '@local/src/Utils/Hooks/useHotjar'

interface CloseRecruitmentDialogProps {
  isOpen: boolean
  recruitment: RecruitmentResponse
  onConfirm: () => void
  onCancel: () => void
}

export const CloseRecruitmentDialog: React.FC<CloseRecruitmentDialogProps> = ({
  isOpen,
  recruitment,
  onCancel,
  onConfirm,
}) => {
  const history = useHistory()
  const [closeRecruitment] = useCloseRecruitmentMutation()
  const { showSnackbar } = useSnackbar()
  const {sendRecruitmentClosedEvent} = useHotjar()
  const {
    description,
    confirmButton,
    cancelButton,
    title,
    confirmSnackbarMessage,
    onCloseUrl,
  } = useContent().manageCandidate.dialogCloseRecruitment

  const handleCloseRecruitment = () => {
    void closeRecruitment({
      id: recruitment.id,
      reason: ClosureReason.CandidateHired,
      applicationsToHire: null,
    })
      .then(() => {
        showSnackbar(
          replaceEpiVariables(confirmSnackbarMessage, {
            title: recruitment.position.heading,
          })
        )
        sendRecruitmentClosedEvent()
        onConfirm()
        history.push(onCloseUrl)
      })
      .catch(console.error)
  }

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={title}
      onClose={onCancel}
      onConfirm={handleCloseRecruitment}
      confirmLabel={confirmButton}
      cancelLabel={cancelButton}
      showCloseButton
    >
      {description}
    </ConfirmationDialog>
  )
}
