import { useGetRecruitmentQuery } from '@local/src/Api/recruitmentApi'
import { useParams } from 'react-router-dom'

export const useCurrentRecruitmentId = () =>
  useParams<{ recruitmentId: string }>()?.recruitmentId

export const useCurrentRecruitment = () => {
  const recruitmentId = useCurrentRecruitmentId()
  return useGetRecruitmentQuery(recruitmentId, { skip: !recruitmentId })
}
