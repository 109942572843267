import { RecruitmentResponse } from '@local/src/Api/types'

export enum ExpirationType {
  Manual = 'manual',
  Auto = 'auto',
}

export interface PublicationForm {
  id: string
  endDateRadio: ExpirationType
  endDate?: Date
}

export const mapRecruitmentResponseToPublicationForm = (
  res: RecruitmentResponse
): PublicationForm => ({
  id: res?.id,
  endDate: res?.publication?.endDate
    ? new Date(res.publication.endDate)
    : undefined,
  endDateRadio: res?.publication?.endDate
    ? ExpirationType.Auto
    : ExpirationType.Manual,
})
