import React from 'react'
import { useRecruitmentForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/useRecruitmentForm'
import { TextField } from '@mui/material'
import { useContent } from '@local/src/Utils/Content/useContent'

export const ContactNameField: React.FC = () => {
  const { nameLabel, namePlaceholder } = useContent().form.contact

  const { values, errors, handleChange, handleBlur } = useRecruitmentForm()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange('contactInfo')(
      values.id,
      { ...values.contactInfo, name: event.target.value },
      {
        validationType: 'onError',
      }
    )
  }

  return (
    <TextField
      fullWidth
      value={values?.contactInfo?.name}
      label={nameLabel}
      placeholder={namePlaceholder}
      onChange={handleInputChange}
      onBlur={() => handleBlur('contactInfo')(values.id)}
      error={!!errors.contactInfo?.name}
      helperText={!!errors.contactInfo?.name && errors.contactInfo?.name}
    />
  )
}
