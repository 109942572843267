import { Paper, styled } from '@mui/material'
import { textEditorStyles } from '@local/src/Components/TextEditor/styles'

const TextEditorWrapper = styled(Paper)(({ theme }) => ({
  ...textEditorStyles(theme),
  borderColor: 'rgba(0, 0, 0, 0.23)',
  ':focus-within': {
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    // -1px to compensate for the 2px border
    margin: '-1px',
  },
  ':hover:not(:focus-within):not(.error)': {
    borderColor: theme.palette.grey[800],
  },
  '.ProseMirror': {
    minHeight: 150,
    maxHeight: 200,
    overflowY: 'auto',
    '&:focus': {
      outline: 'none',
      maxHeight: 600,
    },
  },
  '.tiptap .is-editor-empty:first-of-type::before': {
    color: theme.palette.text.disabled,
    content: 'attr(data-placeholder)',
    float: 'left',
    height: 0,
    pointerEvents: 'none',
  },
  '&.error': {
    borderColor: theme.palette.error.main,
  },
}))

export default TextEditorWrapper
