import React from 'react'
import { useContent } from '@local/src/Utils/Content/useContent'
import { ClosedRecruitmentListItem } from '@local/src/Pages/StartPage/CreatedRecruitments/ClosedRecruitments/ClosedRecruitmentListItem/ClosedRecruitmentListItem'
import { RecruitmentsList } from '@local/src/Pages/StartPage/CreatedRecruitments/RecruitmentList/RecruitmentsList'
import { RecruitmentStatus } from '@local/src/Api/types'

export const ClosedRecruitments: React.FC = () => {
  const {
    closedRecruitments: { closedTabEmptyText },
  } = useContent()

  return (
    <RecruitmentsList
      status={RecruitmentStatus.Closed}
      emptyText={closedTabEmptyText}
      renderListItem={(recruitment) => (
        <ClosedRecruitmentListItem
          key={recruitment.id}
          recruitment={recruitment}
        />
      )}
    />
  )
}
