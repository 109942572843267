import { useContent } from '@local/src/Utils/Content/useContent'
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from '@mui/material'
import React from 'react'

export const OnboardingContent: React.FC = () => {
  const {
    listItem1Heading,
    listItem1Text,
    listItem2Heading,
    listItem2Text,
    listItem3Heading,
    listItem3Text,
    listItem4Heading,
    listItem4Text,
    listItem5Heading,
    listItem5Text,
    listItem6Heading,
    listItem6Text,
  } = useContent().onboarding

  const listContent = [
    { heading: listItem1Heading, text: listItem1Text },
    { heading: listItem2Heading, text: listItem2Text },
    { heading: listItem3Heading, text: listItem3Text },
    { heading: listItem4Heading, text: listItem4Text },
    { heading: listItem5Heading, text: listItem5Text },
    { heading: listItem6Heading, text: listItem6Text },
  ]

  return (
    <Stack component={List} spacing={2}>
      {listContent
        .filter((c) => c.heading && c.text)
        .map(({ heading, text }, index) => (
          <ListItem key={heading} alignItems="flex-start">
            <ListItemAvatar sx={{ mt: 1 }}>
              <Avatar
                sx={{
                  bgcolor: (t) => t.palette.surface?.grey,
                  color: 'text.secondary',
                  fontSize: (t) => t.typography.body1.fontSize,
                }}
              >
                {index + 1}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={heading} secondary={text} />
          </ListItem>
        ))}
    </Stack>
  )
}
