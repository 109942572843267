import React from 'react'
import { OnboardingContent } from '@local/src/Pages/StartPage/OnboardingCard/Components/OnboardingContent'
import { useContent } from '@local/src/Utils/Content/useContent'
import { setClosedOnboardingCardCookie } from '@local/src/Utils/cookie'
import { Close } from '@mui/icons-material'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
} from '@mui/material'

interface OnboardingCardProps {
  isOpen: boolean
  onClose?: () => void
}

const OnboardingCard: React.FC<OnboardingCardProps> = ({
  isOpen,
  onClose = () => {},
}) => {
  const { title } = useContent().onboarding

  const handleClose = () => {
    setClosedOnboardingCardCookie()
    onClose()
  }

  return (
    <Box>
      <Collapse in={isOpen} unmountOnExit>
        <Card>
          <CardHeader
            title={title}
            action={
              <IconButton
                onClick={handleClose}
                data-testid="onboarding-card-close-button"
              >
                <Close />
              </IconButton>
            }
          />
          <CardContent>
            <OnboardingContent />
          </CardContent>
        </Card>
      </Collapse>
    </Box>
  )
}

export default OnboardingCard
