import { object, string } from 'yup'
import { ContactInfo } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { CommonFormContent } from '@local/src/Utils/Content/Content.types'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'

export interface ContactInfoForm extends ContactInfo {
  contactInfo: string
}

export const contactInfoSchema = (form: CommonFormContent) =>
  object<ContactInfoForm>()
    .shape({
      name: string()
        .max(50, replaceEpiVariables(form.maxChars, { count: '50' }))
        .nullable(),
      email: string()
        .transform((v) => v || null)
        .email(form.invalidEmail)
        .min(5, form.invalidEmail)
        .max(100, replaceEpiVariables(form.maxChars, { count: '100' }))
        .nullable(),
      phone: string()
        .transform((v) => v || null)
        .min(8, form.invalidPhone)
        .matches(/^[0-9+\-\s]*$/, form.invalidPhone)
        .max(20, replaceEpiVariables(form.maxChars, { count: '20' }))
        .nullable(),
    })
    .test(
      'not-only-name',
      form.invalidPhoneNameRequired,
      ({ name, email, phone }, ctx) =>
        !(name && !email && !phone)
          ? true
          : ctx.createError({
              message: form.invalidPhoneNameRequired,
              path: 'contactInfo',
            })
    )
