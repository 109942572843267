import React from 'react'
import { ConfirmationDialog } from '@local/src/Components/ConfirmationDialog/ConfirmationDialog'
import { OnboardingContent } from '@local/src/Pages/StartPage/OnboardingCard/Components/OnboardingContent'
import { useContent } from '@local/src/Utils/Content/useContent'

interface OnboardingDialogProps {
  isOpen: boolean
  onClose: () => void
}

export const OnboardingDialog: React.FC<OnboardingDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const { title } = useContent().onboarding

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      cancelLabel="Stäng"
      onConfirm={null}
    >
      <OnboardingContent />
    </ConfirmationDialog>
  )
}
