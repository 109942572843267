import { ActiveApplicants } from '@local/src/Pages/DetailsPage/Applicants/ActiveApplicants/ActiveApplicants'
import { RejectedApplicants } from '@local/src/Pages/DetailsPage/Applicants/RejectedApplicants/RejectedApplicants'
import { useContent } from '@local/src/Utils/Content/useContent'
import { useQuery } from '@local/src/Utils/Hooks/useQuery'
import {
  TAB_QUERY_KEY,
  getTabSearchLocation,
} from '@local/src/Utils/urlHelpers'
import { Box, Stack, Tab, Tabs } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'

export enum ApplicantsTabs {
  Active = 'aktiva',
  NotRelevant = 'ej-aktuella',
}

export const Applicants: React.FC = () => {
  const query = useQuery<typeof TAB_QUERY_KEY, ApplicantsTabs>()
  const tab = query.get(TAB_QUERY_KEY) ?? ApplicantsTabs.Active

  const content = useContent().detailsPage.applicants
  const history = useHistory()

  const handleChangeTab = (_event: unknown, value: ApplicantsTabs) => {
    history.replace(getTabSearchLocation(value), { preventScroll: true })
  }

  return (
    <Stack spacing={3}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} variant="scrollable" onChange={handleChangeTab}>
          <Tab label={content.activeTab} value={ApplicantsTabs.Active} />
          <Tab
            label={content.notRelevantTab}
            value={ApplicantsTabs.NotRelevant}
          />
        </Tabs>
      </Box>
      <Box>
        {tab === ApplicantsTabs.Active && <ActiveApplicants />}
        {tab === ApplicantsTabs.NotRelevant && <RejectedApplicants />}
      </Box>
    </Stack>
  )
}
