import React, { PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import {
  Box,
  Collapse,
  IconButton,
  IconButtonProps,
  Stack,
  Typography,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

interface ExpandProps extends IconButtonProps {
  expand: boolean
}

const Expand = styled((props: ExpandProps) => {
  const { ...other } = props
  return <IconButton {...other} />
})(({ expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: '.1s',
}))

interface TitleAccordionProps extends PropsWithChildren {
  title: string
}

export const TitleAccordion: React.FC<TitleAccordionProps> = ({
  title,
  children,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const handleExpandClick = () => setIsExpanded(!isExpanded)

  return (
    <Box>
      <Stack
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        onClick={handleExpandClick}
      >
        <Typography variant="h6">{title}</Typography>
        <Expand
          expand={isExpanded}
          aria-expanded={isExpanded}
          aria-label="visa mer"
        >
          <ExpandMore />
        </Expand>
      </Stack>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Box>
  )
}
