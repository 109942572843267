import React from 'react'
import {
  RecruitmentApplication,
  RecruitmentResponse,
} from '@local/src/Api/types'
import { CandidateHistoryLog } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/CandidateHistoryLog'
import { SkillsChecklist } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/SkillsChecklist'
import { StackDivider } from '@local/src/Components/StackDivider'
import { Motivation } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/Motivation'
import { ContactInformation } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/ContactInformation'

interface RejectedCandidateProps {
  application: RecruitmentApplication
  recruitment: RecruitmentResponse
}

export const RejectedCandidate: React.FC<RejectedCandidateProps> = ({
  application,
  recruitment,
}) => (
  <StackDivider data-testid="rejected-candidate-wrapper">
    <CandidateHistoryLog historyLog={application?.history} />

    <Motivation text={application?.motivation} />

    <SkillsChecklist
      applicationSkills={application?.skills}
      recruitmentSkills={recruitment.position?.skills.map((x) => x.name)}
    />

    {(application?.person.phone || application?.person.email) && (
      <ContactInformation person={application.person} />
    )}
  </StackDivider>
)
