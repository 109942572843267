import { baseApi } from '@local/src/Api/baseApi'

export interface GetJobTitlesResponse {
  id: string
  jobTitle: string
  jobTitleWithHighLightedSearchTerm: string
}

export const jobTitleApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getJobTitles: builder.query<GetJobTitlesResponse[], string>({
      query: (searchText) => ({
        url: `/referencedata/jobtitles/v2/suggestions/${searchText}`,
      }),
    }),
  }),
})

export const { useGetJobTitlesQuery } = jobTitleApi
