import React, { useState } from 'react'
import { ResponsiveWrapper } from '@local/src/Components/ResponsiveWrapper/ResponsiveWrapper'
import { usePublicationForm } from '@local/src/Pages/DraftPage/PublicationFormView/Form/usePublicationForm'
import { useContent } from '@local/src/Utils/Content/useContent'
import { getTomorrowMidnightLocalTime } from '@local/src/Utils/dateHelpers'
import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { ExpirationType } from '@local/src/Pages/DraftPage/PublicationFormView/Form/types'

export const PublicationEndDateField: React.FC = () => {
  const tomorrow = getTomorrowMidnightLocalTime()

  const { handleChange, values, errors } = usePublicationForm()
  const [tempEndDate, setTempEndDate] = useState(values.endDate ?? tomorrow)
  const endDateRadio = values?.endDateRadio

  const {
    title,
    description,
    manualExpirationLabel,
    automaticExpirationLabel,
    datePickerLabel,
  } = useContent().publish

  const handleChangeEndDate = handleChange('endDate')
  const handleChangeEndDateRadio = handleChange('endDateRadio')

  const handleChangeExpirationType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = (event.target as HTMLInputElement).value as ExpirationType
    handleChangeEndDateRadio(values.id, value)
    handleChangeEndDate(
      values.id,
      value === ExpirationType.Auto ? tempEndDate : undefined
    )
  }

  const handleDatePickerChange = (value: Date) => {
    setTempEndDate(value)
    handleChangeEndDate(values.id, value)
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body1">{description}</Typography>
      <RadioGroup value={endDateRadio} onChange={handleChangeExpirationType}>
        <FormControlLabel
          value={ExpirationType.Manual}
          control={<Radio checked={endDateRadio === ExpirationType.Manual} />}
          label={manualExpirationLabel}
          data-testid="manual-expiration-radio"
        />
        <FormControlLabel
          value={ExpirationType.Auto}
          control={<Radio checked={endDateRadio === ExpirationType.Auto} />}
          label={automaticExpirationLabel}
          data-testid="automatic-expiration-radio"
        />
      </RadioGroup>
      {Boolean(errors?.endDateRadio) && (
        <FormHelperText error>{errors.endDateRadio}</FormHelperText>
      )}

      {endDateRadio === ExpirationType.Auto && (
        <ResponsiveWrapper size={{ xs: 12, sm: 6 }}>
          <DatePicker
            label={datePickerLabel}
            minDate={tomorrow}
            onChange={handleDatePickerChange}
            value={tempEndDate}
            slotProps={{
              textField: {
                error: Boolean(errors.endDate),
                helperText: errors.endDate as string | undefined,
                name: 'endDate',
              },
              layout: {
                sx: { display: 'flex', flexDirection: 'column' },
              },
            }}
          />
        </ResponsiveWrapper>
      )}
    </Stack>
  )
}
