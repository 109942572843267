import {
  ApplicationStatus,
  EmploymentType,
  RecruitmentResponse,
  WorkingHours,
} from '@local/src/Api/types'

export interface ContactInfo {
  name: string
  email: string
  phone: string
}

export interface RecruitmentForm {
  id: string
  jobHeading: string
  locations: LocationInformation[]
  skills: Skill[]
  employingCompanyName: string
  showEmployingCompanyNameInJobAd: boolean
  employingCompanyType: EmployingCompanyType
  employmentDescription: FormattedText
  companyDescription: FormattedText
  companyName: string
  isRecruitingForDeploymentAgency: boolean
  jobTitle: {
    id?: string
    name: string
  }
  workingHours?: WorkingHours
  employmentType?: EmploymentType
  contactInfo?: ContactInfo
}

export interface ApplicantStatusForm {
  status: ApplicationStatus
}

export interface ApplicantHiredForm {
  endRecruitmentProcess: boolean
}

export enum EmployingCompanyType {
  AnotherCompany = 'another-company',
  MyOwnCompany = 'my-own-company',
}

export type LocationType = 'City' | 'Municipality' | 'County' | 'Country'

export interface FormattedText {
  html: string
  text: string
}

export enum CompanyType {
  EmployingCompany = 'EmployingCompany',
  StaffingCompany = 'StaffingCompany',
}

export interface LocationInformation {
  id: string
  name: string
  nameWithHighLightedSearchTerm?: string
  type: LocationType
  locatedIn?: LocationInformation
}

export interface Skill {
  title?: string
  name: string
}

export const mapRecruitmentResponseToForm = (
  res: RecruitmentResponse
): RecruitmentForm => ({
  id: res?.id,
  jobHeading: res?.position?.heading ?? '',
  locations:
    res?.position?.locations?.map((l) => ({
      id: l.id,
      type: l.locationType as LocationType,
      name: l.name,
    })) ?? [],
  skills: res?.position?.skills ?? [],
  employingCompanyName: res?.recruitingForCompany?.name ?? '',
  showEmployingCompanyNameInJobAd: res?.recruitingForCompany?.showName ?? true,
  employingCompanyType: res?.recruitingForCompany
    ? EmployingCompanyType.AnotherCompany
    : EmployingCompanyType.MyOwnCompany,
  employmentDescription: {
    html: res?.position?.descriptionFormatted ?? '',
    text: res?.position?.description ?? '',
  },
  companyName: res?.company?.name ?? '',
  companyDescription: {
    html: res?.company?.descriptionFormatted ?? '',
    text: res?.company?.description ?? '',
  },
  isRecruitingForDeploymentAgency:
    res?.company?.type === CompanyType.StaffingCompany,
  jobTitle: {
    id: res?.position?.jobTitle?.id,
    name: res?.position?.jobTitle?.name ?? '',
  },
  workingHours: res?.position?.workingHours[0] ?? undefined,
  employmentType: res?.position?.employmentType,
  contactInfo: res?.contacts?.[0],
})
