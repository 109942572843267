import React from 'react'
import RecruitmentFormView from '@local/src/Pages/DraftPage/RecruitmentFormView/RecruitmentFormView'
import EmptyResult from '@local/src/Components/EmptyResult/EmptyResult'
import { Wizard } from '@local/src/Components/Wizard/Wizard'
import { WizardContent } from '@local/src/Components/Wizard/WizardContent'
import { useContent } from '@local/src/Utils/Content/useContent'
import PublicationFormView from '@local/src/Pages/DraftPage/PublicationFormView/PublicationFormView'
import PublishView from '@local/src/Pages/DraftPage/PublishView/PublishView'
import { WizardTracker } from '@local/src/Components/Wizard/WizardTracker'
import { ResponsiveWrapper } from '@local/src/Components/ResponsiveWrapper/ResponsiveWrapper'
import { PublishingStatus } from '@local/src/Api/types'
import {
  useCurrentRecruitment,
  useCurrentRecruitmentId,
} from '@local/src/Utils/Hooks/useCurrentRecruitment'
import { LeaveDraftPagePrompt } from '@local/src/Pages/DraftPage/LeaveDraftPagePrompt'

const DraftPage = () => {
  const recruitmentId = useCurrentRecruitmentId()
  const { errorMessage } = useContent()

  const {
    data: recruitment,
    isFetching,
    isError,
    refetch,
  } = useCurrentRecruitment()

  const handleStepChange = () => {
    void refetch()
  }

  return (
    <>
      <ResponsiveWrapper size={{ xs: 12, md: 8 }}>
        <Wizard
          labels={['Om tjänsten', 'Annonseringstid', 'Publicera']}
          onStepChange={handleStepChange}
          isLoading={isFetching}
          isError={
            !recruitmentId ||
            isError ||
            !recruitment ||
            recruitment.publication.status !== PublishingStatus.Draft
          }
          errorComponent={
            <EmptyResult
              heading={errorMessage.heading}
              description={errorMessage.description}
              action={{
                label: errorMessage.actionLabel,
                link: errorMessage.startPagePath,
              }}
            />
          }
        >
          <WizardTracker>
            <WizardContent step={0}>
              <RecruitmentFormView recruitment={recruitment} />
            </WizardContent>
            <WizardContent step={1}>
              <PublicationFormView recruitment={recruitment} />
            </WizardContent>
            <WizardContent step={2}>
              <PublishView recruitment={recruitment} />
            </WizardContent>
          </WizardTracker>
        </Wizard>
      </ResponsiveWrapper>
      <LeaveDraftPagePrompt />
    </>
  )
}

export default DraftPage
