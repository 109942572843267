import React from 'react'
import { Button, Stack, Typography } from '@mui/material'
import {
  RecruitmentApplication,
  RecruitmentResponse,
} from '@local/src/Api/types'
import { useContent } from '@local/src/Utils/Content/useContent'
import { CandidateHistoryLog } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/CandidateHistoryLog'
import { SkillsChecklist } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/SkillsChecklist'
import { StackDivider } from '@local/src/Components/StackDivider'
import { Motivation } from '@local/src/Pages/DetailsPage/CandidateDrawer/Components/Motivation'
import { CloseRecruitmentDialog } from '@local/src/Pages/DetailsPage/CandidateDrawer/Dialogs/CloseRecruitmentDialog/CloseRecruitmentDialog'
import { ButtonStack } from '@local/src/Components/ButtonStack/ButtonStack'
import { useResolve } from '@local/src/Utils/Hooks/useResolve'

interface HiredCandidateProps {
  application: RecruitmentApplication
  recruitment: RecruitmentResponse
  onClose: () => void
}

export const HiredCandidate: React.FC<HiredCandidateProps> = ({
  application,
  recruitment,
  onClose,
}) => {
  const { title, description, buttonText } =
    useContent().manageCandidate.actionEndRecruitmentProcess

  const {
    isPending: isPendingConfirmation,
    resolve: resolveConfirmation,
    promise: confirm,
  } = useResolve<boolean>()

  const handleCloseRecruitment = async () => {
    const confirmed = await confirm()

    return confirmed ? onClose() : null
  }

  return (
    <>
      <StackDivider data-testid="hired-candidate-wrapper">
        <Stack>
          <Typography variant="h6" mb={2}>
            {title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {description}
          </Typography>
          <ButtonStack>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => void handleCloseRecruitment()}
            >
              {buttonText}
            </Button>
          </ButtonStack>
        </Stack>

        <CandidateHistoryLog historyLog={application?.history} />

        <Motivation text={application?.motivation} />

        <SkillsChecklist
          applicationSkills={application?.skills}
          recruitmentSkills={recruitment.position?.skills.map((x) => x.name)}
        />
      </StackDivider>
      <CloseRecruitmentDialog
        recruitment={recruitment}
        isOpen={isPendingConfirmation}
        onCancel={() => resolveConfirmation(false)}
        onConfirm={() => resolveConfirmation(true)}
      />
    </>
  )
}
