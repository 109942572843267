import { ApplicationStatus, RecruitmentApplication } from '@local/src/Api/types'

export const sortByStatusAndAlphabetical = (
  applications: RecruitmentApplication[]
) =>
  applications.slice().sort((a, b) => {
    if (
      a.status === ApplicationStatus.Hired &&
      b.status !== ApplicationStatus.Hired
    ) {
      return -1
    }
    if (
      a.status !== ApplicationStatus.Hired &&
      b.status === ApplicationStatus.Hired
    ) {
      return 1
    }

    if (a.person.name < b.person.name) {
      return -1
    }
    if (a.person.name > b.person.name) {
      return 1
    }
    return 0
  })
