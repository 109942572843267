import React, { PropsWithChildren } from 'react'
import { Box, Stack, Typography } from '@mui/material'

interface LabelWrapperProps extends PropsWithChildren {
  label?: string
  description?: string
  htmlFor?: string
  gutterBottom?: boolean
}

const LabelWrapper: React.FC<LabelWrapperProps> = ({
  children,
  label,
  description,
  htmlFor,
  gutterBottom = true,
}) => (
  <Box mb={gutterBottom ? 3 : 0}>
    {(label || description) && (
      <Stack spacing={1} mb={2}>
        {label && (
          <Typography variant="h6" component="label" htmlFor={htmlFor}>
            {label}
          </Typography>
        )}
        {description && <Typography variant="body1">{description}</Typography>}
      </Stack>
    )}
    {children}
  </Box>
)

export default LabelWrapper
