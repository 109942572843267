import React from 'react'
import { Avatar } from '@mui/material'

interface ApplicantListIconProps {
  score: number
  disableColor?: boolean
}

export const ApplicantListIcon: React.FC<ApplicantListIconProps> = ({
  score,
  disableColor = false,
}) => {
  const getColorSchema = (): { bgcolor: string; color: string } => {
    if (disableColor) {
      return {
        bgcolor: 'surface.grey',
        color: 'text.secondary',
      }
    }
    return {
      bgcolor: score > 50 ? 'surface.green' : 'surface.blue',
      color: score > 50 ? 'success.dark' : 'info.dark',
    }
  }

  const colorSchema = getColorSchema()

  return (
    <Avatar
      sx={{
        bgcolor: colorSchema.bgcolor,
        color: colorSchema.color,
        fontSize: (t) => t.typography.subtitle2.fontSize,
        fontWeight: (t) => t.typography.subtitle2.fontWeight,
      }}
    >
      {`${score}%`}
    </Avatar>
  )
}
