import React from 'react'
import { ConfirmationDialog } from '@local/src/Components/ConfirmationDialog/ConfirmationDialog'
import { useContent } from '@local/src/Utils/Content/useContent'
import { useFeatureFlag } from '@local/src/Utils/Hooks/useFeatureFlag'
import { usePageLeave } from '@local/src/Utils/Hooks/usePageLeave'

export const LeaveDraftPagePrompt: React.FC = () => {
  const { isLeavePagePromptEnabled } = useFeatureFlag()

  const { isAwaitingConfirmation, confirmPageLeave, cancelPageLeave } =
    usePageLeave(isLeavePagePromptEnabled)

  const {
    pageLeavePrompt: {
      title: pageLeaveHeading,
      body: pageLeaveMessage,
      confirmButtonLabel: pageLeaveConfirmLabel,
      cancelButtonLabel: pageLeaveCancelLabel,
    },
  } = useContent()

  return (
    <ConfirmationDialog
      isOpen={isAwaitingConfirmation}
      title={pageLeaveHeading}
      message={pageLeaveMessage}
      confirmLabel={pageLeaveConfirmLabel}
      cancelLabel={pageLeaveCancelLabel}
      onConfirm={confirmPageLeave}
      onClose={cancelPageLeave}
    />
  )
}
