import { baseApi } from '@local/src/Api/baseApi'
import {
  ApplicationStatus,
  CandidateHistoryEvent,
  GetRecruitmentApplicationsRequest,
  GetRecruitmentApplicationsResponse,
  Person,
} from '@local/src/Api/types'

export interface GetApplicationResponse {
  id: string
  person: Person
  skills: string[]
  motivation: string
  status: ApplicationStatus
  createdWhen: string
  lastModifiedWhen: string
  history: CandidateHistoryEvent[]
  isFavourite?: boolean
}

export interface GetApplicationRequest {
  applicationId: string
}

export interface SetApplicationStatusRequest {
  applicationId: string
  status: ApplicationStatus
}

export const applicationsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecruitmentApplications: builder.query<
      GetRecruitmentApplicationsResponse,
      GetRecruitmentApplicationsRequest
    >({
      query: ({ recruitmentId, ...params }) => ({
        url: `/recruitment/recruitments/${recruitmentId}/applications`,
        method: 'POST',
        body: params,
      }),
      providesTags: (res) => [
        'AllApplications',
        ...(res?.applications.map((app) => ({
          type: 'Application' as const,
          id: app.id,
        })) ?? []),
      ],
    }),
    getApplication: builder.query<
      GetApplicationResponse,
      GetApplicationRequest
    >({
      query: ({ applicationId }) => ({
        url: `/recruitment/applications/${applicationId}`,
        method: 'GET',
      }),
      providesTags: (res, err, req) => [
        'Application',
        { type: 'Application', id: req.applicationId },
      ],
    }),
    setStatus: builder.mutation<void, SetApplicationStatusRequest>({
      query: ({ applicationId, status }) => ({
        url: `/recruitment/applications/${applicationId}/status`,
        body: { status },
        method: 'PUT',
      }),
      invalidatesTags: (res, err, req) => [
        'AllApplications',
        { type: 'Application', id: req.applicationId },
      ],
    }),
    favoriteApplication: builder.mutation<
      void,
      { applicationId: string; value: boolean }
    >({
      query: ({ applicationId, value }) => ({
        url: `/recruitment/applications/${applicationId}/favourite`,
        method: value ? 'PUT' : 'DELETE',
      }),
      invalidatesTags: (res, err, req) => [
        'AllApplications',
        { type: 'Application', id: req.applicationId },
      ],
    }),
  }),
})

export const {
  useGetRecruitmentApplicationsQuery,
  useGetApplicationQuery,
  useSetStatusMutation,
  useFavoriteApplicationMutation,
} = applicationsApi
