import React, { PropsWithChildren, useContext } from 'react'
import { WizardContext } from '@local/src/Components/Wizard/Hooks/WizardContext'

interface WizardContentProps extends PropsWithChildren {
  step: number
}

export const WizardContent: React.FC<WizardContentProps> = ({
  step,
  children,
}) => {
  const context = useContext(WizardContext)

  if (!context) {
    throw new Error('WizardContent must be used within a Wizard')
  }

  const { step: currentStep } = context

  if (step !== currentStep) {
    return null
  }

  return <>{children}</>
}
