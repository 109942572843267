import { ApplicantHiredForm } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { useContent } from '@local/src/Utils/Content/useContent'
import { boolean, object } from 'yup'

export const useHiredApplicantFormValidationSchema = () => {
  const { requiredField } = useContent().manageCandidate.dialogOngoing

  return object<ApplicantHiredForm>().shape({
    endRecruitmentProcess: boolean().required(requiredField),
  })
}
