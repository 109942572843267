import React from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import { ApplicantListIcon } from '@local/src/Components/ApplicantListIcon/ApplicantListIcon'
import {
  ChipSelect,
  ChipSelectOptionGroup,
} from '@local/src/Components/ChipSelect/ChipSelect'
import { ApplicationStatus } from '@local/src/Api/types'

export interface ApplicantCardProps {
  title: string
  subtitle: string
  skillsFulfilled: string[]
  totalSkills: number
  buttonLabel: string
  actionChip?: {
    label: string
    optionGroups: ChipSelectOptionGroup[]
  }
  status: ApplicationStatus
  onShowApplicantClick?: () => void
  headerAction?: React.ReactNode
}

export const ApplicantCard: React.FC<ApplicantCardProps> = ({
  title,
  subtitle,
  skillsFulfilled,
  totalSkills,
  buttonLabel,
  actionChip,
  status,
  onShowApplicantClick,
  headerAction,
}) => {
  const score = Math.round((skillsFulfilled.length / totalSkills) * 100)
  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <CardHeader
        title={title}
        subheader={subtitle}
        titleTypographyProps={{ variant: 'h6' }}
        subheaderTypographyProps={{ variant: 'body2' }}
        action={headerAction}
      />

      <CardContent>
        <ListItem>
          <ListItemAvatar>
            <ApplicantListIcon
              score={score}
              disableColor={status === ApplicationStatus.Rejected}
            />
          </ListItemAvatar>
          <ListItemText
            primary={`${skillsFulfilled.length} av ${totalSkills} kunskaper matchar`}
            secondary={
              skillsFulfilled.slice(0, 3).join(', ') +
              (skillsFulfilled.length > 3
                ? `, +${skillsFulfilled.length - 3}`
                : '')
            }
            secondaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        {onShowApplicantClick && (
          <Button size="small" variant="text" onClick={onShowApplicantClick}>
            {buttonLabel}
          </Button>
        )}
        {actionChip && (
          <ChipSelect
            label={actionChip.label}
            optionGroups={actionChip.optionGroups}
          />
        )}
      </CardActions>
    </Card>
  )
}
