import React from 'react'
import { TextField } from '@mui/material'
import { FormikErrors } from 'formik'
import { FormikHandleChange } from '@local/src/Utils/types'
import { useContent } from '@local/src/Utils/Content/useContent'
import { ContactInfoForm } from '@local/src/Pages/SettingsPage/EditContactInfoDialog/Form/contactInfoSchema'

interface ContactPhoneFieldProps {
  value: string
  handleChange: FormikHandleChange
  errors: FormikErrors<ContactInfoForm>
}

export const ContactPhoneField: React.FC<ContactPhoneFieldProps> = ({
  value,
  handleChange,
  errors,
}) => {
  const { phonePlaceholder } = useContent().contactPopup

  return (
    <TextField
      fullWidth
      value={value}
      placeholder={phonePlaceholder}
      onChange={handleChange('phone')}
      error={!!errors?.phone}
      helperText={!!errors?.phone && errors?.phone}
      margin="dense"
    />
  )
}
