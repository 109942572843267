import { Skill } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { baseApi } from '@local/src/Api/baseApi'
import { capitalizeFirstLetter } from '@local/src/Utils/stringHelpers'
import { removeDuplicates } from '@local/src/Utils/arrayHelpers'

export interface GetSkillsResponse {
  concept: string
  term: string
}

export const skillApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getSkills: builder.query<Skill[], string>({
      query: (search) => ({
        url: `/kompetens/prognosportal/competence/${search.toLowerCase()}`,
      }),
      transformResponse: (response: GetSkillsResponse[]): Skill[] =>
        removeDuplicates(response, (a, b) => a?.concept === b?.concept)?.map(
          (skill: GetSkillsResponse) => ({
            name: capitalizeFirstLetter(skill.concept),
          })
        ),
    }),
  }),
})

export const { useLazyGetSkillsQuery } = skillApi
