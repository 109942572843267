import React from 'react'
import { ListSubheader, MenuItem, Select } from '@mui/material'
import { SwapVert } from '@mui/icons-material'

interface OrderSelectProps<T> {
  label: string
  orderValue: T
  orderOptions: { label: string; value: T }[]
  onOrderChange: (order: T) => void
}

export const OrderSelect = <T extends string | number>({
  label,
  orderValue,
  orderOptions,
  onOrderChange,
}: OrderSelectProps<T>) => (
  <Select
    variant="standard"
    disableUnderline
    value={orderValue}
    onChange={(e) => onOrderChange(e.target.value as T)}
    IconComponent={SwapVert}
    SelectDisplayProps={{
      style: {
        paddingRight: 35,
      },
    }}
    MenuProps={{
      MenuListProps: {
        sx: { py: 0 },
      },
    }}
  >
    <ListSubheader sx={{ fontSize: (t) => t.typography.body2.fontSize }}>
      {label}
    </ListSubheader>
    {orderOptions.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </Select>
)
