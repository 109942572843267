import React from 'react'
import { useContent } from '@local/src/Utils/Content/useContent'
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { CandidateHistoryEvent } from '@local/src/Api/types'
import { date } from '@trr/frontend-datetime'
import { applicationStatusContentMapper } from '@local/src/Utils/Content/applicationStatusContentMapper'

interface CandidateHistoryLogProps {
  historyLog: CandidateHistoryEvent[]
  defaultShowMore?: boolean
}

export const CandidateHistoryLog: React.FC<CandidateHistoryLogProps> = ({
  historyLog,
  defaultShowMore = false,
}) => {
  const [showMoreHistory, setShowMoreHistory] = React.useState(defaultShowMore)

  const {
    manageCandidate: {
      history: { emptyState, showLess, showMore, title },
    },
    detailsPage: { applicationStatuses },
  } = useContent()

  const handleShowMoreHistory = () => {
    setShowMoreHistory(!showMoreHistory)
  }

  return (
    <Box>
      <Typography variant="h6" mb={2}>
        {title}
      </Typography>
      <List>
        {historyLog?.map(
          (event, index) =>
            (index === 0 || showMoreHistory) && (
              <ListItem key={index} color="text.secondary">
                <ListItemText
                  primary={`${date.format(new Date(event.createdWhen), { style: 'numeric' })} \u2002${applicationStatusContentMapper(event.status, applicationStatuses)}`}
                />
              </ListItem>
            )
        )}
        {(!historyLog || historyLog.length === 0) && (
          <Typography variant="subtitle1" color="text.secondary">
            {emptyState}
          </Typography>
        )}
      </List>
      {historyLog?.length > 1 && (
        <Button
          variant="text"
          sx={{ p: 0 }}
          onClick={handleShowMoreHistory}
          data-testid="show-more-history-button"
        >
          {!showMoreHistory ? showMore : showLess}
        </Button>
      )}
    </Box>
  )
}
