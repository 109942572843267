import React from 'react'
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardProps,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
} from '@mui/material'

export const ApplicantCardSkeleton: React.FC<CardProps> = (props) => (
  <Card {...props} role="progressbar">
    <CardHeader
      title={<Skeleton variant="text" width="70%" />}
      subheader={<Skeleton variant="text" width="30%" />}
      titleTypographyProps={{ variant: 'h6' }}
      subheaderTypographyProps={{ variant: 'body2' }}
    />
    <CardContent>
      <ListItem>
        <ListItemAvatar>
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton variant="text" width="80%" />}
          secondary={<Skeleton variant="text" width="40%" />}
          secondaryTypographyProps={{ variant: 'body2' }}
        />
      </ListItem>
    </CardContent>
    <CardActions>
      <Button variant="text" size="small">
        <Skeleton variant="text" width="70px" />
      </Button>
    </CardActions>
  </Card>
)
