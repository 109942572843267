import React from 'react'
import { ContactInfo } from '@local/src/Pages/DraftPage/RecruitmentFormView/Form/types'
import { useContent } from '@local/src/Utils/Content/useContent'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'

interface RecruiterContactInformationProps {
  recruiter: ContactInfo
}

export const RecruiterContactInformation: React.FC<
  RecruiterContactInformationProps
> = ({ recruiter }) => {
  const { contactNameLabel, contactEmailLabel, contactPhoneLabel } =
    useContent().preview

  if (!recruiter?.phone && !recruiter?.email && !recruiter?.name) {
    return null
  }

  return (
    <Table>
      <TableBody>
        {recruiter.name && (
          <TableRow>
            <TableCell sx={{ paddingLeft: 0 }}>
              <Typography variant="body2" key="label">
                {contactNameLabel}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" key="label">
                {recruiter.name}
              </Typography>
            </TableCell>
          </TableRow>
        )}
        {recruiter.email && (
          <TableRow>
            <TableCell sx={{ paddingLeft: 0 }}>
              <Typography variant="body2" key="label">
                {contactEmailLabel}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" key="label">
                {recruiter.email}
              </Typography>
            </TableCell>
          </TableRow>
        )}
        {recruiter.phone && (
          <TableRow>
            <TableCell sx={{ paddingLeft: 0 }}>
              <Typography variant="body2" key="label">
                {contactPhoneLabel}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" key="label">
                {recruiter.phone}
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
