import React from 'react'
import { Stack, StackProps } from '@mui/material'

export const ButtonStack: React.FC<StackProps> = ({ children, ...props }) => (
  <Stack
    direction={{ xs: 'column', md: 'row' }}
    {...props}
    spacing={2}
    sx={{
      textWrap: 'nowrap',
      '& .MuiButton-root': { width: { xs: '100%' } },
    }}
  >
    {children}
  </Stack>
)
