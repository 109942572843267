import React, { useId } from 'react'
import { ClosureReason } from '@local/src/Api/types'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { useFormikContext } from 'formik'
import { CloseRecruitmentFormValues } from '@local/src/Pages/SettingsPage/ClosureSettings/ClosureSettingsForm/types'
import { useContent } from '@local/src/Utils/Content/useContent'

interface ClosureReasonFieldProps {
  disableOtherCandidateOptions?: boolean
  disableCandidateHiredOption?: boolean
}

export const ClosureReasonField: React.FC<ClosureReasonFieldProps> = ({
  disableOtherCandidateOptions = false,
  disableCandidateHiredOption = false,
}) => {
  const {
    reasonLabel,
    reasonCandidateHired,
    reasonNotFilled,
    reasonAnotherCandidateHired,
  } = useContent().detailsPage.closure

  const { values, errors, handleChange } =
    useFormikContext<CloseRecruitmentFormValues>()

  const labelId = useId()

  return (
    <Stack spacing={1}>
      <Typography
        variant="body1"
        component="label"
        fontWeight="bold"
        id={labelId}
      >
        {reasonLabel}
      </Typography>
      <FormControl error={Boolean(errors.reason)}>
        <RadioGroup
          aria-labelledby={labelId}
          value={values.reason ?? null}
          onChange={handleChange('reason')}
        >
          <FormControlLabel
            value={ClosureReason.CandidateHired}
            control={<Radio />}
            label={reasonCandidateHired}
            disabled={disableCandidateHiredOption}
          />
          <FormControlLabel
            value={ClosureReason.AnotherCandidateHired}
            control={<Radio />}
            label={reasonAnotherCandidateHired}
            disabled={disableOtherCandidateOptions}
          />
          <FormControlLabel
            value={ClosureReason.NotFilled}
            control={<Radio />}
            label={reasonNotFilled}
            disabled={disableOtherCandidateOptions}
          />
        </RadioGroup>
        {errors.reason && (
          <FormHelperText error>{errors.reason}</FormHelperText>
        )}
      </FormControl>
    </Stack>
  )
}
