import React from 'react'
import { RecruitmentsWithApplicationStatusCategories } from '@local/src/Api/types'
import { RecruitmentListItem } from '@local/src/Pages/StartPage/CreatedRecruitments/RecruitmentList/RecruitmentListItem'
import { ApplicantsIcon } from '@local/src/Components/ApplicantIcon/ApplicantsIcon'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'
import { useContent } from '@local/src/Utils/Content/useContent'
import { Stack, TableCell } from '@mui/material'
import { useDevice } from '@trr/app-shell-data'
import { date } from '@trr/frontend-datetime'

interface ClosedRecruitmentListItemProps {
  recruitment: RecruitmentsWithApplicationStatusCategories
}

export const ClosedRecruitmentListItem: React.FC<
  ClosedRecruitmentListItemProps
> = ({ recruitment }) => {
  const { heading, jobTitle, locations: location } = recruitment.position ?? {}

  const companyName = recruitment.company?.name

  const { isMobile } = useDevice()

  const {
    closedRecruitments: {
      recruitmentEndDate,
      irrelevantApplicants,
      hiredApplicants,
    },
    skapadeAnnonser: { recruitmentDetailsPath },
  } = useContent()

  const secondaryText = [jobTitle?.name, companyName, location?.[0]?.name]
    .filter(Boolean)
    .join(', ')

  const closureDate =
    recruitment?.closure?.date ?? recruitment.metadata.lastModifiedWhen

  const closedEndDateText = closureDate
    ? replaceEpiVariables(recruitmentEndDate, {
        date: date.format(closureDate, {
          year: true,
        }),
      })
    : null

  return (
    <RecruitmentListItem
      primaryText={heading}
      secondaryText={secondaryText}
      tertiaryText={closedEndDateText}
      to={recruitmentDetailsPath + recruitment.id}
    >
      {!isMobile && (
        <TableCell
          sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}
          component="div"
        >
          <Stack direction="row" spacing={3}>
            <ApplicantsIcon
              label={hiredApplicants}
              value={recruitment?.applicationStatusCategories?.hired}
            />
            <ApplicantsIcon
              label={irrelevantApplicants}
              value={recruitment?.applicationStatusCategories?.notRelevant}
            />
          </Stack>
        </TableCell>
      )}
    </RecruitmentListItem>
  )
}
