import React from 'react'
import { RecruitmentsWithApplicationStatusCategories } from '@local/src/Api/types'
import { ApplicantsIcon } from '@local/src/Components/ApplicantIcon/ApplicantsIcon'
import { RecruitmentListItem } from '@local/src/Pages/StartPage/CreatedRecruitments/RecruitmentList/RecruitmentListItem'
import { replaceEpiVariables } from '@local/src/Utils/Content/replaceEpiVariables'
import { useContent } from '@local/src/Utils/Content/useContent'
import { Stack, TableCell } from '@mui/material'
import { useDevice } from '@trr/app-shell-data'
import { date } from '@trr/frontend-datetime'

interface ActiveRecruitmentListItemProps {
  recruitment: RecruitmentsWithApplicationStatusCategories
}

export const ActiveRecruitmentListItem: React.FC<
  ActiveRecruitmentListItemProps
> = ({ recruitment }) => {
  const { heading, jobTitle, locations: location } = recruitment.position ?? {}

  const companyName = recruitment.company?.name

  const { isMobile } = useDevice()

  const {
    skapadeAnnonser: {
      pastPublishEndDate,
      upcomingPublishEndDate,
      interestedApplicants,
      irrelevantApplicants,
      ongoingApplicants,
      recruitmentDetailsPath,
    },
  } = useContent()

  const secondaryText = [jobTitle?.name, companyName, location?.[0]?.name]
    .filter(Boolean)
    .join(', ')

  const publishEndDateContent = () =>
    new Date(recruitment.publication.endDate) > new Date()
      ? upcomingPublishEndDate
      : pastPublishEndDate

  const publishEndDateText = recruitment?.publication?.endDate
    ? replaceEpiVariables(publishEndDateContent(), {
        date: date.format(recruitment.publication.endDate, {
          year: true,
        }),
      })
    : null

  return (
    <RecruitmentListItem
      primaryText={heading}
      secondaryText={secondaryText}
      tertiaryText={publishEndDateText}
      to={recruitmentDetailsPath + recruitment.id}
    >
      {!isMobile && (
        <TableCell
          sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}
          component="div"
        >
          <Stack direction="row" spacing={3}>
            <ApplicantsIcon
              label={interestedApplicants}
              value={recruitment?.applicationStatusCategories?.interested ?? 0}
            />
            <ApplicantsIcon
              label={ongoingApplicants}
              value={recruitment?.applicationStatusCategories?.ongoing ?? 0}
            />
            <ApplicantsIcon
              label={irrelevantApplicants}
              value={recruitment?.applicationStatusCategories?.notRelevant ?? 0}
            />
          </Stack>
        </TableCell>
      )}
    </RecruitmentListItem>
  )
}
